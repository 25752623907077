import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { loginAdmin } from "../actions";
import { Card, Spinner, Image, Form, Button} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';

class Login extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const email = "";

        const password = "";

        this.state = {
            history,
            params,
            location,
            width,
            height,
            email,
            password
        };

    }

    componentWillUnmount(){

        this.setState({email: '', password: ''});

    }

    componentDidMount(){

        const {logged_in } = this.props;

        const { history } = this.state;

        if(logged_in){

            history.push("/home");

        }

    }


    authenticateAdmin(){

        const { loginAdmin } = this.props;

        const {email, password, history } = this.state;

        loginAdmin(email, password, history);


    }

    loginErrors(){

        const { login_errors } = this.props;

        return _.map(login_errors, (error, index) => {

            return(

                <span
                    style={{
                        color: '#ff0000',
                        marginBottom: '20px'
                    }}
                    key={index}
                >
                    {error}
                </span>

            );

        });

    }


    formContent(){

        const { history } = this.state;

        return(

            <Fragment>

                <Image
                    src={require("../images/logo.png")}
                    style={{
                        width: isMobile ? '250px' : '350px',
                        height: isMobile ? '200px': '300px'
                    }}
                    className="logo"
                />


                <Form onSubmit={(e) => e.preventDefault()}>

                    <Form.Group
                        controlId="formBasicEmail"
                        className="login-input-container"
                        style={{
                            width: isMobile ? this.state.width - 70 : this.state.width / 2.5
                        }}
                    >


                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={(e) => {
                                this.setState({email: e.target.value});
                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        controlId="formBasicPassword"
                        className="login-input-container"
                        style={{
                            width: isMobile ? this.state.width - 70 : this.state.width / 2.5
                        }}
                    >

                        <Form.Control
                            type="password"
                            placeholder="Password"
                            value={this.state.password}
                            onChange={(e) => {
                                this.setState({password: e.target.value});
                            }}
                            onKeyPress={(e) => {
                                if(e.charCode === 13){

                                    e.preventDefault();

                                    this.authenticateAdmin()

                                }
                            }}
                        />

                    </Form.Group>



                </Form>

                {this.loginErrors()}

                <Button
                    variant="outline-success"
                    size="lg"
                    style={{
                        alignSelf: 'center',
                        width: isMobile ? this.state.width - 70 : '30%',
                        borderRadius: '10px',
                        marginBottom:  isMobile ? '20px' : '15px'
                    }}
                    onClick={() => {
                        this.authenticateAdmin()
                    }}
                >
                    Login
                </Button>

                <span
                    className="login-form-nav-link"
                    onClick={() => {
                        history.push("/forgot-password");
                    }}
                >
                    Forgot your password?
                </span>


                <span
                    className="login-form-nav-link"
                    onClick={() => {
                        history.push("/resend-unlock-link");
                    }}
                >
                    Resend Unlock Instructions
                </span>


            </Fragment>

        );

    }


    loginForm(){

        if(isMobile){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>

                    {this.formContent()}

                </div>

            );

        }else{

            return(

                <Card style={{
                    width: this.state.width / 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: '40px'
                }}>

                    {this.formContent()}

                </Card>


            );

        }

    }

    renderBody(){

        const { loading } = this.props;

        if( loading){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={ isMobile ? {
                    display: 'flex',
                    justifyContent: 'center'
                } : {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: this.state.height
                }}>



                    {this.loginForm()}




                </div>

            )

        }

    }

    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }

}


const mapStateToProps = (state) => {

    const {
        logged_in,
        loading,
        login_errors
    } = state.login;

    return {
        logged_in,
        loading,
        login_errors
    };


};


export default connect(mapStateToProps, {
    loginAdmin
})(Login);

