export const BACKEND_DOMAIN =  ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development')  ?  "localhost:3000" : "api.bodegaliquidation.com";
export const BACKEND_URL =  `${ ( !process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http' : 'https'}://${BACKEND_DOMAIN}`;


export const CHANGE_ACCOUNT_APPROVAL_STATUS_ROUTE = `${BACKEND_URL}/change-account-approval-status`;

// LandingPageImages Types
export const INITIALIZE_LANDING_PAGE_IMAGES_ROUTE = `${BACKEND_URL}/landing-images-index`;
export const INITIALIZE_LANDING_PAGE_IMAGES_PAGE = "initialize_landing_page_images_page";
export const INITIALIZE_LANDING_PAGE_IMAGES_PAGE_COMPLETE = "initialize_landing_page_images_complete";
export const CLEAR_LANDING_PAGE_IMAGES_STATE = "clear_landing_page_images_state";
export const ADD_LANDING_IMAGE_ROUTE = `${BACKEND_URL}/add-landing-image`;
export const ADD_LANDING_IMAGE = "add_landing_image";
export const ADD_LANDING_IMAGE_COMPLETE = "add_landing_image_complete";
export const ADD_LANDING_IMAGE_SUCCESS = "add_landing_image_success";
export const ADD_LANDING_IMAGE_FAILURE = "add_landing_image_failure";
export const DELETE_LANDING_IMAGE_ROUTE = `${BACKEND_URL}/delete-landing-image`;
export const DELETE_LANDING_IMAGE = "delete_landing_image";
export const DELETE_LANDING_IMAGE_COMPLETE = "delete_landing_image_complete";
export const DELETE_LANDING_IMAGE_SUCCESS = "delete_landing_image_success";
export const DELETE_LANDING_IMAGE_FAILURE = "delete_landing_image_failure";


// View Customer Types
export const INITIALIZE_VIEW_CUSTOMER_PAGE_ROUTE = `${BACKEND_URL}/view-customer-index`;
export const INITIALIZE_VIEW_CUSTOMER_PAGE = "initialize_view_customer_page";
export const INITIALIZE_VIEW_CUSTOMER_PAGE_COMPLETE = "initialize_view_customer_page_complete";
export const VIEW_CUSTOMER_ACCOUNT_CHANGED = "view_customer_account_changed";
export const CLEAR_VIEW_CUSTOMER_PAGE_STATE = "clear_view_customer_page_state";
export const OPEN_APPROVE_ACCOUNT_MODAL = "open_approve_account_modal";
export const CLOSE_APPROVE_ACCOUNT_MODAL = "close_approve_account_modal";
export const APPROVE_CUSTOMER_ACCOUNT = "approve_customer_account";
export const APPROVE_CUSTOMER_ACCOUNT_COMPLETE = "approve_customer_account_complete";
export const APPROVE_ACCOUNT_MODAL_ERROR_CHANGED = "approve_account_modal_error_changed";


// Edit Customer Types
export const INITIALIZE_EDIT_CUSTOMER_PAGE_ROUTE = `${BACKEND_URL}/edit-customer-index`;
export const INITIALIZE_EDIT_CUSTOMER_PAGE = "initialize_edit_customer_page";
export const INITIALIZE_EDIT_CUSTOMER_PAGE_COMPLETE = "initialize_edit_customer_page_complete";
export const CLEAR_EDIT_CUSTOMER_PAGE_STATE = "clear_edit_customer_page_state";
export const EDIT_CUSTOMER_EMAIL_CHANGED = "edit_customer_email_changed";
export const EDIT_CUSTOMER_FULL_NAME_CHANGED = "edit_customer_full_name_changed";
export const EDIT_CUSTOMER_COMPANY_NAME_CHANGED = "edit_customer_company_name_changed";
export const EDIT_CUSTOMER_COMPANY_ADDRESS_CHANGED = "edit_customer_company_address_changed";
export const EDIT_CUSTOMER_COMPANY_STATE_CHANGED = "edit_customer_company_state_changed";
export const EDIT_CUSTOMER_COMPANY_STATE_CODE_CHANGED = "edit_customer_company_state_code_changed";
export const EDIT_CUSTOMER_COMPANY_CITY_CHANGED = "edit_customer_company_city_changed";
export const EDIT_CUSTOMER_COMPANY_ZIP_CODE_CHANGED = "edit_customer_company_zip_code_changed";
export const EDIT_CUSTOMER_ACCOUNT_APPROVED_CHANGED = "edit_customer_account_approved_changed";
export const EDIT_CUSTOMER_ACCOUNT_PASSWORD_CHANGED = "edit_customer_account_password_changed";
export const UPDATE_CUSTOMER_ACCOUNT_ROUTE = `${BACKEND_URL}/update-customer-account`;
export const UPDATE_CUSTOMER_ACCOUNT = "update_customer_account";
export const UPDATE_CUSTOMER_ACCOUNT_COMPLETE = "update_customer_account_complete";
export const EDIT_CUSTOMER_ACCOUNT_CHANGED = "edit_customer_account_changed";
export const OPEN_EDIT_CUSTOMER_ACCOUNT_MODAL = "open_edit_customer_account_modal";
export const CLOSE_EDIT_CUSTOMER_ACCOUNT_MODAL = "close_edit_customer_account_modal";
export const REPLACE_SELLER_PERMIT_ROUTE = `${BACKEND_URL}/replace-seller-permit`;
export const REPLACE_SELLER_PERMIT = "replace_seller_permit";
export const REPLACE_SELLER_PERMIT_COMPLETE = "replace_seller_permit_complete";
export const REPLACE_SELLER_PERMIT_ERROR = "replace_seller_permit_error";
export const OPEN_RESELLER_PERMIT_MODAL = "open_reseller_permit_modal";
export const CLOSE_RESELLER_PERMIT_MODAL = "close_reseller_permit_modal";



// Customer Accounts Types
export const INITIALIZE_CUSTOMER_ACCOUNTS_PAGE_ROUTE = `${BACKEND_URL}/get-customer-accounts`;
export const GET_CUSTOMER_ACCOUNTS = "get_customer_accounts";
export const GET_CUSTOMER_ACCOUNTS_COMPLETE = "get_customer_accounts_complete";
export const CUSTOMER_ACCOUNTS_CHANGED = "customer_accounts_changed";
export const CLEAR_CUSTOMER_ACCOUNTS_STATE = "clear_customer_accounts_state";
export const SEARCH_CUSTOMER_ACCOUNTS_ROUTE = `${BACKEND_URL}/search-customer-accounts`;
export const SEARCH_CUSTOMER_ACCOUNTS = "search_customer_accounts";
export const SEARCH_CUSTOMER_ACCOUNTS_COMPLETE = "search_customer_accounts_complete";


// Edit Product Types
export const EDIT_PRODUCT_INDEX_ROUTE = `${BACKEND_URL}/edit-product-index`;
export const INITIALIZE_EDIT_PRODUCT_PAGE = "initialize_edit_product_page";
export const INITIALIZE_EDIT_PRODUCT_PAGE_COMPLETE = "initialize_edit_product_page_complete";
export const INITIALIZE_EDIT_PRODUCT_PAGE_SUCCESS = "initialize_product_page_success";
export const CLEAR_EDIT_PRODUCT_STATE = "clear_edit_product_state";
export const EDIT_PRODUCT_NAME_CHANGED = "edit_product_name_changed";
export const EDIT_PRODUCT_DESCRIPTION_CHANGED = "edit_product_description_changed";
export const EDIT_PRODUCT_UPC_CHANGED = "edit_product_upc_changed";
export const EDIT_PRODUCT_SKU_CHANGED = "edit_product_sku_changed";
export const EDIT_PRODUCT_TOPIC_CHANGED = "edit_product_topic_changed";
export const EDIT_PRODUCT_BRAND_NAME_CHANGED = "edit_product_brand_name_changed";
export const EDIT_PRODUCT_ATTRIBUTES_CHANGED = "edit_product_attributes_changed";
export const EDIT_PRODUCT_CATEGORY_CHANGED = "edit_product_category_changed";
export const EDIT_PRODUCT_QUANTITY_CHANGED = "edit_product_quantity_changed";
export const REMOVE_PRODUCT_IMAGE_ROUTE = `${BACKEND_URL}/remove-product-image`;
export const REMOVE_PRODUCT_IMAGE = "remove_product_image";
export const REMOVE_PRODUCT_IMAGE_COMPLETE = "remove_product_image_complete";
export const REMOVE_PRODUCT_IMAGE_SUCCESS = "remove_product_image_success";
export const REMOVE_PRODUCT_IMAGE_FAILURE = "remove_product_image_failure";
export const HIDE_EDIT_PRODUCT_MODAL = "hide_edit_product_modal";
export const ADD_PRODUCT_IMAGES_ROUTE = `${BACKEND_URL}/add-product-images`;
export const ADD_PRODUCT_IMAGES = "add_product_images";
export const ADD_PRODUCT_IMAGES_COMPLETE = "add_product_images_complete";
export const ADD_PRODUCT_IMAGES_SUCCESS = "add_product_images_success";
export const ADD_PRODUCT_IMAGES_FAILURE = "add_product_images_failure";
export const UPDATE_PRODUCT_ROUTE = `${BACKEND_URL}/update-product`;
export const UPDATE_PRODUCT = "update_product";
export const UPDATE_PRODUCT_COMPLETE = "update_product_complete";
export const UPDATE_PRODUCT_SUCCESS = "update_product_success";
export const UPDATE_PRODUCT_FAILURE = "update_product_failure";
export const REPLACE_PRODUCT_PICTURE_ROUTE = `${BACKEND_URL}/replace-product-picture`;
export const REPLACE_PRODUCT_PICTURE = "replace_product_picture";
export const REPLACE_PRODUCT_PICTURE_COMPLETE = "replace_product_picture_complete";
export const EDIT_PRODUCT_PICTURES_CHANGED = "edit_product_pictures_changed";
export const REPLACE_PRODUCT_PICTURE_FAILURE = "replace_product_picture_failure";

export const EDIT_PACK_SIZE_MODAL_VISIBLE_CHANGED = "edit_pack_size_modal_visible_changed";
export const NEW_PACK_SIZE_CHANGED = "new_pack_size_changed";
export const EDIT_PACK_SIZE_ROUTE = `${BACKEND_URL}/edit-pack-size`;
export const EDIT_PACK_SIZE = "edit_pack_size";
export const EDIT_PACK_SIZE_COMPLETE = "edit_pack_size_complete";
export const EDIT_PACK_SIZE_SUCCESS = "edit_pack_size_success";
export const EDIT_PACK_SIZE_FAILURE = "edit_pack_size_failure";
export const CLEAR_EDIT_PRICE_LIST_STATE = "clear_edit_price_list_state";
export const CLEAR_EDIT_PACK_SIZE_MODAL_STATE = "clear_edit_pack_size_modal_state";
export const EDIT_PACK_SIZE_MODAL_ERROR_CHANGED = "edit_pack_size_modal_error_changed";

export const EDIT_PRICE_MODAL_VISIBLE_CHANGED = "edit_price_modal_visible_changed";
export const NEW_PRICE_CHANGED = "new_price_changed";
export const EDIT_PRICE_ROUTE = `${BACKEND_URL}/edit-price-range-price`;
export const EDIT_PRICE = "edit_price";
export const EDIT_PRICE_COMPLETE = "edit_price_complete";
export const EDIT_PRICE_SUCCESS = "edit_price_success";
export const EDIT_PRICE_FAILURE = "edit_price_failure";
export const CLEAR_EDIT_PRICE_MODAL_STATE = "clear_edit_price_modal_state";
export const EDIT_PRICE_MODAL_ERROR_CHANGED = "edit_price_modal_error_changed";
export const EDIT_PRICE_RANGES_CHANGED = "edit_price_ranges_changed";
export const EDIT_SELECTED_PRICE_RANGE_ID_CHANGED = "edit_selected_price_range_id_changed";
export const OPEN_EDIT_PRICE_MODAL = "open_edit_price_modal";

export const CLEAR_EDIT_QUANTITY_MODAL_STATE = "clear_edit_quantity_modal_state";
export const EDIT_QUANTITY_MODAL_ERROR_CHANGED = "edit_quantity_modal_error_changed";
export const OPEN_EDIT_QUANTITY_MODAL = "open_edit_quantity_modal";
export const NEW_END_QUANTITY_CHANGED = "new_end_quantity_changed";
export const EDIT_QUANTITY_ROUTE = `${BACKEND_URL}/edit-quantity`;
export const EDIT_QUANTITY = "edit_quantity";
export const EDIT_QUANTITY_COMPLETE = "edit_quantity_complete";
export const EDIT_QUANTITY_FAILURE = "edit_quantity_failure";


export const REMOVE_PRICE_RANGE_ROUTE = `${BACKEND_URL}/remove-price-range`;
export const REMOVE_PRICE_RANGE = "remove_price_range";
export const REMOVE_PRICE_RANGE_COMPLETE = "remove_price_range_complete";
export const REMOVE_PRICE_RANGE_FAILURE = "remove_price_range_failure";

export const CLEAR_ADD_PRICE_MODAL_STATE = "clear_add_price_modal_state";
export const END_QUANTITY_ERROR_CHANGED = "end_quantity_error_changed";
export const PRICE_ERROR_CHANGED = "price_error_changed";
export const ADD_PRICE_MODAL_ERROR_CHANGED = "add_price_modal_error_changed";
export const OPEN_ADD_PRICE_MODAL = "open_add_price_modal";
export const ADD_PRICE_ROUTE = `${BACKEND_URL}/add-price-range`;
export const ADD_PRICE = "add_price";
export const ADD_PRICE_COMPLETE = "add_price_complete";
export const ADD_PRICE_FAILURE = "add_price_failure";

export const DELETE_PRICE_LIST_ROUTE = `${BACKEND_URL}/destroy-price-list`;
export const DELETE_PRICE_LIST = "delete_price_list";
export const DELETE_PRICE_LIST_COMPLETE = "delete_price_list_complete";

export const CLEAR_CREATE_PRICE_LIST_MODAL_STATE = "clear_create_price_list_modal_state";
export const PACK_SIZE_ERROR_CHANGED = "pack_size_error_changed";
export const CREATE_PRICE_LIST_MODAL_ERROR_CHANGED = "create_price_list_modal_error_changed";
export const OPEN_CREATE_PRICE_LIST_MODAL = "open_create_price_list_modal";
export const CREATE_PRICE_LIST_ROUTE = `${BACKEND_URL}/create-price-list`;
export const CREATE_PRICE_LIST = "create_price_list";
export const CREATE_PRICE_LIST_COMPLETE = "create_price_list_complete";
export const CREATE_PRICE_LIST_FAILURE = "create_price_list_failure";
export const CREATE_PRICE_LIST_SUCCESS = "create_price_list_success";


// Create Product Types
export const CLEAR_CREATE_PRODUCT_STATE = "clear_create_product_state";
export const CREATE_PRODUCT_ROUTE = `${BACKEND_URL}/create-product`;
export const CREATE_PRODUCT = "create_product";
export const CREATE_PRODUCT_SUCCESS = "create_product_success";
export const CREATE_PRODUCT_FAILURE = "create_product_failure";
export const HIDE_CREATE_PRODUCT_MODAL = "hide_create_product_modal";
export const CREATE_PRODUCT_NAME_CHANGED = "create_product_name_changed";
export const CREATE_PRODUCT_DESCRIPTION_CHANGED = "create_product_description_changed";
export const CREATE_PRODUCT_UPC_CHANGED = "create_product_upc_changed";
export const CREATE_PRODUCT_SKU_CHANGED = "create_product_sku_changed";
export const CREATE_PRODUCT_PICTURES_CHANGED = "create_product_pictures_changed";
export const CREATE_PRODUCT_TOPIC_CHANGED = "create_product_topic_changed";
export const CREATE_PRODUCT_ATTRIBUTES_CHANGED = "create_product_attributes_changed";
export const CREATE_PRODUCT_BRAND_NAME_CHANGED = "create_product_brand_name_changed";
export const CLEAR_CREATE_PRODUCT_FORM = "clear_create_product_form";
export const CREATE_PRODUCT_PRICE_LIST_VISIBILITY_CHANGED = "create_product_price_list_visibility_changed";
export const CREATE_PRODUCT_PACK_SIZE_CHANGED = "create_product_pack_size_changed";
export const CREATE_PRODUCT_PRICE_RANGES_CHANGED = "create_product_price_ranges_changed";
export const CREATE_PRODUCT_QUANTITY_CHANGED = "create_product_quantity_changed";




// Products Types
export const PRODUCTS_PAGE_INDEX_ROUTE = `${BACKEND_URL}/products-page-index`;
export const INITIALIZE_PRODUCTS_PAGE = "initialize_products_page";
export const INITIALIZE_PRODUCTS_PAGE_COMPLETE = "initialize_products_page_complete";
export const CLEAR_PRODUCTS_PAGE_STATE = "clear_products_page_state";
export const SEARCH_PRODUCTS_ROUTE = `${BACKEND_URL}/search-products`;
export const SEARCH_PRODUCTS = "search_products";
export const SEARCH_PRODUCTS_COMPLETE = "search_products_complete";
export const HIDE_PRODUCT_ROUTE = `${BACKEND_URL}/hide-product`;
export const CHANGE_PRODUCTS_CATEGORY_ROUTE = `${BACKEND_URL}/change-products-category`;
export const CHANGE_PRODUCTS_CATEGORY = "change_products_category";
export const PRODUCTS_PAGE_CATEGORIES_CHANGED = "products_page_categories_changed";
export const CHANGE_PRODUCTS_CATEGORY_SUCCESS = "change_products_category_success";
export const CHANGE_PRODUCTS_CATEGORY_FAILURE = "change_products_category_failure";
export const CHANGE_PRODUCTS_CATEGORY_COMPLETE = "change_products_category_complete";
export const PRODUCTS_BATCH_ACTION_EXECUTION_COMPLETE = "products_batch_action_execution_complete";
export const CLEAR_CHANGE_PRODUCTS_CATEGORY_MODAL_MESSAGES = "clear_change_products_category_modal_messages";
export const PRODUCTS_QUANTITY_CHANGED = "products_quantity_changed";


// Categories Types
export const CATEGORIES_INDEX_ROUTE = `${BACKEND_URL}/categories-index`;
export const INITIALIZE_CATEGORIES_PAGE = "initialize_categories_page";
export const INITIALIZE_CATEGORIES_PAGE_COMPLETE = "initialize_categories_page_complete";
export const CLEAR_CATEGORIES_PAGE_STATE = "clear_categories_page_state";
export const CREATE_CATEGORY_ROUTE = `${BACKEND_URL}/create-category`;
export const CREATE_CATEGORY = "create_category";
export const CREATE_CATEGORY_SUCCESS = "create_category_success";
export const CREATE_CATEGORY_FAILURE = "create_category_failure";
export const CREATE_CATEGORY_COMPLETE = "create_category_complete";
export const CLEAR_CATEGORY_MODAL_MESSAGES = "clear_category_modal_messages";
export const SEARCH_CATEGORIES_ROUTE = `${BACKEND_URL}/search-categories`;
export const SEARCH_CATEGORIES = "search_categories";
export const SEARCH_CATEGORIES_COMPLETE = "search_categories_complete";
export const CREATE_SUBCATEGORY_ROUTE = `${BACKEND_URL}/create-subcategory`;
export const CREATE_SUBCATEGORY = "create_subcategory";
export const CREATE_SUBCATEGORY_COMPLETE = "create_subcategory_complete";
export const CREATE_SUBCATEGORY_SUCCESS = "create_subcategory_success";
export const CREATE_SUBCATEGORY_FAILURE = "create_subcategory_failure";
export const CHANGE_CATEGORY_NAME_ROUTE = `${BACKEND_URL}/change-category-name`;
export const CHANGE_CATEGORY_NAME = "change_category_name";
export const CHANGE_CATEGORY_NAME_COMPLETE = "change_category_name_complete";
export const CHANGE_CATEGORY_NAME_SUCCESS = "change_category_name_success";
export const CHANGE_CATEGORY_NAME_FAILURE = "change_category_name_failure";
export const HIDE_CATEGORY_ROUTE = `${BACKEND_URL}/hide-category`;
export const HIDE_CATEGORY = "hide_category";
export const HIDE_CATEGORY_COMPLETE = "hide_category_complete";


// Edit Admin Types
export const EDIT_ADMIN_INDEX_ROUTE = `${BACKEND_URL}/edit-admin-index`;
export const UPDATE_ADMIN_ACCOUNT_FULL_NAME_ROUTE = `${BACKEND_URL}/update-admin-account-name`;
export const UPDATE_ADMIN_ACCOUNT_ROLES_ROUTE = `${BACKEND_URL}/update-admin-account-roles`;
export const INITIALIZE_EDIT_ADMIN_PAGE = "initialize_edit_admin_page";
export const INITIALIZE_EDIT_ADMIN_PAGE_COMPLETE = "initialize_edit_admin_page_complete";
export const CLEAR_EDIT_ADMIN_STATE = "clear_edit_admin_state";
export const CHANGE_ADMIN_ACCOUNT_FULL_NAME = "change_admin_account_full_name";
export const CHANGE_ADMIN_ACCOUNT_EMAIL = "change_admin_account_email";
export const CHANGE_ADMIN_ACCOUNT_PASSWORD = "change_admin_account_password";
export const CHANGE_ADMIN_ACCOUNT_ROLES = "change_admin_account_roles";
export const UPDATE_ADMIN_ACCOUNT = "update_admin_account";
export const UPDATE_ADMIN_ACCOUNT_COMPLETE = "update_admin_account_complete";
export const UPDATE_ADMIN_ADMIN_ACCOUNT_FULL_NAME_ERROR = "update_admin_account_full_name_error";
export const UPDATE_ADMIN_ACCOUNT_EMAIL_ERROR = "update_admin_account_email_error";
export const UPDATE_ADMIN_ACCOUNT_PASSWORD_ERROR = "update_admin_account_password_error";
export const UPDATE_ADMIN_ACCOUNT_ROLES_ERROR = "update_admin_account_roles_error";
export const ADMIN_ACCOUNT_CHANGED = "admin_account_changed";
export const FULL_NAME_UPDATE_SUCCESS = "full_name_update_success";
export const EMAIL_UPDATE_SUCCESS = "email_update_success";
export const PASSWORD_UPDATE_SUCCESS = "password_update_success";
export const ROLES_UPDATE_SUCCESS = "roles_update_success";



// Create Admin Types
export const CREATE_ADMIN_ACCOUNT_ROUTE = `${BACKEND_URL}/create-admin-account`;
export const CREATE_ADMIN_ACCOUNT = "create_admin_account";
export const CREATE_ADMIN_ACCOUNT_SUCCESS = "create_admin_account_success";
export const CREATE_ADMIN_ACCOUNT_FAILURE = "create_admin_account_failure";
export const CLEAR_CREATE_ADMIN_STATE = "clear_create_admin_state";
export const CREATE_ADMIN_INDEX_ROUTE = `${BACKEND_URL}/create-admin-index`;
export const INITIALIZE_CREATE_ADMIN_PAGE = "initialize_create_admin_page";
export const INITIALIZE_CREATE_ADMIN_PAGE_COMPLETE = "initialize_create_admin_page_complete";



// Admin Accounts Types
export const GET_ADMIN_PROFILE_ROUTE = `${BACKEND_URL}/get-admin-profile`;
export const ADMIN_ACCOUNT_ROLES_CHANGED = "admin_account_roles_changed";
export const ADMIN_ACCOUNT_UID_CHANGED = "admin_account_uid_changed";
export const ADMIN_ACCOUNT_FULL_NAME_CHANGED = "admin_account_full_name_changed";
export const GET_ADMIN_PROFILE = "get_admin_profile";
export const GET_ADMIN_PROFILE_COMPLETE = "get_admin_profile_complete";
export const UPDATE_ADMIN_ACCOUNT_EMAIL_ROUTE = `${BACKEND_URL}/update-admin-account-email`;
export const UPDATE_ADMIN_ACCOUNT_PASSWORD_ROUTE = `${BACKEND_URL}/update-admin-account-password`;
export const ADMIN_ACCOUNTS_INDEX_ROUTE = `${BACKEND_URL}/admin-accounts-index`;
export const INITIALIZE_ADMIN_ACCOUNTS_PAGE = "initialize_admin_accounts_page";
export const INITIALIZE_ADMIN_ACCOUNTS_PAGE_COMPLETE = "initialize_admin_accounts_page_complete";
export const SEARCH_ADMIN_ACCOUNTS_ROUTE = `${BACKEND_URL}/search-admin-accounts`;
export const SEARCH_ADMIN_ACCOUNTS = "search_admin_accounts";
export const SEARCH_ADMIN_ACCOUNTS_COMPLETE = "search_admin_accounts_complete";
export const DESTROY_ADMIN_ACCOUNT_ROUTE = `${BACKEND_URL}/destroy-admin-account`;
export const DESTROY_ADMIN_ACCOUNT = "destroy_admin_account";

// Home Types
export const ADMIN_UPDATE_OWN_ACCOUNT = "admin_update_own_account";
export const ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE = "admin_update_own_account_complete";
export const ADMIN_UPDATE_OWN_ACCOUNT_SUCCESS = "admin_update_own_account_success";
export const ADMIN_UPDATE_OWN_ACCOUNT_FAILURE = "admin_update_own_account_failure";
export const CLEAR_HOME_MODAL_FORM_STATE = "clear_home_modal_form_state";

// Reset Password Types
export const RESET_PASSWORD_ROUTE = `${BACKEND_URL}/admin_auth/password`;
export const RESET_PASSWORD = "reset_password";
export const RESET_PASSWORD_COMPLETE = "reset_password_complete";
export const CLEAR_RESET_PASSWORD_STATE = "clear_reset_password_state";
export const RESET_PASSWORD_FORM_ERROR_CHANGED = "reset_password_form_error_changed";
export const RESET_PASSWORD1_CHANGED = "reset_password1_changed";
export const RESET_PASSWORD2_CHANGED = "reset_password2_changed";


// Forgot Password Types
export const REQUEST_PASSWORD_RESET_LINK_ROUTE = `${BACKEND_URL}/admin_auth/password`;
export const REQUEST_PASSWORD_RESET_LINK = "request_password_reset_link";
export const REQUEST_PASSWORD_RESET_LINK_SUCCESS = "request_password_reset_link_success";
export const REQUEST_PASSWORD_RESET_LINK_FAILURE = "request_password_reset_link_failure";
export const REQUEST_PASSWORD_RESET_LINK_COMPLETE = "request_password_reset_link_complete";
export const CLEAR_REQUEST_PASSWORD_RESET_LINK_STATE = "clear_request_password_reset_link_state";


// Resend Unlock Types
export const RESEND_UNLOCK_LINK_ROUTE = `${BACKEND_URL}/admin_auth/unlock`;
export const RESEND_UNLOCK_LINK = "resend_unlock_link";
export const RESEND_UNLOCK_LINK_SUCCESS = "resend_unlock_link_success";
export const RESEND_UNLOCK_LINK_FAILURE = "resend_unlock_link_failure";
export const RESEND_UNLOCK_LINK_COMPLETE = "resend_unlock_link_complete";
export const CLEAR_RESEND_UNLOCK_STATE = "clear_resend_unlock_state";



// Login Types
export const LOGIN_ROUTE = `${BACKEND_URL}/admin_auth/sign_in`;
export const LOGIN_ADMIN = "login_admin";
export const LOGIN_ADMIN_SUCCESS = "login_admin_success";
export const LOGIN_ADMIN_FAILURE = "login_admin_failure";
export const LOGOUT_SUCCESS = "logout_success";
export const LOGOUT_ROUTE = `${BACKEND_URL}/admin_auth/sign_out`;