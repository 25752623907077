import {
    LOGOUT_SUCCESS,
    INITIALIZE_LANDING_PAGE_IMAGES_PAGE,
    INITIALIZE_LANDING_PAGE_IMAGES_PAGE_COMPLETE,
    INITIALIZE_LANDING_PAGE_IMAGES_ROUTE,
    CLEAR_LANDING_PAGE_IMAGES_STATE,
    ADD_LANDING_IMAGE,
    ADD_LANDING_IMAGE_COMPLETE,
    ADD_LANDING_IMAGE_FAILURE,
    ADD_LANDING_IMAGE_ROUTE,
    ADD_LANDING_IMAGE_SUCCESS,
    DELETE_LANDING_IMAGE_ROUTE,
    DELETE_LANDING_IMAGE,
    DELETE_LANDING_IMAGE_COMPLETE,
    DELETE_LANDING_IMAGE_FAILURE,
    DELETE_LANDING_IMAGE_SUCCESS
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const deleteLandingImage = (access_token, client, uid, history, id) => {

    return(dispatch) => {

        dispatch({type: DELETE_LANDING_IMAGE});

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: id
        });


        axios.post(DELETE_LANDING_IMAGE_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: DELETE_LANDING_IMAGE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const landing_page_images = data.landing_images;

                    dispatch({type: DELETE_LANDING_IMAGE_SUCCESS, payload: landing_page_images});

                }else{

                    const landing_page_images = data.landing_images;

                    const message = data.message;

                    dispatch({type: DELETE_LANDING_IMAGE_FAILURE, payload: {
                        landing_page_images: landing_page_images,
                        landing_image_error_message: message
                    }});


                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};

export const addLandingImage = (access_token, client, uid, history, picture) => {

    return(dispatch) => {

        dispatch({type: ADD_LANDING_IMAGE});

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = new FormData();



        const isBase64 = picture.dataURL.includes("base64");

        let data = {
            isBase64: isBase64
        };

        if(isBase64){

            let file = {};

            file.uri = picture.dataURL;

            file.name = picture.file.name;

            file.type = picture.file.type;

            file = JSON.stringify(file);

            bodyFormData.append('picture', file);


        }else{


            bodyFormData.append('picture', picture);

        }

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });

        axios.post(ADD_LANDING_IMAGE_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: ADD_LANDING_IMAGE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const landing_page_images = data.landing_images;

                    dispatch({type: ADD_LANDING_IMAGE_SUCCESS, payload: landing_page_images});

                }else{

                    const message = data.message;

                    dispatch({type: ADD_LANDING_IMAGE_FAILURE, payload: message});


                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};


export const clearLandingPageImagesState = () => {

    return{
      type: CLEAR_LANDING_PAGE_IMAGES_STATE
    };

};

export const initializeLandingPageImagesPage = (access_token, client, uid, history) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_LANDING_PAGE_IMAGES_PAGE});

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };



        axios.get(INITIALIZE_LANDING_PAGE_IMAGES_ROUTE, config)
            .then(response => {

               const data = response.data;

               const landing_images = data.landing_images;

               console.log(landing_images);

               dispatch({type: INITIALIZE_LANDING_PAGE_IMAGES_PAGE_COMPLETE, payload: landing_images})

            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };

};