import {
    LOGOUT_SUCCESS,
    VIEW_CUSTOMER_ACCOUNT_CHANGED,
    INITIALIZE_VIEW_CUSTOMER_PAGE_ROUTE,
    INITIALIZE_VIEW_CUSTOMER_PAGE,
    INITIALIZE_VIEW_CUSTOMER_PAGE_COMPLETE,
    CLEAR_VIEW_CUSTOMER_PAGE_STATE,
    OPEN_APPROVE_ACCOUNT_MODAL,
    CLOSE_APPROVE_ACCOUNT_MODAL,
    CHANGE_ACCOUNT_APPROVAL_STATUS_ROUTE,
    APPROVE_CUSTOMER_ACCOUNT,
    APPROVE_CUSTOMER_ACCOUNT_COMPLETE,
    APPROVE_ACCOUNT_MODAL_ERROR_CHANGED
} from "./types";

import axios from "axios";
import { getFormData, getUsaStateCode } from "../helpers";
import _ from "lodash";

export const approveAccount = (access_token, client, uid, history, customer_id) => {

    return(dispatch) => {

        dispatch({type: APPROVE_ACCOUNT_MODAL_ERROR_CHANGED, payload: ''});

        dispatch({type: APPROVE_CUSTOMER_ACCOUNT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            customer_id: customer_id,
            account_approved: true
        });

        axios.post(CHANGE_ACCOUNT_APPROVAL_STATUS_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: APPROVE_CUSTOMER_ACCOUNT_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const customer = data.customer;

                    console.log(customer);

                    const email = customer.email;

                    const full_name = customer.full_name;

                    const company_name = customer.company_name;

                    const company_address = customer.company_address;

                    const company_state = customer.company_state;

                    const company_city = customer.company_city;

                    const company_zip_code = customer.company_zip_code;

                    const account_approved = customer.account_approved;

                    const state_code = getUsaStateCode(company_state);

                    const seller_permit_url = customer.seller_permit_url;

                    const email_consent_given = customer.email_consent_given;


                    dispatch({type: VIEW_CUSTOMER_ACCOUNT_CHANGED , payload: {
                        email: email,
                        full_name: full_name,
                        company_name: company_name,
                        company_address: company_address,
                        company_state: company_state,
                        company_city: company_city,
                        company_zip_code: company_zip_code,
                        account_approved: account_approved,
                        state_code: state_code,
                        seller_permit_url: seller_permit_url,
                        email_consent_given: email_consent_given
                    }});


                    dispatch({type: CLOSE_APPROVE_ACCOUNT_MODAL});


                }else{

                    const message = data.message;

                    dispatch({type: APPROVE_ACCOUNT_MODAL_ERROR_CHANGED, payload: message});

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };


};


export const closeApproveAccountModal = () => {

    return{
      type: CLOSE_APPROVE_ACCOUNT_MODAL
    };

};

export const openApproveAccountModal = () => {

    return{
      type: OPEN_APPROVE_ACCOUNT_MODAL
    };

};

export const clearViewCustomerPageState = () => {

    return{
      type: CLEAR_VIEW_CUSTOMER_PAGE_STATE
    };

};

export const initializeViewCustomerPage = (access_token, client, uid, history, customer_id) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_VIEW_CUSTOMER_PAGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            customer_id: customer_id
        });


        axios.post(INITIALIZE_VIEW_CUSTOMER_PAGE_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: INITIALIZE_VIEW_CUSTOMER_PAGE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const customer = data.customer;

                    console.log(customer);

                    const email = customer.email;

                    const full_name = customer.full_name;

                    const company_name = customer.company_name;

                    const company_address = customer.company_address;

                    const company_state = customer.company_state;

                    const company_city = customer.company_city;

                    const company_zip_code = customer.company_zip_code;

                    const account_approved = customer.account_approved;

                    const state_code = getUsaStateCode(company_state);

                    const seller_permit_url = customer.seller_permit_url;

                    const email_consent_given = customer.email_consent_given;


                    dispatch({type: VIEW_CUSTOMER_ACCOUNT_CHANGED , payload: {
                        email: email,
                        full_name: full_name,
                        company_name: company_name,
                        company_address: company_address,
                        company_state: company_state,
                        company_city: company_city,
                        company_zip_code: company_zip_code,
                        account_approved: account_approved,
                        state_code: state_code,
                        seller_permit_url: seller_permit_url,
                        email_consent_given: email_consent_given
                    }});


                }else{

                    history.push("/customer-accounts");

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};
