import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeEditProductPage,
    clearEditProductState,
    editProductNameChanged,
    editProductDescriptionChanged,
    editProductUpcChanged,
    editProductSkuChanged,
    editProductTopicChanged,
    editProductAttributesChanged,
    editProductCategoryChanged,
    removeProductImage,
    hideEditProductModal,
    addProductImages,
    updateProduct,
    replaceProductPicture,
    editProductBrandNameChanged,
    editPackSizeModalVisibleChanged,
    newPackSizeChanged,
    editPackSize,
    editPackSizeModalErrorChanged,
    editPriceModalVisibleChanged,
    newPriceChanged,
    editPrice,
    editPriceModalErrorChanged,
    editSelectedPriceRangeIdChanged,
    clearEditPriceModalState,
    clearEditPackSizeModalState,
    openEditPriceModal,
    removePriceRange,
    openEditQuantityModal,
    clearEditQuantityModalState,
    newEndQuantityChanged,
    editQuantityModalErrorChanged,
    editQuantity,
    endQuantityErrorChanged,
    priceErrorChanged,
    addPriceModalErrorChanged,
    openAddPriceModal,
    clearAddPriceModalState,
    addPrice,
    deletePriceList,
    packSizeErrorChanged,
    createPriceListModalErrorChanged,
    openCreatePriceListModal,
    clearCreatePriceListModalState,
    createPriceList,
    editProductQuantityChanged
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";
import ImageUploading from 'react-images-uploading';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Typeahead } from 'react-bootstrap-typeahead';

class EditProduct extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const product_id = params.product_id;

        const add_attribute_modal_visible = false;

        const attribute_name = "";

        const attribute_value = "";

        const edit_pack_size_modal_visible = false;


        this.state = {
            history,
            params,
            location,
            width,
            height,
            product_id,
            add_attribute_modal_visible,
            attribute_name,
            attribute_value,
            edit_pack_size_modal_visible
        };

    }

    componentWillUnmount(){

        this.props.clearEditProductState();

    }

    componentDidMount(){

        const {
            logged_in,
            roles,
            initializeEditProductPage,
            access_token,
            client,
            uid
        } = this.props;

        const { history, product_id } = this.state;

        if(!logged_in){

            history.push("/");

        }else if(!roles.includes("root_admin") && !roles.includes("lister")) {

            history.push("/home");

        }else{

            initializeEditProductPage(access_token, client, uid, history, product_id);


        }

    }

    renderProductAttributes(){

        const { product_attributes, editProductAttributesChanged } = this.props;

        if(!_.isEmpty(product_attributes)){

            return _.map(product_attributes, (attribute_value, attribute_name) => {



                return(

                    <Form.Group
                        style={{
                            marginBottom: '1.5rem',
                            display: 'flex',
                            flexDirection: 'row',
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5
                        }}
                        key={attribute_name}
                    >

                        <Form.Control
                            value={attribute_name}
                            readOnly
                            style={{
                                marginRight: '20px',
                            }}
                        />

                        <Form.Control
                            value={attribute_value}
                            readOnly
                            style={{
                                marginRight: '20px',
                            }}
                        />

                        <Button
                            variant="danger"
                            onClick={() => {

                                let new_product_attributes = product_attributes;

                                delete new_product_attributes[attribute_name];

                                editProductAttributesChanged(new_product_attributes);


                            }}
                        >
                            Remove
                        </Button>


                    </Form.Group>


                );

            });

        }

    }


    renderUploadImages(){

        const {
            pictures,
            access_token,
            client,
            uid,
            removeProductImage,
            addProductImages,
            editing_images,
            replaceProductPicture
        } = this.props;

        const {
            history,
            product_id
        } = this.state;


        const responsive =  {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                slidesToSlide: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1
            }
        };


        if(editing_images){


            return(

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '20px'
                }}>

                    <Spinner animation="border" variant="primary"  />

                </div>



            );


        }else{

            return(

                <Fragment>

                    <Form.Label
                        className="edit-product-form-label"
                        style={{
                            marginBottom: '1rem'
                        }}
                    >
                        Upload Images (at least 1)
                    </Form.Label>

                    <ImageUploading
                        multiple={true}
                        value={pictures}
                        onChange={(imageList, addUpdateIndex) => {

                            console.log(imageList);

                            console.log(addUpdateIndex);

                            console.log(pictures);

                            if(addUpdateIndex.length === 1 && pictures[addUpdateIndex[0]] !== undefined ){

                                const new_picture = imageList[addUpdateIndex[0]];

                                const replace_index = addUpdateIndex[0];

                                const picture_name = pictures[replace_index].image_name;

                                replaceProductPicture(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    product_id,
                                    new_picture,
                                    replace_index,
                                    picture_name
                                );

                            }else{

                                let new_pictures = [];

                                for(let i = 0; i < addUpdateIndex.length; i++){

                                    new_pictures.push(imageList[addUpdateIndex[i]]);

                                }

                                console.log(new_pictures);

                                addProductImages(access_token, client, uid, history, product_id, new_pictures);


                            }




                        }}
                        acceptType={['jpg' , 'jpeg',  'gif', 'png', 'webp']}
                    >

                        {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps
                          }) => (
                            // write your building UI
                            <div >

                                <Button
                                    style={ {
                                        color: isDragging ? 'red' : null,
                                        marginRight: '20px',
                                        marginBottom: '3rem'
                                    }}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                    variant="success"
                                >
                                    { isMobile ? "Upload" : "Click or Drop here"  }
                                </Button>



                                <Carousel
                                    responsive={responsive}
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                >

                                    {imageList.map((image, index) => (


                                        <Card
                                            key={index}
                                            style={  {
                                                marginRight: isMobile ? 0 : '20px',
                                            }}
                                        >

                                            <Image
                                                src={image.uri}
                                                style={ isMobile ? {
                                                    width: '300px',
                                                    height: '200px',
                                                    padding: '20px'
                                                } : {
                                                    width: '400px',
                                                    height: '300px',
                                                    padding: '20px'
                                                }}
                                                className="edit-product-image"
                                            />


                                            <Card.Footer>

                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    padding: '10px'
                                                }}>


                                                    <Button
                                                        onClick={() => {
                                                            onImageUpdate(index);
                                                        }}
                                                        variant="success"
                                                        style={{
                                                            width: '90%',
                                                            marginBottom: '15px'
                                                        }}
                                                    >
                                                        Replace
                                                    </Button>


                                                    <Button
                                                        onClick={() => {

                                                            removeProductImage(
                                                                access_token,
                                                                client,
                                                                uid,
                                                                history,
                                                                product_id,
                                                                image.image_name
                                                            );

                                                        }}
                                                        variant="danger"
                                                        style={{
                                                            width: '90%',
                                                            marginBottom: '15px'
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>

                                                </div>


                                            </Card.Footer>







                                        </Card>




                                    ))}

                                </Carousel>








                            </div>
                        )}


                    </ImageUploading>

                </Fragment>

            );


        }

    }


    removePriceButton(price_range, index){

        const {
            price_ranges,
            access_token,
            client,
            uid,
            removePriceRange
        } = this.props;

        const {
            history
        } = this.state;



        const price_range_max_start_quantity = _.maxBy(price_ranges, function(pr) { return pr.start_quantity });

        const max_start_quantity = price_range_max_start_quantity.start_quantity;

        if( max_start_quantity !== 1 && price_range.start_quantity === max_start_quantity){

            return(

                <Button
                    variant="link"
                    id="remove-price-button"
                    onClick={() => {
                        removePriceRange(access_token, client, uid, history, price_range.id, price_ranges, index);
                    }}
                >
                    Remove Price
                </Button>

            );

        }


    }

    editQuantityButton(price_range, index){

        const { price_ranges,openEditQuantityModal  } = this.props;

        const price_range_max_start_quantity = _.maxBy(price_ranges, function(pr) { return pr.start_quantity });

        if(price_range.start_quantity === price_range_max_start_quantity.start_quantity){

            return(

                <Button
                    variant="link"
                    id="edit-price-quantity-button"
                    onClick={() => {
                        openEditQuantityModal(price_range.start_quantity, price_range.end_quantity, price_range.id);
                    }}
                >
                    Edit Quantity
                </Button>

            );

        }



    }


    renderPriceRange(price_range, index){

        const {  openEditPriceModal } = this.props;

        return(

            <Fragment>




                <Form.Group
                    style={isMobile ? {
                        marginBottom: '25px'
                    } : {
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '20px',
                    }}
                >

                    <Form.Label>Start Quantity</Form.Label>

                    <Form.Control
                        value={price_range.start_quantity}
                        disabled
                    />

                </Form.Group>


                <Form.Group
                    style={isMobile ? {
                        marginBottom: '25px'
                    } :{
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '20px',
                    }}
                >

                    <Form.Label>End Quantity</Form.Label>

                    <Form.Control
                        value={price_range.end_quantity === undefined ? '' : price_range.end_quantity}
                        disabled
                    />

                </Form.Group>


                <Form.Group
                    style={isMobile ? {
                        marginBottom: '25px'
                    } :{
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '20px',
                    }}
                >

                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}>

                        <Form.Label>Price (USD)</Form.Label>

                        <span
                            id="change-price-button"
                            onClick={() => {




                                openEditPriceModal(price_range.price, price_range.id);


                            }}
                        >
                                    Edit
                        </span>


                    </div>



                    <Form.Control
                        value={price_range.price}
                        disabled
                    />

                </Form.Group>


                {this.editQuantityButton(price_range, index)}

                {this.removePriceButton(price_range, index)}







            </Fragment>

        );


    }

    renderPriceList(){

        const { price_ranges } = this.props;

        if(!_.isEmpty(price_ranges)){

            return _.map(price_ranges, (price_range, index) => {


                if(isMobile){

                    return(

                        <Card
                            key={index}
                            style={{
                                marginBottom: '1.5rem',
                                width: this.state.width - 60
                            }}
                        >
                            <Card.Body style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>

                                {this.renderPriceRange(price_range, index)}

                            </Card.Body>

                        </Card>

                    );

                }else{

                    return(

                        <div
                            style={{
                                marginBottom: '1.5rem',
                                display: 'flex',
                                flexDirection: isMobile ? 'column' :  'row',
                                width: isMobile ? this.state.width - 60 : this.state.width / 1.5
                            }}
                            key={index}
                        >

                            {this.renderPriceRange(price_range, index)}

                        </div>

                    );

                }


            });

        }

    }

    priceListForm(){

        const {
            price_list_form_visible,
            pack_size,
            editPackSizeModalVisibleChanged,
            newPackSizeChanged,
            removing_price_range,
            openAddPriceModal,
            price_ranges,
            deletePriceList,
            price_list_id,
            access_token,
            client,
            uid,
            deleting_price_list
        } = this.props;

        const { history } = this.state;

        if(price_list_form_visible){

            if(removing_price_range ||  deleting_price_list){

                return(

                    <div style={{
                        display: 'flex',
                        justifyContent: 'center'
                    }}>

                        <Spinner animation="border" variant="primary"  />

                    </div>

                )


            }else{

                return(

                    <Fragment>


                        <Button
                            variant="danger"
                            onClick={() => {

                                deletePriceList(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    price_list_id
                                );

                            }}
                            style={ isMobile ? {
                                marginBottom: '30px',
                                width:  '100%',
                                alignSelf: 'center'
                            } : {
                                marginBottom: '30px',
                                width: '10%'
                            }}
                        >
                            Delete List
                        </Button>


                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                        >

                            <Form.Label
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '15px'
                                }}
                            >
                                Pack Size (*)
                            </Form.Label>


                            <span
                                id="edit-pack-size-button"
                                onClick={() => {

                                    editPackSizeModalVisibleChanged(true);

                                    newPackSizeChanged(pack_size);

                                }}
                            >
                                    Edit
                                </span>

                        </div>



                        <Form.Control
                            value={pack_size}
                            style={{
                                marginBottom: '1.5rem'
                            }}
                            disabled
                        />



                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '20px',
                            }}
                        >
                            Prices (*)
                        </Form.Label>

                        <Button
                            variant="success"
                            style={ isMobile ? {
                                marginBottom: '30px',
                                width:  '100%',
                                alignSelf: 'center'
                            } : {
                                marginBottom: '30px',
                                width: '10%'
                            }}
                            onClick={() => {

                                if(_.isEmpty(price_ranges)){

                                    openAddPriceModal(1);

                                }else{

                                    const price_range_max_start_quantity = _.maxBy(price_ranges, function(pr) { return pr.start_quantity });

                                    let next_start_quantity;

                                    if(price_range_max_start_quantity.end_quantity === undefined){

                                        next_start_quantity = price_range_max_start_quantity.start_quantity + 1;

                                    }else{

                                        next_start_quantity = price_range_max_start_quantity.end_quantity + 1;

                                    }


                                    openAddPriceModal(next_start_quantity);


                                }

                            }}
                        >
                            Add Price
                        </Button>


                        {this.renderPriceList()}





                    </Fragment>

                );

            }



        }


    }


    createPriceListButton(){

        const { price_list_id, price_list_form_visible, openCreatePriceListModal } = this.props;

        if(_.isEmpty(price_list_id) && !price_list_form_visible){

            return(

                <Button
                    variant="success"
                    onClick={() => {
                        openCreatePriceListModal();
                    }}
                    style={ isMobile ? {
                        marginBottom: '30px',
                        width:  '100%',
                        alignSelf: 'center'
                    } : {
                        marginBottom: '30px',
                        width: '10%'
                    }}
                >
                    Create List
                </Button>

            );

        }


    }

    renderForm(){

        const {
            editing_product,
            editProductNameChanged,
            name,
            description,
            editProductDescriptionChanged,
            upc,
            editProductUpcChanged,
            sku,
            editProductSkuChanged,
            editProductTopicChanged,
            topic_name,
            categories,
            category_id,
            editProductCategoryChanged,
            access_token,
            client,
            uid,
            updateProduct,
            product_attributes,
            editProductBrandNameChanged,
            brand_name,
            editProductQuantityChanged,
            quantity
        } = this.props;

        const {
            history,
            product_id
        } = this.state;

        const default_category = _.find(categories, {id: category_id});


        if(editing_product){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>


            );

        }else{

            return(

                <Fragment>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Name (*)
                        </Form.Label>


                        <Form.Control
                            value={name}
                            onChange={(e) => {
                                editProductNameChanged(e.target.value);
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Description (*)
                        </Form.Label>


                        <Form.Control
                            value={description}
                            as="textarea"
                            onChange={(e) => {
                                editProductDescriptionChanged(e.target.value);
                            }}
                            rows={10}
                        />

                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            UPC (*)
                        </Form.Label>


                        <Form.Control
                            value={upc}
                            onChange={(e) => {
                                editProductUpcChanged(e.target.value);
                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            SKU (*)
                        </Form.Label>


                        <Form.Control
                            value={sku}
                            onChange={(e) => {
                                editProductSkuChanged(e.target.value);
                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Topic (optional)
                        </Form.Label>

                        <p>
                            This product will be shown in the landing page under the topic below.
                        </p>

                        <Form.Control
                            value={topic_name}
                            onChange={(e) => {
                                editProductTopicChanged(e.target.value);
                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Brand Name (optional)
                        </Form.Label>

                        <Form.Control
                            value={brand_name}
                            onChange={(e) => {
                                editProductBrandNameChanged(e.target.value);
                            }}
                        />

                    </Form.Group>



                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Attributes (optional)
                        </Form.Label>



                        <Button
                            variant="success"
                            onClick={() => {
                                this.setState({add_attribute_modal_visible: true});
                            }}
                            style={ isMobile ? {
                                marginBottom: '30px',
                                width:  '90%',
                                alignSelf: 'center'
                            } : {
                                marginBottom: '30px',
                                width: '10%'
                            }}
                        >
                            Add Attribute
                        </Button>


                        {this.renderProductAttributes()}




                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Category
                        </Form.Label>


                        <Typeahead
                            id="category-selector"
                            labelKey={category => `${category.name}`}
                            onChange={(selected_categories) => {

                                console.log(selected_categories);

                                if(!_.isEmpty(selected_categories)){

                                    const new_selected_category = selected_categories[0];

                                    const new_category_id = new_selected_category.id;

                                    console.log(new_category_id);

                                    editProductCategoryChanged(new_category_id);

                                }


                            }}
                            options={categories}
                            defaultSelected={(default_category === undefined || default_category === null ) ? [] : [default_category]}
                        />



                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Price List (optional)
                        </Form.Label>

                        {this.createPriceListButton()}

                        {this.priceListForm()}


                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="edit-product-form-label"
                        >
                            Quantity (optional)
                        </Form.Label>


                        <Form.Control
                            value={quantity}
                            onChange={(e) => {
                                editProductQuantityChanged(e.target.value);
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        {this.renderUploadImages()}

                    </Form.Group>


                    <Button
                        style={{
                            width:  isMobile ? this.state.width - 60 : '25%',
                            marginTop: '1.5rem',
                            borderRadius: 20,
                            fontSize: '20px'
                        }}
                        variant="success"
                        onClick={() => {

                            updateProduct(
                                access_token,
                                client,
                                uid,
                                history,
                                product_id,
                                name,
                                description,
                                upc,
                                sku,
                                product_attributes,
                                category_id,
                                topic_name,
                                brand_name,
                                quantity
                            );

                        }}
                    >
                        Update
                    </Button>






                </Fragment>

            );

        }

    }

    exitAddAttributeModal(){

        this.setState({
            add_attribute_modal_visible: false,
            attribute_name: '',
            attribute_value: ''
        });

    }

    addAttributeModal(){

        const {
            add_attribute_modal_visible,
            attribute_name,
            attribute_value
        } = this.state;

        const {
            product_attributes,
            editProductAttributesChanged
        } = this.props;

        if(add_attribute_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={add_attribute_modal_visible}
                    onHide={() => {
                        this.exitAddAttributeModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Attribute
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label>
                                Attribute Name (*)
                            </Form.Label>


                            <Form.Control
                                value={attribute_name}
                                onChange={(e) => {
                                    this.setState({attribute_name: e.target.value})
                                }}
                            />

                        </Form.Group>

                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label>
                                Attribute Value (*)
                            </Form.Label>


                            <Form.Control
                                value={attribute_value}
                                onChange={(e) => {
                                    this.setState({attribute_value: e.target.value})
                                }}
                            />

                        </Form.Group>


                    </Modal.Body>



                    <Modal.Footer>

                        <Button
                            variant="success"
                            style={{
                                marginRight: '10px'
                            }}
                            onClick={() => {

                                console.log(product_attributes);

                                if(!_.isEmpty(attribute_name) && !_.isEmpty(attribute_value)){

                                    const new_attribute_name = attribute_name.trim();

                                    const new_attribute_value = attribute_value.trim();

                                    if(product_attributes[new_attribute_name] === undefined){

                                        let new_product_attributes = product_attributes;

                                        new_product_attributes[new_attribute_name] = new_attribute_value;

                                        editProductAttributesChanged(new_product_attributes);

                                    }

                                    this.setState({attribute_name: '', attribute_value: ''});

                                }

                            }}>
                            Add
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitAddAttributeModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }



    }

    editProductModalBody(){

        const {
            success_message,
            error_message
        } = this.props;

        if(success_message.length > 0){

            return(

                <p style={{
                    fontSize: '18px',
                    color: '#008000'
                }}>
                    {success_message}
                </p>

            );

        }else{

            return(
                <p style={{
                    fontSize: '18px',
                    color: '#FF0000'
                }}>
                    {error_message}
                </p>
            )

        }


    }


    exitEditPackSizeModal(){


        this.props.clearEditPackSizeModalState();


    }

    editPackSizeModalFooter(){


        const {
            new_pack_size,
            editPackSize,
            access_token,
            client,
            uid,
            price_list_id,
            editing_pack_size,
            editPackSizeModalErrorChanged
        } = this.props;

        const { history } = this.state;

        if(!editing_pack_size){

            return(

                <Fragment>

                    <Button
                        variant="success"
                        style={{
                            marginRight: '10px'
                        }}
                        onClick={() => {


                            editPackSizeModalErrorChanged('');

                            if(_.isEmpty(new_pack_size)){

                                editPackSizeModalErrorChanged('Pack size cannot be empty');

                            }else if(!/^[1-9]\d*$/.test(new_pack_size)) {

                                editPackSizeModalErrorChanged('Pack size must be a positive integer');

                            }else{

                                editPackSize( access_token, client, uid, history, price_list_id, new_pack_size);

                            }


                        }}>
                        Submit
                    </Button>


                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.exitEditPackSizeModal();
                        }}>
                        Close
                    </Button>

                </Fragment>

            );


        }


    }


    editPackSizeModalBody(){

        const {
            new_pack_size,
            newPackSizeChanged,
            edit_pack_size_modal_error,
            editing_pack_size
        } = this.props;

        if(editing_pack_size){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );

        }else{

            return(

                <Form.Group>

                    <Form.Label
                        style={{
                            fontSize: '18px',
                            marginBottom: '15px',
                            marginTop: '10px'
                        }}
                    >
                        Pack Size (*)
                    </Form.Label>


                    <p style={{
                        color: 'blue'
                    }}>
                        Must be a positive integer.
                    </p>


                    <Form.Control
                        value={new_pack_size}
                        onChange={(e) => {
                            newPackSizeChanged(e.target.value);
                        }}
                        style={{
                            marginBottom: '15px'
                        }}
                        isInvalid={!_.isEmpty(edit_pack_size_modal_error)}
                    />

                    <Form.Control.Feedback type="invalid">
                        {edit_pack_size_modal_error}
                    </Form.Control.Feedback>

                </Form.Group>

            );

        }

    }

    editPackSizeModal(){


        const {
            edit_pack_size_modal_visible
        } = this.props;


        if(edit_pack_size_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={edit_pack_size_modal_visible}
                    onHide={() => {
                        this.exitEditPackSizeModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Pack Size
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.editPackSizeModalBody()}


                    </Modal.Body>



                    <Modal.Footer>

                        {this.editPackSizeModalFooter()}

                    </Modal.Footer>

                </Modal>


            );

        }



    }

    exitEditPriceModal(){

        this.props.clearEditPriceModalState();

    }


    editPriceModalBody(){

        const {
            edit_price_modal_visible,
            new_price,
            editing_price,
            edit_price_modal_error,
            selected_price_range_id,
            newPriceChanged
        } = this.props;


        if(editing_price){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );

        }else{

            return(

                <Form.Group>

                    <Form.Label
                        style={{
                            fontSize: '18px',
                            marginBottom: '15px',
                            marginTop: '10px'
                        }}
                    >
                        Price (USD) (*)
                    </Form.Label>


                    <p style={{
                        color: 'blue'
                    }}>
                        Must be at least 0.5 USD.
                    </p>


                    <Form.Control
                        value={new_price}
                        onChange={(e) => {
                            newPriceChanged(e.target.value);
                        }}
                        style={{
                            marginBottom: '15px'
                        }}
                        isInvalid={!_.isEmpty(edit_price_modal_error)}
                    />

                    <Form.Control.Feedback type="invalid">
                        {edit_price_modal_error}
                    </Form.Control.Feedback>

                </Form.Group>

            );


        }


    }

    editPriceModalFooter(){

        const {
            edit_price_modal_visible,
            editing_price,
            edit_price_modal_error,
            selected_price_range_id,
            editPrice,
            access_token,
            client,
            uid,
            price_ranges,
            editPriceModalErrorChanged,
            new_price
        } = this.props;


        const { history } = this.state;

        if(!editing_price){

            return(

                <Fragment>

                    <Button
                        variant="success"
                        style={{
                            marginRight: '10px'
                        }}
                        onClick={() => {

                            editPriceModalErrorChanged('');

                            let is_valid = true;

                            if(_.isEmpty(new_price)){

                                is_valid = false;

                                editPriceModalErrorChanged('Price cannot be empty');

                            } else{

                                if(!/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(new_price)){

                                    is_valid = false;

                                    editPriceModalErrorChanged('Price must be a positive decimal number');


                                }else{


                                    if(parseFloat(new_price) < 0.5){

                                        is_valid = false;

                                        editPriceModalErrorChanged('Price must be at least 0.5');

                                    }

                                }


                            }

                            if(is_valid){

                                editPrice(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    selected_price_range_id,
                                    new_price,
                                    price_ranges
                                );

                            }



                        }}>
                        Submit
                    </Button>


                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.exitEditPriceModal();
                        }}>
                        Close
                    </Button>

                </Fragment>

            );

        }


    }

    editPriceModal(){

        const {
            edit_price_modal_visible,
            new_price,
            editing_price,
            edit_price_modal_error,
            selected_price_range_id
        } = this.props;


        if(edit_price_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={edit_price_modal_visible}
                    onHide={() => {
                        this.exitEditPriceModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Change Price
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.editPriceModalBody()}

                    </Modal.Body>



                    <Modal.Footer>

                        {this.editPriceModalFooter()}

                    </Modal.Footer>

                </Modal>

            );

        }


    }


    editProductModal(){

        const { edit_product_modal_visible, hideEditProductModal } = this.props;

        if(edit_product_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={edit_product_modal_visible}
                    onHide={() => {
                        hideEditProductModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Result
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.editProductModalBody()}

                    </Modal.Body>



                    <Modal.Footer>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                hideEditProductModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }


    }

    exitEditQuantityModal(){

        this.props.clearEditQuantityModalState();

    }

    editQuantityModalBody(){



        const {
            edit_quantity_modal_visible,
            new_start_quantity,
            new_end_quantity,
            edit_quantity_modal_error,
            newEndQuantityChanged,
            editQuantityModalErrorChanged,
            editing_quantity
        } = this.props;


        if(editing_quantity){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <Spinner animation="border" variant="primary" />


                </div>

            );

        }else{

            return(

                <Fragment>

                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            Start Quantity
                        </Form.Label>


                        <Form.Control
                            value={new_start_quantity}
                            disabled
                        />


                    </Form.Group>


                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            End Quantity (optional)
                        </Form.Label>

                        <p style={{
                            color: 'blue'
                        }}>
                            Must be a positive integer greater than start quantity.
                        </p>


                        <Form.Control
                            value={new_end_quantity}
                            onChange={(e) => {
                                newEndQuantityChanged(e.target.value)
                            }}
                            isInvalid={!_.isEmpty(edit_quantity_modal_error)}
                        />

                        <Form.Control.Feedback type="invalid">
                            {edit_quantity_modal_error}
                        </Form.Control.Feedback>


                    </Form.Group>

                </Fragment>

            );


        }

    }


    editQuantityModalFooter(){

        const {
            edit_quantity_modal_visible,
            new_start_quantity,
            edit_quantity_modal_error,
            newEndQuantityChanged,
            editQuantityModalErrorChanged,
            editing_quantity,
            editQuantity,
            access_token,
            client,
            uid,
            selected_price_range_id,
            new_end_quantity,
            price_ranges
        } = this.props;

        const { history } = this.state;

        if(!editing_quantity){

            return(

                <Fragment>

                    <Button
                        variant="success"
                        onClick={() => {


                            editQuantityModalErrorChanged('');

                            let is_valid = true;


                            if(!_.isEmpty(new_end_quantity)){

                                if(!/^[1-9]\d*$/.test(new_end_quantity)){

                                    is_valid = false;

                                    editQuantityModalErrorChanged('End quantity must be a positive integer');

                                }else{


                                    if(parseInt(new_end_quantity) <= parseInt(new_start_quantity)){

                                        is_valid = false;

                                        editQuantityModalErrorChanged('End quantity must be greater than start quantity');

                                    }


                                }

                            }


                            if(is_valid){


                                editQuantity(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    selected_price_range_id,
                                    new_end_quantity,
                                    price_ranges
                                );

                            }





                        }}>
                        Submit
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.exitEditQuantityModal();
                        }}>
                        Close
                    </Button>

                </Fragment>

            );

        }


    }

    editQuantityModal(){

        const {
            edit_quantity_modal_visible
        } = this.props;

        if(edit_quantity_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={edit_quantity_modal_visible}
                    onHide={() => {
                        this.exitEditQuantityModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Quantity
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.editQuantityModalBody()}

                    </Modal.Body>



                    <Modal.Footer>


                        {this.editQuantityModalFooter()}

                    </Modal.Footer>

                </Modal>

            );

        }


    }

    exitAddPriceModal(){

        this.props.clearAddPriceModalState();

    }


    addPriceModalError(){

        const { add_price_modal_error } = this.props;

        if(!_.isEmpty(add_price_modal_error)){

            return(

                <p style={{
                    marginBottom: '30px',
                    textAlign: 'center',
                    color: '#ff0000'
                }}>
                    {add_price_modal_error}
                </p>


            );

        }


    }

    addPriceModalBody(){

        const {
            new_start_quantity,
            new_end_quantity,
            new_price,
            end_quantity_error,
            price_error,
            adding_price,
            newEndQuantityChanged,
            newPriceChanged
        } = this.props;

        if(adding_price){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );

        }else{

            return(

                <Fragment>

                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            Start Quantity
                        </Form.Label>


                        <Form.Control
                            value={new_start_quantity}
                            disabled
                        />


                    </Form.Group>


                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            End Quantity (optional)
                        </Form.Label>

                        <p style={{
                            color: 'blue'
                        }}>
                            Must be a positive integer greater than start quantity.
                        </p>


                        <Form.Control
                            value={new_end_quantity}
                            onChange={(e) => {
                                newEndQuantityChanged(e.target.value)
                            }}
                            isInvalid={!_.isEmpty(end_quantity_error)}
                        />

                        <Form.Control.Feedback type="invalid">
                            {end_quantity_error}
                        </Form.Control.Feedback>


                    </Form.Group>


                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            Price (*) (USD)
                        </Form.Label>

                        <p style={{
                            color: 'blue'
                        }}>
                            Must be at least 0.5 USD
                        </p>


                        <Form.Control
                            value={new_price}
                            onChange={(e) => {
                                newPriceChanged(e.target.value)
                            }}
                            isInvalid={!_.isEmpty(price_error)}
                        />

                        <Form.Control.Feedback type="invalid">
                            {price_error}
                        </Form.Control.Feedback>


                    </Form.Group>

                    {this.addPriceModalError()}



                </Fragment>


            );

        }


    }

    addPriceModalFooter(){

        const {
            adding_price,
            endQuantityErrorChanged,
            priceErrorChanged,
            addPriceModalErrorChanged,
            new_start_quantity,
            addPrice,
            access_token,
            client,
            uid,
            price_list_id,
            new_price,
            new_end_quantity
        } = this.props;

        const { history } = this.state;




        if(!adding_price){

            return(

                <Fragment>

                    <Button
                        variant="success"
                        onClick={() => {

                            let is_valid = true;


                            priceErrorChanged('');

                            endQuantityErrorChanged('');


                            if(_.isEmpty(new_price)){

                                is_valid = false;

                                priceErrorChanged('Price cannot be empty');

                            } else{

                                if(!/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(new_price)){

                                    is_valid = false;

                                    priceErrorChanged('Price must be a positive decimal number');


                                }else{


                                    if(parseFloat(new_price) < 0.5){

                                        is_valid = false;

                                        priceErrorChanged('Price must be at least 0.5');

                                    }

                                }


                            }



                            if(!_.isEmpty(new_end_quantity)){


                                if(!/^[1-9]\d*$/.test(new_end_quantity)){

                                    is_valid = false;

                                    endQuantityErrorChanged('End quantity must be a positive integer');

                                }else{


                                    if(parseInt(new_end_quantity) <= parseInt(new_start_quantity)){

                                        is_valid = false;

                                        endQuantityErrorChanged('End quantity must be greater than start quantity');

                                    }


                                }



                            }

                            if(is_valid){

                                let price_range;

                                if(_.isEmpty(new_end_quantity)){

                                    price_range = {
                                        start_quantity: new_start_quantity,
                                        price: new_price
                                    };

                                }else{

                                    price_range = {
                                        start_quantity: new_start_quantity,
                                        end_quantity: new_end_quantity,
                                        price: new_price
                                    }

                                }


                                addPrice (
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    price_list_id,
                                    price_range
                                )

                            }

                        }}>
                        Submit
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.exitAddPriceModal();
                        }}>
                        Close
                    </Button>

                </Fragment>


            );

        }


    }

    exitCreatePriceListModal(){

        this.props.clearCreatePriceListModalState();

    }

    createPriceListModalError(){

        const { create_price_list_modal_error } = this.props;

        if(!_.isEmpty(create_price_list_modal_error)){

            return(

                <p style={{
                    marginBottom: '30px',
                    textAlign: 'center',
                    color: '#ff0000'
                }}>
                    {create_price_list_modal_error}
                </p>


            );

        }

    }

    createPriceListModalBody(){

        const {
            creating_price_list,
            new_start_quantity,
            new_end_quantity,
            newEndQuantityChanged,
            end_quantity_error,
            new_price,
            newPriceChanged,
            price_error,
            pack_size_error,
            new_pack_size,
            newPackSizeChanged
        } = this.props;

        if(creating_price_list){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );

        }else{

            return(

                <Fragment>

                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            Start Quantity
                        </Form.Label>


                        <Form.Control
                            value={new_start_quantity}
                            disabled
                        />


                    </Form.Group>


                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            End Quantity (optional)
                        </Form.Label>

                        <p style={{
                            color: 'blue'
                        }}>
                            Must be a positive integer greater than start quantity.
                        </p>


                        <Form.Control
                            value={new_end_quantity}
                            onChange={(e) => {
                                newEndQuantityChanged(e.target.value)
                            }}
                            isInvalid={!_.isEmpty(end_quantity_error)}
                        />

                        <Form.Control.Feedback type="invalid">
                            {end_quantity_error}
                        </Form.Control.Feedback>


                    </Form.Group>


                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            Pack size (*)
                        </Form.Label>

                        <p style={{
                            color: 'blue'
                        }}>
                            Must be a positive integer.
                        </p>


                        <Form.Control
                            value={new_pack_size}
                            onChange={(e) => {
                                newPackSizeChanged(e.target.value)
                            }}
                            isInvalid={!_.isEmpty(pack_size_error)}
                        />

                        <Form.Control.Feedback type="invalid">
                            {pack_size_error}
                        </Form.Control.Feedback>


                    </Form.Group>



                    <Form.Group
                        style={{
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            style={{
                                fontSize: '18px',
                                marginBottom: '15px'
                            }}
                        >
                            Price (*) (USD)
                        </Form.Label>

                        <p style={{
                            color: 'blue'
                        }}>
                            Must be at least 0.5 USD
                        </p>


                        <Form.Control
                            value={new_price}
                            onChange={(e) => {
                                newPriceChanged(e.target.value)
                            }}
                            isInvalid={!_.isEmpty(price_error)}
                        />

                        <Form.Control.Feedback type="invalid">
                            {price_error}
                        </Form.Control.Feedback>


                    </Form.Group>

                    {this.createPriceListModalError()}



                </Fragment>

            );

        }


    }

    createPriceListModalFooter(){

        const {
            creating_price_list,
            packSizeErrorChanged,
            endQuantityErrorChanged,
            priceErrorChanged,
            new_start_quantity,
            new_price,
            new_end_quantity,
            new_pack_size,
            createPriceList,
            access_token,
            client,
            uid,
        } = this.props;

        const { history, product_id } = this.state;



        if(!creating_price_list){

            return(

                <Fragment>

                    <Button
                        variant="success"
                        onClick={() => {




                            let is_valid = true;

                            endQuantityErrorChanged('');

                            packSizeErrorChanged('');

                            priceErrorChanged('');






                            if(_.isEmpty(new_price)){

                                is_valid = false;

                                priceErrorChanged('Price cannot be empty');

                            } else{

                                if(!/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(new_price)){

                                    is_valid = false;

                                    priceErrorChanged('Price must be a positive decimal number');


                                }else{


                                    if(parseFloat(new_price) < 0.5){

                                        is_valid = false;

                                        priceErrorChanged('Price must be at least 0.5');

                                    }

                                }


                            }

                            if(_.isEmpty(new_pack_size)){

                                is_valid = false;

                                packSizeErrorChanged('Pack size cannot be empty');

                            }else if(!/^[1-9]\d*$/.test(new_pack_size)){

                                is_valid = false;

                                packSizeErrorChanged('Pack size must be a positive decimal number');

                            }


                            if(!_.isEmpty(new_end_quantity)){


                                if(!/^[1-9]\d*$/.test(new_end_quantity)){

                                    is_valid = false;

                                    endQuantityErrorChanged('End quantity must be a positive integer');

                                }else{


                                    if(parseInt(new_end_quantity) <= parseInt(new_start_quantity)){

                                        is_valid = false;

                                        endQuantityErrorChanged('End quantity must be greater than start quantity');

                                    }


                                }



                            }

                            if(is_valid){

                                let price_range;

                                if(_.isEmpty(new_end_quantity)){

                                    price_range = {
                                        start_quantity: new_start_quantity,
                                        price: new_price
                                    };

                                }else{

                                    price_range = {
                                        start_quantity: new_start_quantity,
                                        end_quantity: new_end_quantity,
                                        price: new_price
                                    };

                                }


                                const price_list = {
                                    pack_size: new_pack_size,
                                    price_ranges: [price_range]
                                };

                                createPriceList(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    price_list,
                                    product_id
                                )

                            }



                        }}>
                        Submit
                    </Button>

                    <Button
                        variant="secondary"
                        onClick={() => {
                            this.exitCreatePriceListModal();
                        }}>
                        Close
                    </Button>

                </Fragment>


            );

        }

    }

    createPriceListModal(){

        const {
            create_price_list_modal_visible
        } = this.props;


        if(create_price_list_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={create_price_list_modal_visible}
                    onHide={() => {
                        this.exitCreatePriceListModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Create Price List
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.createPriceListModalBody()}

                    </Modal.Body>



                    <Modal.Footer>

                        {this.createPriceListModalFooter()}


                    </Modal.Footer>

                </Modal>

            );


        }


    }

    addPriceModal(){

        const {
            add_price_modal_visible
        } = this.props;

        if(add_price_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={add_price_modal_visible}
                    onHide={() => {
                        this.exitAddPriceModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Price
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.addPriceModalBody()}

                    </Modal.Body>



                    <Modal.Footer>


                        {this.addPriceModalFooter()}

                    </Modal.Footer>

                </Modal>

            );

        }


    }

    renderBody(){

        const {
            fetching_admin_profile,
            initializing_page
        } = this.props;

        const {
            history,
            params,
            location
        } = this.state;

        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />


                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '3rem',
                            flexDirection: 'column'
                        }}>

                            <p style={{
                                fontSize: isMobile ? '30px' : '40px',
                                marginBottom: '20px',
                                textAlign: 'center'
                            }}>
                                Edit Product
                            </p>

                            {this.renderForm()}

                        </div>


                    </div>


                    {this.addAttributeModal()}

                    {this.editProductModal()}

                    {this.editPackSizeModal()}

                    {this.editPriceModal()}

                    {this.editQuantityModal()}

                    {this.addPriceModal()}

                    {this.createPriceListModal()}

                </div>

            );

        }

    }

    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        initializing_page,
        name,
        description,
        upc,
        sku,
        pictures,
        topic_name,
        product_attributes,
        category_id,
        categories,
        brand_name,
        editing_product,
        edit_product_modal_visible,
        success_message,
        error_message,
        editing_images,
        price_list_id,
        pack_size,
        price_ranges,
        price_list_form_visible,
        edit_pack_size_modal_visible,
        new_pack_size,
        edit_pack_size_modal_error,
        editing_pack_size,
        edit_price_modal_visible,
        new_price,
        editing_price,
        edit_price_modal_error,
        selected_price_range_id,
        removing_price_range,
        edit_quantity_modal_visible,
        new_start_quantity,
        new_end_quantity,
        edit_quantity_modal_error,
        editing_quantity,
        end_quantity_error,
        price_error,
        add_price_modal_error,
        add_price_modal_visible,
        adding_price,
        deleting_price_list,
        pack_size_error,
        create_price_list_modal_error,
        create_price_list_modal_visible,
        creating_price_list,
        quantity
    } = state.edit_product;


    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        name,
        description,
        upc,
        sku,
        pictures,
        topic_name,
        product_attributes,
        category_id,
        categories,
        brand_name,
        editing_product,
        edit_product_modal_visible,
        success_message,
        error_message,
        editing_images,
        price_list_id,
        pack_size,
        price_ranges,
        price_list_form_visible,
        edit_pack_size_modal_visible,
        new_pack_size,
        edit_pack_size_modal_error,
        editing_pack_size,
        edit_price_modal_visible,
        new_price,
        editing_price,
        edit_price_modal_error,
        selected_price_range_id,
        removing_price_range,
        edit_quantity_modal_visible,
        new_start_quantity,
        new_end_quantity,
        edit_quantity_modal_error,
        editing_quantity,
        end_quantity_error,
        price_error,
        add_price_modal_error,
        add_price_modal_visible,
        adding_price,
        deleting_price_list,
        pack_size_error,
        create_price_list_modal_error,
        create_price_list_modal_visible,
        creating_price_list,
        quantity
    };

};

export default connect(mapStateToProps, {
    initializeEditProductPage,
    clearEditProductState,
    editProductNameChanged,
    editProductDescriptionChanged,
    editProductUpcChanged,
    editProductSkuChanged,
    editProductTopicChanged,
    editProductAttributesChanged,
    editProductCategoryChanged,
    removeProductImage,
    hideEditProductModal,
    addProductImages,
    updateProduct,
    replaceProductPicture,
    editProductBrandNameChanged,
    editPackSizeModalVisibleChanged,
    newPackSizeChanged,
    editPackSize,
    editPackSizeModalErrorChanged,
    editPriceModalVisibleChanged,
    newPriceChanged,
    editPrice,
    editPriceModalErrorChanged,
    editSelectedPriceRangeIdChanged,
    clearEditPriceModalState,
    clearEditPackSizeModalState,
    openEditPriceModal,
    removePriceRange,
    openEditQuantityModal,
    clearEditQuantityModalState,
    newEndQuantityChanged,
    editQuantityModalErrorChanged,
    editQuantity,
    endQuantityErrorChanged,
    priceErrorChanged,
    addPriceModalErrorChanged,
    openAddPriceModal,
    clearAddPriceModalState,
    addPrice,
    deletePriceList,
    packSizeErrorChanged,
    createPriceListModalErrorChanged,
    openCreatePriceListModal,
    clearCreatePriceListModalState,
    createPriceList,
    editProductQuantityChanged
})(EditProduct);
