import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    createProduct,
    clearCreateProductState,
    hideCreateProductModal,
    createProductNameChanged,
    createProductDescriptionChanged,
    createProductUpcChanged,
    createProductSkuChanged,
    createProductPicturesChanged,
    createProductTopicChanged,
    createProductAttributesChanged,
    createProductBrandNameChanged,
    createProductPriceListVisibilityChanged,
    createProductPackSizeChanged,
    createProductPriceRangesChanged,
    createProductQuantityChanged
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";
import ImageUploading from 'react-images-uploading';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class CreateProduct extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const category_id = params.category_id;

        const add_attribute_modal_visible = false;

        const attribute_name = "";

        const attribute_value = "";

        const start_quantity = "";

        const end_quantity = "";

        const price = "";

        const add_price_modal_visible = false;

        const price_error = '';

        const end_quantity_error = '';

        const change_price_modal_visible = false;

        const price_range_index = null;

        const edit_quantity_modal_visible = false;

        const price_list_error = '';

        this.state = {
            history,
            params,
            location,
            width,
            height,
            category_id,
            add_attribute_modal_visible,
            attribute_name,
            attribute_value,
            start_quantity,
            end_quantity,
            price,
            add_price_modal_visible,
            price_error,
            end_quantity_error,
            change_price_modal_visible,
            price_range_index,
            edit_quantity_modal_visible,
            price_list_error
        };

    }




    componentWillUnmount(){
        this.props.clearCreateProductState();
    }

    componentDidMount(){

        const {
            logged_in,
            roles
        } = this.props;

        const { history } = this.state;

        if(!logged_in){

            history.push("/");

        }else if(!roles.includes("root_admin") && !roles.includes("lister")) {

            history.push("/home");

        }

    }


    renderProductAttributes(){

        const { product_attributes, createProductAttributesChanged } = this.props;

        if(!_.isEmpty(product_attributes)){

            return _.map(product_attributes, (attribute_value, attribute_name) => {



                return(

                    <Form.Group
                        style={{
                            marginBottom: '1.5rem',
                            display: 'flex',
                            flexDirection: 'row',
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5
                        }}
                        key={attribute_name}
                    >

                        <Form.Control
                            value={attribute_name}
                            readOnly
                            style={{
                                marginRight: '20px',
                            }}
                        />

                        <Form.Control
                            value={attribute_value}
                            readOnly
                            style={{
                                marginRight: '20px',
                            }}
                        />

                        <Button
                            variant="danger"
                            onClick={() => {

                                let new_product_attributes = product_attributes;

                                delete new_product_attributes[attribute_name];

                                createProductAttributesChanged(new_product_attributes);


                            }}
                        >
                            Remove
                        </Button>

                    </Form.Group>


                );

            });

        }

    }

    addPrice(){

        const { price_ranges } = this.props;

        if(_.isEmpty(price_ranges)){

            this.setState({add_price_modal_visible: true, start_quantity: 1});

        }else{

            const price_range_max_start_quantity = _.maxBy(price_ranges, function(price_range) { return price_range.start_quantity });

            let next_start_quantity;

            if(price_range_max_start_quantity.end_quantity === undefined){

                next_start_quantity = price_range_max_start_quantity.start_quantity + 1;

            }else{

                next_start_quantity = price_range_max_start_quantity.end_quantity + 1;

            }



            this.setState({add_price_modal_visible: true, start_quantity: next_start_quantity});


        }

    }


    exitAddPriceModal(){

        this.setState({
            add_price_modal_visible: false,
            start_quantity: '',
            end_quantity: '',
            price: '',
            price_error: '',
            end_quantity_error: ''
        });

    }



    addPriceModal(){

        const {
            add_price_modal_visible,
            start_quantity,
            end_quantity,
            price,
            price_error,
            end_quantity_error
        } = this.state;

        const { price_ranges, createProductPriceRangesChanged } = this.props;

        if(add_price_modal_visible){

            return(


                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={add_price_modal_visible}
                    onHide={() => {
                        this.exitAddPriceModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Price
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '15px'
                                }}
                            >
                                Start Quantity
                            </Form.Label>


                            <Form.Control
                                value={start_quantity}
                                disabled
                            />


                        </Form.Group>


                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '15px'
                                }}
                            >
                                End Quantity (optional)
                            </Form.Label>

                            <p style={{
                                color: 'blue'
                            }}>
                                Must be a positive integer greater than start quantity.
                            </p>


                            <Form.Control
                                value={end_quantity}
                                onChange={(e) => {
                                    this.setState({end_quantity: e.target.value})
                                }}
                                isInvalid={!_.isEmpty(end_quantity_error)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {end_quantity_error}
                            </Form.Control.Feedback>


                        </Form.Group>


                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label
                                style={{
                                    fontSize: '18px'
                                }}
                            >
                                Price (USD) (*)
                            </Form.Label>

                            <p style={{
                                color: 'blue'
                            }}>
                                Must be at least 0.5 USD.
                            </p>


                            <Form.Control
                                value={price}
                                onChange={(e) => {
                                    this.setState({price: e.target.value})
                                }}
                                isInvalid={!_.isEmpty(price_error)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {price_error}
                            </Form.Control.Feedback>


                        </Form.Group>

                    </Modal.Body>



                    <Modal.Footer>


                        <Button
                            variant="success"
                            onClick={() => {


                                let is_valid = true;

                                let new_price = '';

                                let new_end_quantity = null;

                                this.setState({price_error: '', end_quantity_error: ''});




                                if(_.isEmpty(price)){

                                    is_valid = false;

                                    this.setState({price_error: 'Price cannot be empty'});

                                } else{

                                    if(!/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(price)){

                                        is_valid = false;

                                        this.setState({price_error: 'Price must be a positive decimal number'});


                                    }else{

                                        new_price = parseFloat(price);

                                        if(new_price < 0.5){

                                            is_valid = false;

                                            this.setState({price_error: 'Price must be at least 0.5'});

                                        }

                                    }


                                }



                                if(!_.isEmpty(end_quantity)){


                                    if(!/^[1-9]\d*$/.test(end_quantity)){

                                        is_valid = false;

                                        this.setState({end_quantity_error: 'End quantity must be a positive integer'});

                                    }else{

                                        new_end_quantity = parseInt(end_quantity);

                                        if(new_end_quantity <= start_quantity){

                                            is_valid = false;

                                            this.setState({end_quantity_error: 'End quantity must be greater than start quantity'});

                                        }


                                    }



                                }

                                if(is_valid){

                                    let prev_price_ranges = price_ranges;

                                    if(new_end_quantity === null){

                                        prev_price_ranges.push({
                                            start_quantity: start_quantity,
                                            price: new_price
                                        });

                                    }else{

                                        prev_price_ranges.push({
                                            start_quantity: start_quantity,
                                            end_quantity: new_end_quantity,
                                            price: new_price
                                        });

                                    }

                                    const new_price_ranges = _.cloneDeep(prev_price_ranges);

                                    createProductPriceRangesChanged(new_price_ranges);


                                    this.exitAddPriceModal();


                                }


                            }}>
                            Submit
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitAddPriceModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }

    }

    editQuantityButton(price_range, index){

        const { price_ranges } = this.props;

        const price_range_max_start_quantity = _.maxBy(price_ranges, function(pr) { return pr.start_quantity });

        if(price_range.start_quantity === price_range_max_start_quantity.start_quantity){

            return(

                <Button
                    variant="link"
                    id="edit-price-quantity-button"
                    onClick={() => {
                        this.setState({
                            price_range_index: index,
                            edit_quantity_modal_visible: true,
                            start_quantity: price_range.start_quantity,
                            end_quantity: price_range.end_quantity === undefined ? '' : price_range.end_quantity
                        });
                    }}
                >
                    Edit Quantity
                </Button>

            );

        }



    }

    exitEditQuantityModal(){


        this.setState({
            edit_quantity_modal_visible: false,
            price_range_index: null,
            start_quantity: '',
            end_quantity: '',
            end_quantity_error: ''
        });


    }


    editQuantityModal() {

        const {
            edit_quantity_modal_visible,
            price_range_index,
            start_quantity,
            end_quantity,
            end_quantity_error,
        } = this.state;

        const { price_ranges, createProductPriceRangesChanged} = this.props;

        if(edit_quantity_modal_visible && price_range_index !== null){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={edit_quantity_modal_visible}
                    onHide={() => {
                        this.exitEditQuantityModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Edit Quantity
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>


                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '15px'
                                }}
                            >
                                Start Quantity
                            </Form.Label>


                            <Form.Control
                                value={start_quantity}
                                disabled
                            />


                        </Form.Group>


                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label
                                style={{
                                    fontSize: '18px',
                                    marginBottom: '15px'
                                }}
                            >
                                End Quantity (optional)
                            </Form.Label>

                            <p style={{
                                color: 'blue'
                            }}>
                                Must be a positive integer greater than start quantity.
                            </p>


                            <Form.Control
                                value={end_quantity}
                                onChange={(e) => {
                                    this.setState({end_quantity: e.target.value})
                                }}
                                isInvalid={!_.isEmpty(end_quantity_error)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {end_quantity_error}
                            </Form.Control.Feedback>


                        </Form.Group>





                    </Modal.Body>



                    <Modal.Footer>


                        <Button
                            variant="success"
                            onClick={() => {

                                let new_end_quantity = null;

                                this.setState({ end_quantity_error: ''});



                                if(_.isEmpty(end_quantity)){

                                    let prev_price_ranges = price_ranges;

                                    let price_range = prev_price_ranges[price_range_index];

                                    prev_price_ranges[price_range_index] = {
                                        start_quantity: price_range.start_quantity,
                                        price: price_range.price
                                    };

                                    const new_price_ranges = _.cloneDeep(prev_price_ranges);

                                    createProductPriceRangesChanged(new_price_ranges);

                                    this.exitEditQuantityModal();

                                }else{

                                    let is_valid = true;

                                    if(!/^[1-9]\d*$/.test(end_quantity)){

                                        is_valid = false;

                                        this.setState({end_quantity_error: 'End quantity must be a positive integer'});

                                    }else{

                                        new_end_quantity = parseInt(end_quantity);

                                        if(new_end_quantity <= start_quantity){

                                            is_valid = false;

                                            this.setState({end_quantity_error: 'End quantity must be greater than start quantity'});

                                        }


                                    }

                                    if(is_valid){

                                        let prev_price_ranges = price_ranges;

                                        let price_range = prev_price_ranges[price_range_index];

                                        price_range.end_quantity = new_end_quantity;

                                        prev_price_ranges[price_range_index] = price_range;

                                        const new_price_ranges = _.cloneDeep(prev_price_ranges);

                                        createProductPriceRangesChanged(new_price_ranges);


                                        this.exitEditQuantityModal();

                                    }




                                }






                            }}>
                            Submit
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitEditQuantityModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );


        }

    }




    exitChangePriceModal(){

        this.setState({
            change_price_modal_visible: false,
            price_range_index: null,
            price: '',
            price_error: ''
        });

    }

    changePriceModal(){

        const {
            change_price_modal_visible,
            price_range_index,
            price,
            price_error,
        } = this.state;

        const { price_ranges, createProductPriceRangesChanged } = this.props;


        if(change_price_modal_visible && price_range_index !== null){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={change_price_modal_visible}
                    onHide={() => {
                        this.exitChangePriceModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Change Price
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>


                        <Form.Group
                            style={{
                                marginBottom: '1rem'
                            }}
                        >

                            <Form.Label
                                style={{
                                    fontSize: '18px'
                                }}
                            >
                                Price (USD) (*)
                            </Form.Label>

                            <p style={{
                                color: 'blue'
                            }}>
                                Must be at least 0.5 USD.
                            </p>


                            <Form.Control
                                value={price}
                                onChange={(e) => {
                                    this.setState({price: e.target.value})
                                }}
                                isInvalid={!_.isEmpty(price_error)}
                            />

                            <Form.Control.Feedback type="invalid">
                                {price_error}
                            </Form.Control.Feedback>


                        </Form.Group>

                    </Modal.Body>



                    <Modal.Footer>


                        <Button
                            variant="success"
                            onClick={() => {


                                let is_valid = true;

                                let new_price = '';


                                this.setState({price_error: ''});



                                if(_.isEmpty(price)){

                                    is_valid = false;

                                    this.setState({price_error: 'Price cannot be empty'});

                                } else{

                                    if(!/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(price)){

                                        is_valid = false;

                                        this.setState({price_error: 'Price must be a positive decimal number'});


                                    }else{

                                        new_price = parseFloat(price);

                                        if(new_price < 0.5){

                                            is_valid = false;

                                            this.setState({price_error: 'Price must be at least 0.5'});

                                        }

                                    }


                                }



                                if(is_valid){


                                    let prev_price_ranges = price_ranges;

                                    let new_price_range = prev_price_ranges[price_range_index];

                                    new_price_range.price = new_price;

                                    prev_price_ranges[price_range_index] = new_price_range;

                                    const new_price_ranges = _.cloneDeep(prev_price_ranges);

                                    createProductPriceRangesChanged(new_price_ranges);


                                    this.exitChangePriceModal();


                                }


                            }}>
                            Submit
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitChangePriceModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }

    }


    removePriceButton(price_range, index){

        const { price_ranges, createProductPriceRangesChanged } = this.props;

        const price_range_max_start_quantity = _.maxBy(price_ranges, function(pr) { return pr.start_quantity });

        const max_start_quantity = price_range_max_start_quantity.start_quantity;

        if( max_start_quantity !== 1 && price_range.start_quantity === max_start_quantity){

            return(

                <Button
                    variant="link"
                    id="edit-price-quantity-button"
                    onClick={() => {

                        let prev_price_ranges = price_ranges;

                        prev_price_ranges.splice(index, 1);

                        const new_price_ranges = _.cloneDeep(prev_price_ranges);

                        createProductPriceRangesChanged(new_price_ranges);

                    }}
                >
                    Remove Price
                </Button>

            );

        }

    }


    renderPriceRange(price_range, index){

        return(

            <Fragment>




                    <Form.Group
                        style={isMobile ? {
                            marginBottom: '25px'
                        } : {
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '20px',
                        }}
                    >

                        <Form.Label>Start Quantity</Form.Label>

                        <Form.Control
                            value={price_range.start_quantity}
                            disabled
                        />

                    </Form.Group>


                    <Form.Group
                        style={isMobile ? {
                            marginBottom: '25px'
                        } :{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '20px',
                        }}
                    >

                        <Form.Label>End Quantity</Form.Label>

                        <Form.Control
                            value={price_range.end_quantity === undefined ? '' : price_range.end_quantity}
                            disabled
                        />

                    </Form.Group>


                    <Form.Group
                        style={isMobile ? {
                            marginBottom: '25px'
                        } :{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '20px',
                        }}
                    >

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}>

                            <Form.Label>Price (USD)</Form.Label>

                            <span
                                variant="link"
                                id="change-price-button"
                                onClick={() => {
                                    this.setState({
                                        change_price_modal_visible: true,
                                        price_range_index: index,
                                        price: price_range.price
                                    })
                                }}
                            >
                                    Edit
                                </span>


                        </div>



                        <Form.Control
                            value={price_range.price}
                            disabled
                        />

                    </Form.Group>


                    {this.editQuantityButton(price_range, index)}


                    {this.removePriceButton(price_range, index)}





            </Fragment>

        );


    }

    renderPriceList(){

        const { price_ranges } = this.props;

        if(!_.isEmpty(price_ranges)){

            return _.map(price_ranges, (price_range, index) => {


                if(isMobile){

                    return(

                        <Card
                            key={index}
                            style={{
                                marginBottom: '1.5rem',
                                width: this.state.width - 60
                            }}
                        >
                            <Card.Body style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>

                                {this.renderPriceRange(price_range, index)}

                            </Card.Body>

                        </Card>

                    );

                }else{

                    return(

                        <div
                            style={{
                                marginBottom: '1.5rem',
                                display: 'flex',
                                flexDirection: isMobile ? 'column' :  'row',
                                width: isMobile ? this.state.width - 60 : this.state.width / 1.5
                            }}
                            key={index}
                        >

                            {this.renderPriceRange(price_range, index)}

                        </div>

                    );

                }


            });

        }

    }

    priceListForm(){


        const {
            price_list_form_visible,
            createProductPriceListVisibilityChanged,
            pack_size,
            createProductPackSizeChanged,
            createProductPriceRangesChanged
        } = this.props;

        if(price_list_form_visible){

            return(

                <Fragment>


                    <Button
                        variant="danger"
                        onClick={() => {

                            createProductPriceListVisibilityChanged(false);

                            createProductPackSizeChanged('');

                            createProductPriceRangesChanged([]);



                        }}
                        style={ isMobile ? {
                            marginBottom: '30px',
                            width:  '100%',
                            alignSelf: 'center'
                        } : {
                            marginBottom: '30px',
                            width: '10%'
                        }}
                    >
                        Delete List
                    </Button>



                    <Form.Label
                        style={{
                            fontSize: '18px',
                            marginBottom: '15px',
                            marginTop: '10px'
                        }}
                    >
                        Pack Size (*)
                    </Form.Label>


                    <p style={{
                        color: 'blue'
                    }}>
                        Must be a positive integer.
                    </p>


                    <Form.Control
                        value={pack_size}
                        onChange={(e) => {
                            createProductPackSizeChanged(e.target.value);
                        }}
                        style={{
                            marginBottom: '15px'
                        }}
                    />

                    <Form.Label
                        style={{
                            fontSize: '18px',
                            marginBottom: '15px',
                            marginTop: '10px'
                        }}
                    >
                        Prices (*)
                    </Form.Label>


                    <Button
                        variant="success"
                        onClick={() => {
                            this.addPrice();
                        }}
                        style={ isMobile ? {
                            marginBottom: '30px',
                            width:  '100%',
                            alignSelf: 'center'
                        } : {
                            marginBottom: '30px',
                            width: '10%'
                        }}
                    >
                        Add Price
                    </Button>


                    {this.renderPriceList()}





                </Fragment>

            );

        }

    }

    createPriceListButton(){

        const { price_list_form_visible, createProductPriceListVisibilityChanged } = this.props;

        if(!price_list_form_visible){

            return(


                <Button
                    variant="success"
                    onClick={() => {
                        createProductPriceListVisibilityChanged(true);
                    }}
                    style={ isMobile ? {
                        marginBottom: '30px',
                        width:  '100%',
                        alignSelf: 'center'
                    } : {
                        marginBottom: '30px',
                        width: '10%'
                    }}
                >
                    Create List
                </Button>


            );

        }

    }

    priceListError(){

        const { price_list_error } = this.state;

        if(price_list_error.length > 0){

            return(

                <p style={{
                    color: '#ff0000',
                    fontSize: '18px',
                    marginBottom: '15px',
                    marginTop: '15px'
                }}>
                    {price_list_error}
                </p>

            );

        }

    }

    renderForm(){

        const {
            creating_product,
            access_token,
            client,
            uid,
            name,
            description,
            upc,
            sku,
            createProductNameChanged,
            createProductDescriptionChanged,
            createProductUpcChanged,
            createProductSkuChanged,
            topic_name,
            createProductTopicChanged,
            pictures,
            createProductPicturesChanged,
            createProduct,
            product_attributes,
            brand_name,
            createProductBrandNameChanged,
            price_list_form_visible,
            pack_size,
            price_ranges,
            quantity,
            createProductQuantityChanged
        } = this.props;

        const {
            history,
            category_id
        } = this.state;

        const responsive =  {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
                slidesToSlide: 3
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
                slidesToSlide: 2
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1
            }
        };

        if(creating_product){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>


            );


        }else{

            return(

                <Fragment>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Name (*)
                        </Form.Label>


                        <Form.Control
                            value={name}
                            onChange={(e) => {
                                createProductNameChanged(e.target.value);
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Description (*)
                        </Form.Label>


                        <Form.Control
                            value={description}
                            as="textarea"
                            onChange={(e) => {
                                createProductDescriptionChanged(e.target.value);
                            }}
                            rows={10}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            UPC (*)
                        </Form.Label>


                        <Form.Control
                            value={upc}
                            onChange={(e) => {
                                createProductUpcChanged(e.target.value);
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            SKU (*)
                        </Form.Label>


                        <Form.Control
                            value={sku}
                            onChange={(e) => {
                                createProductSkuChanged(e.target.value);
                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Topic (optional)
                        </Form.Label>

                        <p>
                            This product will be shown in the landing page under the topic below.
                        </p>

                        <Form.Control
                            value={topic_name}
                            onChange={(e) => {
                                createProductTopicChanged(e.target.value);
                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Brand Name (optional)
                        </Form.Label>


                        <Form.Control
                            value={brand_name}
                            onChange={(e) => {
                                createProductBrandNameChanged(e.target.value);
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Attributes (optional)
                        </Form.Label>



                        <Button
                            variant="success"
                            onClick={() => {
                                this.setState({add_attribute_modal_visible: true});
                            }}
                            style={ isMobile ? {
                                marginBottom: '30px',
                                width:  '100%',
                                alignSelf: 'center'
                            } : {
                                marginBottom: '30px',
                                width: '10%'
                            }}
                        >
                            Add Attribute
                        </Button>


                        {this.renderProductAttributes()}




                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Price List (optional)
                        </Form.Label>


                        {this.createPriceListButton()}

                        {this.priceListForm()}


                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '2.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Quantity (optional)
                        </Form.Label>


                        <Form.Control
                            value={quantity}
                            onChange={(e) => {
                                createProductQuantityChanged(e.target.value);
                            }}
                        />

                    </Form.Group>





                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1.5rem'
                        }}
                    >

                        <Form.Label
                            className="create-product-form-label"
                        >
                            Upload Images (at least 1)
                        </Form.Label>

                        <ImageUploading
                            multiple={true}
                            value={pictures}
                            onChange={(imageList, addUpdateIndex) => {

                                console.log(imageList);

                                createProductPicturesChanged(imageList);

                            }}
                            acceptType={['jpg' , 'jpeg',  'gif', 'png', 'webp']}
                        >

                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps
                              }) => (
                                // write your building UI
                                <div >


                                    <div style={ isMobile ? {
                                        display: 'flex' ,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: '2rem'
                                    } : {
                                        marginBottom: '4rem'
                                    }}>

                                        <Button
                                            style={ isMobile ? {
                                                color: isDragging ? 'red' : null,
                                                width: '45%'
                                            }:  {
                                                color: isDragging ? 'red' : null,
                                                marginRight: '20px'
                                            }}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            variant="success"
                                        >
                                            { isMobile ? "Upload" : "Click or Drop here"  }
                                        </Button>



                                        <Button
                                            onClick={onImageRemoveAll}
                                            variant="danger"
                                            style={isMobile ? {
                                                width: '45%'
                                            }: {}}
                                        >
                                            {isMobile ? "Delete All" : "Remove All Images"}
                                        </Button>

                                    </div>


                                    <Carousel
                                        responsive={responsive}
                                        removeArrowOnDeviceType={["tablet", "mobile"]}
                                    >

                                        {imageList.map((image, index) => (


                                            <Card
                                                key={index}
                                                style={  {
                                                    marginRight: isMobile ? 0 : '20px',
                                                }}
                                            >

                                                <Image
                                                    src={image.dataURL}
                                                    style={ isMobile ? {
                                                        width: '300px',
                                                        height: '200px',
                                                        padding: '20px'
                                                    } : {
                                                        width: '400px',
                                                        height: '300px',
                                                        padding: '20px'
                                                    }}
                                                    className="create-product-image"
                                                />



                                                <Card.Footer>


                                                    <div style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        flexDirection: 'column',
                                                        padding: '10px'
                                                    }}>


                                                        <Button
                                                            onClick={() => onImageUpdate(index)}
                                                            variant="success"
                                                            style={{
                                                                width: '90%',
                                                                marginBottom: '15px'
                                                            }}
                                                        >
                                                            Replace
                                                        </Button>

                                                        <Button
                                                            onClick={() => onImageRemove(index)}
                                                            variant="danger"
                                                            style={{
                                                                width: '90%',
                                                                marginBottom: '15px'
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>


                                                    </div>

                                                </Card.Footer>


                                            </Card>




                                        ))}

                                    </Carousel>








                                </div>
                            )}


                        </ImageUploading>






                    </Form.Group>



                    {this.priceListError()}


                    <Button
                        style={{
                            width:  isMobile ? this.state.width - 60 : '25%',
                            marginTop: '1.5rem',
                            borderRadius: 20,
                            fontSize: '20px'
                        }}
                        variant="success"
                        onClick={() => {

                            this.setState({price_list_error: ''});

                            let is_valid = true;

                            if(price_list_form_visible){


                                if( !/^[1-9]\d*$/.test(pack_size) || _.isEmpty(price_ranges)){

                                    is_valid = false;

                                    this.setState({price_list_error: 'You need to enter the pack size and at least 1 price to continue.'})

                                }


                            }


                            if(is_valid){

                                let price_list = {};

                                if(!_.isEmpty(pack_size) && !_.isEmpty(price_ranges)){

                                    price_list = {pack_size: pack_size, price_ranges: price_ranges}

                                }


                                createProduct(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    category_id,
                                    name,
                                    description,
                                    upc,
                                    sku,
                                    pictures,
                                    topic_name,
                                    product_attributes,
                                    brand_name,
                                    price_list,
                                    quantity
                                );


                            }



                        }}
                    >
                        Submit
                    </Button>





                </Fragment>

            );


        }

    }

    createProductModalBody(){

        const {
            success_message,
            error_message
        } = this.props;

        if(success_message.length > 0){

            return(

                <p style={{
                    fontSize: '18px',
                    color: '#008000'
                }}>
                    {success_message}
                </p>

            );

        }else{

            return(
                <p style={{
                    fontSize: '18px',
                    color: '#FF0000'
                }}>
                    {error_message}
                </p>
            )

        }


    }



    createProductModal(){

        const {
            create_product_modal_visible,
            hideCreateProductModal
        } = this.props;

        if(create_product_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={create_product_modal_visible}
                    onHide={() => {
                        hideCreateProductModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Result
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.createProductModalBody()}

                    </Modal.Body>



                    <Modal.Footer>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                hideCreateProductModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }


    }


    exitAddAttributeModal(){

        this.setState({
            add_attribute_modal_visible: false,
            attribute_name: '',
            attribute_value: ''
        })

    }

    addAttributeModal(){

        const {
            add_attribute_modal_visible,
            attribute_name,
            attribute_value
        } = this.state;

        const {
            product_attributes,
            createProductAttributesChanged
        } = this.props;

        if(add_attribute_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={add_attribute_modal_visible}
                    onHide={() => {
                        this.exitAddAttributeModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Add Attribute
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label>
                                Attribute Name (*)
                            </Form.Label>


                            <Form.Control
                                value={attribute_name}
                                onChange={(e) => {
                                    this.setState({attribute_name: e.target.value})
                                }}
                            />

                        </Form.Group>

                        <Form.Group
                            style={{
                                marginBottom: '1.5rem'
                            }}
                        >

                            <Form.Label>
                                Attribute Value (*)
                            </Form.Label>


                            <Form.Control
                                value={attribute_value}
                                onChange={(e) => {
                                    this.setState({attribute_value: e.target.value})
                                }}
                            />

                        </Form.Group>


                    </Modal.Body>



                    <Modal.Footer>

                        <Button
                            variant="success"
                            style={{
                                marginRight: '10px'
                            }}
                            onClick={() => {

                                console.log(product_attributes);

                                if(!_.isEmpty(attribute_name) && !_.isEmpty(attribute_value)){

                                    const new_attribute_name = attribute_name.trim();

                                    const new_attribute_value = attribute_value.trim();

                                    if(product_attributes[new_attribute_name] === undefined){

                                        let new_product_attributes = product_attributes;

                                        new_product_attributes[new_attribute_name] = new_attribute_value;

                                        createProductAttributesChanged(new_product_attributes);

                                    }

                                    this.setState({attribute_name: '', attribute_value: ''});



                                }

                            }}>
                            Add
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitAddAttributeModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }

    }

    renderBody(){

        const {
            fetching_admin_profile
        } = this.props;

        const {
            history,
            params,
            location
        } = this.state;


        if(fetching_admin_profile){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />

                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>


                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>

                            <p style={{
                                fontSize: isMobile ? '30px' : '40px',
                                marginBottom: '20px',
                                textAlign: 'center'
                            }}>
                                Create Product
                            </p>

                            <div style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '3rem',
                                flexDirection: 'column'
                            }}>

                                {this.renderForm()}

                            </div>


                        </div>




                    </div>

                    {this.addAttributeModal()}

                    {this.createProductModal()}

                    {this.addPriceModal()}

                    {this.changePriceModal()}

                    {this.editQuantityModal()}

                </div>

            );

        }

    }

    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        creating_product,
        name,
        description,
        upc,
        sku,
        pictures,
        topic_name,
        product_attributes,
        create_product_modal_visible,
        success_message,
        error_message,
        brand_name,
        price_list_form_visible,
        pack_size,
        price_ranges,
        quantity
    } = state.create_product;


    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        creating_product,
        name,
        description,
        upc,
        sku,
        pictures,
        topic_name,
        product_attributes,
        create_product_modal_visible,
        success_message,
        error_message,
        brand_name,
        price_list_form_visible,
        pack_size,
        price_ranges,
        quantity
    };

};

export default connect(mapStateToProps, {
    createProduct,
    clearCreateProductState,
    hideCreateProductModal,
    createProductNameChanged,
    createProductDescriptionChanged,
    createProductUpcChanged,
    createProductSkuChanged,
    createProductPicturesChanged,
    createProductTopicChanged,
    createProductAttributesChanged,
    createProductBrandNameChanged,
    createProductPriceListVisibilityChanged,
    createProductPackSizeChanged,
    createProductPriceRangesChanged,
    createProductQuantityChanged
})(CreateProduct);

