import {
    LOGOUT_SUCCESS,
    INITIALIZE_LANDING_PAGE_IMAGES_PAGE,
    INITIALIZE_LANDING_PAGE_IMAGES_PAGE_COMPLETE,
    CLEAR_LANDING_PAGE_IMAGES_STATE,
    ADD_LANDING_IMAGE,
    ADD_LANDING_IMAGE_COMPLETE,
    ADD_LANDING_IMAGE_SUCCESS,
    ADD_LANDING_IMAGE_FAILURE,
    DELETE_LANDING_IMAGE,
    DELETE_LANDING_IMAGE_COMPLETE,
    DELETE_LANDING_IMAGE_SUCCESS,
    DELETE_LANDING_IMAGE_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    landing_page_images: [],
    modifying_images: false,
    landing_image_error_message: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELETE_LANDING_IMAGE_FAILURE:
            return{
                ...state,
                landing_page_images: action.payload.landing_page_images,
                landing_image_error_message: action.payload.landing_image_error_message
            };
        case DELETE_LANDING_IMAGE_SUCCESS:
            return{
                ...state,
                landing_page_images: action.payload
            };
        case DELETE_LANDING_IMAGE_COMPLETE:
            return{
                ...state,
                modifying_images: false
            };
        case DELETE_LANDING_IMAGE:
            return{
                ...state,
                modifying_images: true,
                landing_image_error_message: ''
            };
        case ADD_LANDING_IMAGE_FAILURE:
            return{
                ...state,
                landing_image_error_message: action.payload
            };
        case ADD_LANDING_IMAGE_SUCCESS:
            return{
                ...state,
                landing_page_images: action.payload
            };
        case ADD_LANDING_IMAGE_COMPLETE:
            return{
                ...state,
                modifying_images: false
            };
        case ADD_LANDING_IMAGE:
            return{
                ...state,
                modifying_images: true,
                landing_image_error_message: ''
            };
        case INITIALIZE_LANDING_PAGE_IMAGES_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false,
                landing_page_images: action.payload
            };
        case INITIALIZE_LANDING_PAGE_IMAGES_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_LANDING_PAGE_IMAGES_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};