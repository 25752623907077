import {
    LOGOUT_SUCCESS,
    INITIALIZE_EDIT_CUSTOMER_PAGE,
    INITIALIZE_EDIT_CUSTOMER_PAGE_COMPLETE,
    CLEAR_EDIT_CUSTOMER_PAGE_STATE,
    EDIT_CUSTOMER_EMAIL_CHANGED,
    EDIT_CUSTOMER_FULL_NAME_CHANGED,
    EDIT_CUSTOMER_COMPANY_NAME_CHANGED,
    EDIT_CUSTOMER_COMPANY_ADDRESS_CHANGED,
    EDIT_CUSTOMER_COMPANY_STATE_CHANGED,
    EDIT_CUSTOMER_COMPANY_CITY_CHANGED,
    EDIT_CUSTOMER_COMPANY_ZIP_CODE_CHANGED,
    EDIT_CUSTOMER_ACCOUNT_APPROVED_CHANGED,
    EDIT_CUSTOMER_ACCOUNT_PASSWORD_CHANGED,
    EDIT_CUSTOMER_COMPANY_STATE_CODE_CHANGED,
    EDIT_CUSTOMER_ACCOUNT_CHANGED,
    UPDATE_CUSTOMER_ACCOUNT,
    UPDATE_CUSTOMER_ACCOUNT_COMPLETE,
    OPEN_EDIT_CUSTOMER_ACCOUNT_MODAL,
    CLOSE_EDIT_CUSTOMER_ACCOUNT_MODAL,
    OPEN_RESELLER_PERMIT_MODAL,
    CLOSE_RESELLER_PERMIT_MODAL,
    REPLACE_SELLER_PERMIT,
    REPLACE_SELLER_PERMIT_COMPLETE,
    REPLACE_SELLER_PERMIT_ERROR
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    email: '',
    full_name: '',
    company_name: '',
    company_address: '',
    company_state: '',
    company_city: '',
    company_zip_code: '',
    account_approved: '',
    password: '',
    state_code: '',
    updating_account: false,
    edit_customer_account_modal_visible: false,
    edit_customer_account_modal_error: '',
    seller_permit_url: '',
    replace_seller_permit_modal_visible: false,
    replace_seller_permit_modal_error: '',
    replacing_seller_permit: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REPLACE_SELLER_PERMIT_ERROR:
            return{
                ...state,
                replace_seller_permit_modal_error: action.payload
            };
        case REPLACE_SELLER_PERMIT_COMPLETE:
            return{
                ...state,
                replacing_seller_permit: false
            };
        case REPLACE_SELLER_PERMIT:
            return{
                ...state,
                replacing_seller_permit: true
            };
        case CLOSE_RESELLER_PERMIT_MODAL:
            return{
                ...state,
                replace_seller_permit_modal_visible: false,
                replace_seller_permit_modal_error: ''
            };
        case OPEN_RESELLER_PERMIT_MODAL:
            return{
                ...state,
                replace_seller_permit_modal_visible: true
            };
        case CLOSE_EDIT_CUSTOMER_ACCOUNT_MODAL:
            return{
                ...state,
                edit_customer_account_modal_visible: false,
                edit_customer_account_modal_error: ''
            };
        case OPEN_EDIT_CUSTOMER_ACCOUNT_MODAL:
            return{
                ...state,
                edit_customer_account_modal_visible: true,
                edit_customer_account_modal_error: action.payload
            };
        case UPDATE_CUSTOMER_ACCOUNT_COMPLETE:
            return{
                ...state,
                updating_account: false
            };
        case UPDATE_CUSTOMER_ACCOUNT:
            return{
                ...state,
                updating_account: true
            };
        case EDIT_CUSTOMER_COMPANY_STATE_CODE_CHANGED:
            return{
                ...state,
                state_code: action.payload
            };
        case EDIT_CUSTOMER_ACCOUNT_PASSWORD_CHANGED:
            return{
                ...state,
                password: action.payload
            };
        case EDIT_CUSTOMER_ACCOUNT_APPROVED_CHANGED:
            return{
                ...state,
                account_approved: action.payload
            };
        case EDIT_CUSTOMER_COMPANY_ZIP_CODE_CHANGED:
            return{
                ...state,
                company_zip_code: action.payload
            };
        case EDIT_CUSTOMER_COMPANY_CITY_CHANGED:
            return{
                ...state,
                company_city: action.payload
            };
        case EDIT_CUSTOMER_COMPANY_STATE_CHANGED:
            return{
                ...state,
                company_state: action.payload
            };
        case EDIT_CUSTOMER_COMPANY_ADDRESS_CHANGED:
            return{
                ...state,
                company_address: action.payload
            };
        case EDIT_CUSTOMER_COMPANY_NAME_CHANGED:
            return{
                ...state,
                company_name: action.payload
            };
        case EDIT_CUSTOMER_FULL_NAME_CHANGED:
            return{
                ...state,
                full_name: action.payload
            };
        case EDIT_CUSTOMER_EMAIL_CHANGED:
            return{
                ...state,
                email: action.payload
            };
        case EDIT_CUSTOMER_ACCOUNT_CHANGED:
            return{
                ...state,
                email: action.payload.email,
                full_name: action.payload.full_name,
                company_name: action.payload.company_name,
                company_address: action.payload.company_address,
                company_state: action.payload.company_state,
                company_city: action.payload.company_city,
                company_zip_code: action.payload.company_zip_code,
                account_approved: action.payload.account_approved,
                state_code: action.payload.state_code,
                seller_permit_url: action.payload.seller_permit_url
            };
        case INITIALIZE_EDIT_CUSTOMER_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false
            };
        case INITIALIZE_EDIT_CUSTOMER_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_EDIT_CUSTOMER_PAGE_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};