import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    clearRequestPasswordResetLinkState,
    requestPasswordResetLink
} from "../actions";
import { Card, Spinner, Image, Form, Button} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';

class ForgotPassword extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const email = "";

        this.state = {
            history,
            params,
            location,
            width,
            height,
            email
        };

    }

    componentWillUnmount(){

        this.setState({email: ''});

        this.props.clearRequestPasswordResetLinkState();

    }

    componentDidMount(){

        const { logged_in } = this.props;

        const { history } = this.state;

        if(logged_in){

            history.push("/home");

        }

    }

    getResetLink(){

        const { requestPasswordResetLink } = this.props;

        const { email } = this.state;

        requestPasswordResetLink(email);

        this.setState({email: ''});



    }

    formErrors(){

        const { errors } = this.props;

        return _.map(errors, (error, index) => {

            return(

                <p
                    style={{
                        color: '#ff0000',
                        marginBottom: '20px'
                    }}
                    key={index}
                >
                    {error}
                </p>

            );

        });

    }

    formMessage(){

        const { message } = this.props;

        if(!_.isEmpty(message)){

            return(

                <p
                    style={{
                        color: '#32CD32',
                        marginBottom: isMobile ? '30px' : '20px',
                        textAlign: 'center'
                    }}
                >
                    {message}
                </p>



            );

        }

    }

    formContent(){

        return(

            <Fragment>

                <p style={ isMobile ? {
                    fontSize: '25px',
                    fontWeight: 'bold',
                    marginBottom: '30px'
                } :  {
                    fontSize: '35px',
                    marginBottom: '60px'
                }}>
                    Send Password Reset Link
                </p>


                <Form onSubmit={(e) => {
                    e.preventDefault();
                }}>

                    <Form.Group
                        controlId="formBasicEmail"
                    >

                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={this.state.email}
                            style={{
                                width: isMobile ? this.state.width - 60 : this.state.width / 3,
                                marginBottom: '30px'
                            }}
                            onChange={(e) => {
                                this.setState({email: e.target.value});
                            }}
                            onKeyPress={(e) => {

                                if(e.charCode === 13){

                                    e.preventDefault();

                                    this.getResetLink()

                                }

                            }}
                        />

                    </Form.Group>


                </Form>

                {this.formErrors()}

                {this.formMessage()}

                <Button
                    variant="success"
                    size="lg"
                    style={{
                        alignSelf: 'center',
                        width:  isMobile ? this.state.width - 60 : '40%',
                        borderRadius: '20px',
                        marginBottom: '15px'
                    }}
                    onClick={() => {

                        this.getResetLink();

                    }}
                >
                    Submit
                </Button>

            </Fragment>


        );

    }


    renderForm(){

        if(isMobile){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>

                    {this.formContent()}

                </div>

            );

        }else{

            return(

                <Card style={{
                    width: this.state.width / 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '30px',
                    height: this.state.height / 2
                }}>

                    {this.formContent()}


                </Card>


            );

        }


    }

    renderBody(){

        const { loading } = this.props;

        if(loading){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );


        }else{

            return(

                <div style={ isMobile ? {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: this.state.height
                } : {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: this.state.height
                }}>


                    {this.renderForm()}



                </div>

            );

        }



    }


    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        logged_in
    } = state.login;

    const {
        loading,
        message,
        errors
    } = state.forgot_password;

    return {
        logged_in,
        loading,
        message,
        errors
    };


};

export default connect(mapStateToProps, {
    clearRequestPasswordResetLinkState,
    requestPasswordResetLink
})(ForgotPassword);
