import {
    LOGOUT_SUCCESS,
    VIEW_CUSTOMER_ACCOUNT_CHANGED,
    INITIALIZE_VIEW_CUSTOMER_PAGE,
    INITIALIZE_VIEW_CUSTOMER_PAGE_COMPLETE,
    CLEAR_VIEW_CUSTOMER_PAGE_STATE,
    OPEN_APPROVE_ACCOUNT_MODAL,
    CLOSE_APPROVE_ACCOUNT_MODAL,
    APPROVE_ACCOUNT_MODAL_ERROR_CHANGED,
    APPROVE_CUSTOMER_ACCOUNT,
    APPROVE_CUSTOMER_ACCOUNT_COMPLETE,
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    email: '',
    full_name: '',
    company_name: '',
    company_address: '',
    company_state: '',
    company_city: '',
    company_zip_code: '',
    account_approved: '',
    state_code: '',
    seller_permit_url: '',
    approve_account_modal_visible: false,
    approve_account_modal_error: '',
    approving_account: false,
    email_consent_given: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case APPROVE_CUSTOMER_ACCOUNT_COMPLETE:
            return{
                ...state,
                approving_account: false
            };
        case APPROVE_CUSTOMER_ACCOUNT:
            return{
                ...state,
                approving_account: true
            };
        case APPROVE_ACCOUNT_MODAL_ERROR_CHANGED:
            return{
                ...state,
                approve_account_modal_error: action.payload
            };
        case CLOSE_APPROVE_ACCOUNT_MODAL:
            return{
                ...state,
                approve_account_modal_visible: false,
                approve_account_modal_error: '',
                approving_account: false
            };
        case OPEN_APPROVE_ACCOUNT_MODAL:
            return{
                ...state,
                approve_account_modal_visible: true
            };
        case VIEW_CUSTOMER_ACCOUNT_CHANGED:
            return{
                ...state,
                email: action.payload.email,
                full_name: action.payload.full_name,
                company_name: action.payload.company_name,
                company_address: action.payload.company_address,
                company_state: action.payload.company_state,
                company_city: action.payload.company_city,
                company_zip_code: action.payload.company_zip_code,
                account_approved: action.payload.account_approved,
                state_code: action.payload.state_code,
                seller_permit_url: action.payload.seller_permit_url,
                email_consent_given: action.payload.email_consent_given
            };
        case INITIALIZE_VIEW_CUSTOMER_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false
            };
        case INITIALIZE_VIEW_CUSTOMER_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_VIEW_CUSTOMER_PAGE_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};

