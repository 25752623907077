import {
    CLEAR_CREATE_ADMIN_STATE,
    LOGOUT_SUCCESS,
    CREATE_ADMIN_ACCOUNT,
    CREATE_ADMIN_ACCOUNT_SUCCESS,
    CREATE_ADMIN_ACCOUNT_FAILURE,
    INITIALIZE_CREATE_ADMIN_PAGE,
    INITIALIZE_CREATE_ADMIN_PAGE_COMPLETE
} from "../actions/types";

const INITIAL_STATE = {
    loading: false,
    success_message: '',
    error_message: '',
    admin_roles: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case INITIALIZE_CREATE_ADMIN_PAGE_COMPLETE:
            return{
                ...state,
                loading: false,
                admin_roles: action.payload
            };
        case INITIALIZE_CREATE_ADMIN_PAGE:
            return{
                ...state,
                loading: true
            };
        case CREATE_ADMIN_ACCOUNT_FAILURE:
            return{
                ...state,
                loading: false,
                error_message: action.payload
            };
        case CREATE_ADMIN_ACCOUNT_SUCCESS:
            return{
                ...state,
                loading: false,
                success_message: action.payload
            };
        case CREATE_ADMIN_ACCOUNT:
            return{
                ...state,
                loading: true,
                success_message: '',
                error_message: ''
            };
        case CLEAR_CREATE_ADMIN_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};