import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form, FormControl, Table, Accordion} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeAdminAccountsPage,
    searchAdminAccounts,
    destroyAdminAccount
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";
import {Trash} from 'react-bootstrap-icons';

class AdminAccounts extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const search_term = "";

        const selected_role = null;

        this.state = {
            history,
            params,
            location,
            width,
            height,
            search_term,
            selected_role
        };

    }

    componentWillUnmount(){

        this.setState({search_term: '', selected_role: null});

    }

    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeAdminAccountsPage
        } = this.props;

        const { history } = this.state;

        if(!logged_in){

            history.push("/");

        }else{

            if(!roles.includes("root_admin")){

                history.push("/home");

            }else{

                initializeAdminAccountsPage(access_token, client, uid, history);

            }

        }

    }

    adminRolesOptions(){

        const { admin_roles } = this.props;

        let options = [];

        options.push({ label: 'Select Role', value: ''});

        _.map(admin_roles, (role, index) => {

            options.push({
                label: _.startCase( role.split("_").join(" ") ),
                value: role
            });

        });

        return options;

    }


    deleteAdminAccount(account_id){

        const {
            destroyAdminAccount,
            access_token,
            client,
            uid,
            admin_accounts
        } = this.props;

        const { history } = this.state;


        destroyAdminAccount(access_token, client, uid, history, account_id, admin_accounts);

    }


    editAdminAccount(id){

        const history = this.state.history;

        history.push(`/admin-accounts/edit/id=${id}`);


    }

    renderAccounts(){

        const { admin_accounts } = this.props;

        const { history } = this.state;

        return _.map(admin_accounts, (account, index) => {

            if(isMobile){

                return(

                    <Card
                        key={index}
                        style={{
                            marginBottom: '20px',
                            width: this.state.width - 50
                        }}
                    >

                        <Accordion.Item eventKey={index}>

                            <Accordion.Header>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>

                                    <p style={{
                                        fontWeight: 'bold'
                                    }}>
                                        {account.full_name}
                                    </p>

                                    <p>
                                        {account.email}
                                    </p>


                                </div>

                            </Accordion.Header>

                            <Accordion.Body>


                                <Form.Group className="admin-account-attribute-container" >

                                    <Form.Label  >
                                        Full Name
                                    </Form.Label>


                                    <Form.Control
                                        readOnly
                                        type="text"
                                        value={account.full_name}
                                        disabled
                                    />

                                </Form.Group>


                                <Form.Group className="admin-account-attribute-container" >

                                    <Form.Label  >
                                        Email
                                    </Form.Label>


                                    <Form.Control
                                        readOnly
                                        type="text"
                                        value={account.email}
                                        disabled
                                    />

                                </Form.Group>


                                <Form.Group className="admin-account-attribute-container">

                                    <Form.Label  >
                                        Roles
                                    </Form.Label>


                                    <Form.Control
                                        readOnly
                                        type="text"
                                        value={account.roles}
                                        disabled
                                    />

                                </Form.Group>

                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}>

                                    <Button
                                        variant="outline-primary"
                                        style={{marginBottom: '15px'}}
                                        onClick={() => {
                                            this.editAdminAccount(account.id);
                                        }}
                                    >
                                        Edit
                                    </Button>

                                    <Button
                                        variant="outline-danger"
                                        onClick={() => {
                                            this.deleteAdminAccount(account.id);
                                        }}
                                    >
                                        Delete
                                    </Button>


                                </div>


                            </Accordion.Body>

                        </Accordion.Item>


                    </Card>

                );


            }else{

                return(

                    <tr key={index}>

                        <td>
                            {account.full_name}
                        </td>

                        <td>
                            { account.email }
                        </td>

                        <td>
                            {account.roles}
                        </td>


                        <td>
                            <p
                                onClick={() => {
                                    this.editAdminAccount(account.id);
                                }}
                                style={{
                                    textDecoration: 'underline',
                                    color: '#0000EE',
                                    textAlign: 'center'
                                }}
                            >
                                Edit
                            </p>
                        </td>

                        <td >

                            <div style={{
                                justifyContent: 'center',
                                display: 'flex'
                            }}>

                                <Trash
                                    color="#ff0000"
                                    size={20}
                                    style={{
                                        marginTop: '2px'
                                    }}
                                    onClick={() => {
                                        this.deleteAdminAccount(account.id);
                                    }}
                                />

                            </div>


                        </td>

                    </tr>

                );

            }



        });


    }


    renderAdmins(){

        const { searching, admin_accounts } = this.props;

        if(searching){

            return(

                <Spinner animation="border" variant="primary"  />


            );

        }else if(!_.isEmpty(admin_accounts)){

            if(isMobile){


                return(

                    <Accordion>

                        {this.renderAccounts()}


                    </Accordion>


                );

            }else{

                return(



                    <Table style={ {
                        width:  this.state.width / 2
                    }}   striped bordered hover>

                        <thead>

                        <tr>
                            <th>Full Name</th>
                            <th>Email</th>
                            <th>Roles</th>
                            <th></th>
                            <th></th>
                        </tr>

                        </thead>

                        <tbody>

                        {this.renderAccounts()}

                        </tbody>

                    </Table>



                );


            }



        }



    }





    renderBody(){

        const {
            fetching_admin_profile,
            initializing_page,
            access_token,
            client,
            uid,
            searchAdminAccounts
        } = this.props;

        const { history, params, location, search_term, selected_role} = this.state;



        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{


            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />


                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>


                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>

                            <p style={{
                                fontSize: isMobile ? '30px' : '35px'
                            }}>
                                Admin Accounts
                            </p>

                            <Form style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '1.5rem'
                            }}>

                                <FormControl
                                    type="text"
                                    placeholder="search by email, full name"
                                    style={{
                                        borderRadius: '20px',
                                        width: isMobile ? this.state.width - 70 : this.state.width / 2.5
                                    }}
                                    onChange={(e) => {

                                        e.preventDefault();

                                        const new_search_term = e.target.value;

                                        this.setState({search_term: new_search_term});

                                        searchAdminAccounts(access_token, client, uid, history, new_search_term, selected_role);


                                    }}
                                    onKeyPress={(e) => {
                                        if(e.charCode === 13){

                                            e.preventDefault();

                                            searchAdminAccounts(access_token, client, uid, history, search_term, selected_role);


                                        }
                                    }}
                                    value={search_term}
                                />

                            </Form>

                            <Form style={{
                                marginBottom: '2rem'
                            }}>

                                <Form.Group style={{
                                    width: isMobile ? this.state.width - 90 : this.state.width / 6
                                }} >

                                    <Form.Label style={{
                                        fontSize: '18px'
                                    }} >
                                        Role
                                    </Form.Label>

                                    <Form.Control
                                        as="select"
                                        onChange={(e) => {

                                            const new_selected_role = e.target.value;

                                            console.log(new_selected_role);

                                            if(_.isEmpty(new_selected_role)){

                                                this.setState({selected_role: null});

                                            }else{

                                                this.setState({selected_role: new_selected_role});
                                            }

                                            searchAdminAccounts(access_token, client, uid, history, search_term, new_selected_role);


                                        }}
                                    >

                                        {
                                            this.adminRolesOptions().map((role, index) => (
                                                <option
                                                    key={index}
                                                    value={role.value}
                                                >
                                                    {role.label}
                                                </option>
                                            ))
                                        }

                                    </Form.Control>

                                </Form.Group>

                            </Form>

                            <Button
                                variant="outline-success"
                                style={{
                                    marginBottom: isMobile ? '2rem' : '3rem',
                                    width: isMobile ? this.state.width - 90 : this.state.width / 6
                                }}
                                onClick={() =>{
                                    history.push("/admin-accounts/new");
                                }}
                            >
                                Create New Account
                            </Button>

                            {this.renderAdmins()}


                        </div>






                    </div>




                </div>

            )

        }

    }

    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile,
        initializing_page,
        admin_accounts,
        admin_roles,
        searching
    } = state.admin_accounts;


    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        admin_accounts,
        admin_roles,
        searching
    };

};

export default connect(mapStateToProps, {
    initializeAdminAccountsPage,
    searchAdminAccounts,
    destroyAdminAccount
})(AdminAccounts);

