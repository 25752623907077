import {
    LOGOUT_SUCCESS,
    INITIALIZE_PRODUCTS_PAGE,
    INITIALIZE_PRODUCTS_PAGE_COMPLETE,
    CLEAR_PRODUCTS_PAGE_STATE,
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_COMPLETE,
    PRODUCTS_BATCH_ACTION_EXECUTION_COMPLETE,
    CHANGE_PRODUCTS_CATEGORY,
    CHANGE_PRODUCTS_CATEGORY_COMPLETE,
    CHANGE_PRODUCTS_CATEGORY_SUCCESS,
    PRODUCTS_PAGE_CATEGORIES_CHANGED,
    CHANGE_PRODUCTS_CATEGORY_FAILURE,
    CLEAR_CHANGE_PRODUCTS_CATEGORY_MODAL_MESSAGES,
    PRODUCTS_QUANTITY_CHANGED
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    products: [],
    categories: [],
    topics: [],
    searching_products: false,
    batch_action_executed: false,
    changing_products_category: false,
    change_products_category_error_message: '',
    change_products_category_success_message: '',
    quantity: 20
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCTS_QUANTITY_CHANGED:
            return{
                ...state,
                quantity: action.payload
            };
        case CLEAR_CHANGE_PRODUCTS_CATEGORY_MODAL_MESSAGES:
            return{
                ...state,
                change_products_category_error_message: '',
                change_products_category_success_message: ''
            };
        case PRODUCTS_BATCH_ACTION_EXECUTION_COMPLETE:
            return{
                ...state,
                batch_action_executed: false
            };
        case CHANGE_PRODUCTS_CATEGORY_FAILURE:
            return{
                ...state,
                change_products_category_error_message: action.payload
            };
        case PRODUCTS_PAGE_CATEGORIES_CHANGED:
            return{
                ...state,
                categories: action.payload
            };
        case CHANGE_PRODUCTS_CATEGORY_SUCCESS:
            return{
                ...state,
                batch_action_executed: true,
                change_products_category_success_message: action.payload
            };
        case CHANGE_PRODUCTS_CATEGORY_COMPLETE:
            return{
                ...state,
                changing_products_category: false
            };
        case CHANGE_PRODUCTS_CATEGORY:
            return{
                ...state,
                changing_products_category: true,
                change_products_category_error_message: ''
            };
        case SEARCH_PRODUCTS_COMPLETE:
            return{
                ...state,
                searching_products: false,
                products: action.payload
            };
        case SEARCH_PRODUCTS:
            return{
                ...state,
                searching_products: true
            };
        case INITIALIZE_PRODUCTS_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false,
                products: action.payload.products,
                categories: action.payload.categories,
                topics: action.payload.topics
            };
        case INITIALIZE_PRODUCTS_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_PRODUCTS_PAGE_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};