import {
    REQUEST_PASSWORD_RESET_LINK_ROUTE,
    REQUEST_PASSWORD_RESET_LINK,
    REQUEST_PASSWORD_RESET_LINK_SUCCESS,
    REQUEST_PASSWORD_RESET_LINK_FAILURE,
    REQUEST_PASSWORD_RESET_LINK_COMPLETE,
    CLEAR_REQUEST_PASSWORD_RESET_LINK_STATE
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const clearRequestPasswordResetLinkState = () => {

    return{
      type: CLEAR_REQUEST_PASSWORD_RESET_LINK_STATE
    };

};

export const requestPasswordResetLink = (email) => {

    return(dispatch) => {

        dispatch({type: REQUEST_PASSWORD_RESET_LINK});

        const config = {
            headers: {
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            email: email
        });

        axios.post(REQUEST_PASSWORD_RESET_LINK_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: REQUEST_PASSWORD_RESET_LINK_COMPLETE});

                const data = response.data;

                const message = data.message;

                const success = data.success;

                if( success && !_.isEmpty(message)){

                    dispatch({type: REQUEST_PASSWORD_RESET_LINK_SUCCESS, payload: message});

                }


            }).catch(error => {

            dispatch({type: REQUEST_PASSWORD_RESET_LINK_COMPLETE});

            if(error.response !== undefined){

                const data = error.response.data;

                if(data !== undefined){

                    const errors = data.errors;

                    const success = data.success;

                    if(!success && !_.isEmpty(errors)){

                        dispatch({type: REQUEST_PASSWORD_RESET_LINK_FAILURE, payload: errors});

                    }

                }

            }

        });

    };

};