import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { getAdminProfile } from "../actions";

class Wrapper extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        this.state = {
            history
        };

    }

    componentDidMount(){

        const { access_token,  client, uid, getAdminProfile, logged_in  } = this.props;

        const { history } = this.state;

        if(logged_in){

            getAdminProfile(access_token, client, uid, history);

        }



    }

    render(){

        return(
            <Fragment>

                {this.props.children}

            </Fragment>
        );

    }

}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid
    } = state.login;

    return{
        logged_in,
        access_token,
        client,
        uid
    };

};

export default connect(mapStateToProps, {
    getAdminProfile
})(Wrapper);


