import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {
    resetPassword,
    clearResetPasswordState,
    resetPassword1Changed,
    resetPassword2Changed
} from "../actions";
import { Card, Spinner, Image, Form, Button} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';

class ResetPassword extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const access_token = params.access_token;

        const client = params.client;

        const uid = params.uid;

        const reset_password_token = params.reset_password_token;


        this.state = {
            history,
            params,
            location,
            width,
            height,
            access_token,
            client,
            uid,
            reset_password_token
        };

    }

    componentWillUnmount(){

        this.props.clearResetPasswordState();

    }

    componentDidMount(){

        const { logged_in } = this.props;

        const { history } = this.state;

        if(logged_in){

            history.push("/home");

        }

    }

    formError(){

        const { error } = this.props;

        if(error.length > 0){

            return(

                <p
                    style={{
                        color: '#ff0000',
                        marginBottom: '20px'
                    }}
                >
                    {error}
                </p>

            );


        }

    }

    changePassword(){

        const {
            password1,
            password2,
            resetPassword
        } = this.props;

        const {
            access_token,
            client,
            uid,
            reset_password_token,
            history
        } = this.state;


        resetPassword(access_token, client, uid, reset_password_token, history, password1, password2);

    }

    formContent(){

        const {
            password1,
            resetPassword1Changed,
            password2,
            resetPassword2Changed
        } = this.props;

        return(

            <Fragment>



                <p style={ isMobile ? {
                    fontSize: '30px',
                    fontWeight: 'bold',
                    marginBottom: '30px'
                } :  {
                    fontSize: '35px',
                    marginBottom: '60px'
                }}>
                    Reset Password
                </p>


                <Form onSubmit={(e) => {
                    e.preventDefault();
                }}>

                    <Form.Group
                        controlId="formBasicPassword1"
                    >


                        <Form.Control
                            type="password"
                            placeholder="password"
                            value={password1}
                            style={{
                                width: isMobile ? this.state.width - 60 : this.state.width / 3,
                                marginBottom: '30px'
                            }}
                            onChange={(e) => {

                                resetPassword1Changed(e.target.value);

                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        controlId="formBasicPassword2"
                    >


                        <Form.Control
                            type="password"
                            placeholder="retype password"
                            value={password2}
                            style={{
                                width: isMobile ? this.state.width - 60 : this.state.width / 3,
                                marginBottom: '30px'
                            }}
                            onChange={(e) => {

                                resetPassword2Changed(e.target.value);

                            }}
                            onKeyPress={(e) => {

                                if(e.charCode === 13){

                                    e.preventDefault();

                                    this.changePassword();

                                }

                            }}
                        />

                    </Form.Group>


                </Form>

                {this.formError()}

                <Button
                    variant="success"
                    size="lg"
                    style={{
                        alignSelf: 'center',
                        width:  isMobile ? this.state.width - 60 : '40%',
                        borderRadius: '20px',
                        marginBottom: '15px'
                    }}
                    onClick={() => {

                        this.changePassword();

                    }}
                >
                    Submit
                </Button>


            </Fragment>

        );

    }

    renderForm(){

        if(isMobile){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column'
                }}>

                    {this.formContent()}

                </div>

            );

        }else{

            return(

                <Card style={{
                    width: this.state.width / 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '30px',
                    height: this.state.height / 2
                }}>

                    {this.formContent()}


                </Card>


            );

        }


    }


    renderBody(){

        const {
            loading
        } = this.props;


        if(loading){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );


        }else{

            return(

                <div style={ isMobile ? {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: this.state.height
                } : {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: this.state.height
                }}>

                    {this.renderForm()}

                </div>

            );

        }



    }

    render() {


        return (

            <Fragment>

                {this.renderBody()}

            </Fragment>

        );

    }


}


const mapStateToProps = (state) => {

    const {
        logged_in
    } = state.login;

    const {
        password1,
        password2,
        loading,
        error
    } = state.reset_password;

    return {
        logged_in,
        password1,
        password2,
        loading,
        error
    };


};

export default connect(mapStateToProps, {
    resetPassword,
    clearResetPasswordState,
    resetPassword1Changed,
    resetPassword2Changed
})(ResetPassword);
