import {
    UPDATE_ADMIN_ACCOUNT_EMAIL_ROUTE,
    ADMIN_UPDATE_OWN_ACCOUNT,
    ADMIN_UPDATE_OWN_ACCOUNT_SUCCESS,
    ADMIN_UPDATE_OWN_ACCOUNT_FAILURE,
    ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE,
    LOGOUT_SUCCESS,
    CLEAR_HOME_MODAL_FORM_STATE,
    ADMIN_ACCOUNT_UID_CHANGED,
    UPDATE_ADMIN_ACCOUNT_PASSWORD_ROUTE
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const clearHomeModalFormState = () => {

    return{
      type: CLEAR_HOME_MODAL_FORM_STATE
    };

};

export const adminChangeOwnPassword = (access_token, client, uid, history, id, password) => {

    return(dispatch) => {

        dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: id,
            password: password
        });

        axios.post(UPDATE_ADMIN_ACCOUNT_PASSWORD_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_SUCCESS, payload: 'Successfully changed password'});

                }else{

                    let message = data.message;

                    message =  (message === null || message === undefined || _.isEmpty(message) ) ? 'Error updating password'  : message;

                    dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_FAILURE, payload: message});

                }



            }).catch(error => {

            dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE});

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };

};


export const adminChangeOwnEmail = (access_token, client, uid, history, id, email) => {


    return(dispatch) => {

        dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: id,
            email: email
        });

        axios.post(UPDATE_ADMIN_ACCOUNT_EMAIL_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const headers = response.headers;

                    const uid = headers["uid"];

                    dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_SUCCESS, payload: 'Successfully changed email'});

                    dispatch({ type: ADMIN_ACCOUNT_UID_CHANGED, payload: uid });

                }else{

                    let message = data.message;

                    message =  (message === null || message === undefined || _.isEmpty(message) ) ? 'Error updating email'  : message;

                    dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_FAILURE, payload: message});

                }



            }).catch(error => {

            dispatch({type: ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE});

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };

};