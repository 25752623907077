import {
    LOGOUT_SUCCESS,
    INITIALIZE_CUSTOMER_ACCOUNTS_PAGE_ROUTE,
    GET_CUSTOMER_ACCOUNTS,
    GET_CUSTOMER_ACCOUNTS_COMPLETE,
    CUSTOMER_ACCOUNTS_CHANGED,
    CLEAR_CUSTOMER_ACCOUNTS_STATE,
    SEARCH_CUSTOMER_ACCOUNTS_ROUTE,
    SEARCH_CUSTOMER_ACCOUNTS,
    SEARCH_CUSTOMER_ACCOUNTS_COMPLETE
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";





export const clearCustomerAccountsState = () => {

    return{
      type: CLEAR_CUSTOMER_ACCOUNTS_STATE
    };

};

export const searchCustomerAccounts = (
    access_token,
    client,
    uid,
    history,
    search_term,
    account_approved,
    sort_direction,
    email_consent_given
) => {

    return(dispatch) => {

        dispatch({type: SEARCH_CUSTOMER_ACCOUNTS});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };


        let bodyFormData = new FormData();

        let data = {
            search_term: search_term
        };

        if(account_approved !== null && account_approved !== undefined){
            data.account_approved = account_approved;
        }

        if(sort_direction !== null && sort_direction !== undefined){
            data.sort_direction = sort_direction;
        }

        if(email_consent_given !== null && email_consent_given !== undefined){
            data.email_consent_given = email_consent_given;
        }


        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });

        axios.post(SEARCH_CUSTOMER_ACCOUNTS_ROUTE, bodyFormData, config)
            .then( response => {

                dispatch({type: SEARCH_CUSTOMER_ACCOUNTS_COMPLETE});

                const data = response.data;

                const customers = data.customers;

                console.log(customers);

                dispatch({type: CUSTOMER_ACCOUNTS_CHANGED, payload: customers});



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};


export const initializeCustomerAccountsPage = (
    access_token,
    client,
    uid,
    history
) => {

    return(dispatch) => {

        dispatch({type: GET_CUSTOMER_ACCOUNTS});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.get(INITIALIZE_CUSTOMER_ACCOUNTS_PAGE_ROUTE, config)
            .then(response => {

                dispatch({type: GET_CUSTOMER_ACCOUNTS_COMPLETE});

                const data = response.data;

                const customers = data.customers;

                console.log(customers);

                dispatch({type: CUSTOMER_ACCOUNTS_CHANGED, payload: customers});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });




    };

};