import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

import {
    LOGIN_ADMIN,
    LOGIN_ADMIN_SUCCESS,
    LOGIN_ADMIN_FAILURE,
    LOGOUT_SUCCESS,
    ADMIN_ACCOUNT_FULL_NAME_CHANGED,
    ADMIN_ACCOUNT_UID_CHANGED,
    ADMIN_ACCOUNT_ROLES_CHANGED,
} from "../actions/types";

const INITIAL_STATE = {
    access_token: '',
    client: '',
    uid: '',
    logged_in: false,
    id: '',
    roles: [],
    full_name: '',
    loading: false,
    login_errors: []
};

const LoginReducer =  (state = INITIAL_STATE , action) => {

    switch (action.type) {
        case ADMIN_ACCOUNT_ROLES_CHANGED:
            return{
                ...state,
                roles: action.payload
            };
        case ADMIN_ACCOUNT_UID_CHANGED:
            return{
                ...state,
                uid: action.payload
            };
        case ADMIN_ACCOUNT_FULL_NAME_CHANGED:
            return{
                ...state,
                full_name: action.payload
            };
        case LOGIN_ADMIN_SUCCESS:
            return{
                ...state,
                loading: false,
                access_token: action.payload.access_token,
                client: action.payload.client,
                uid: action.payload.uid,
                logged_in: true,
                roles: action.payload.roles,
                id: action.payload.id,
                full_name: action.payload.full_name
            };
        case LOGIN_ADMIN_FAILURE:
            return{
                ...state,
                loading: false,
                login_errors: action.payload
            };
        case LOGIN_ADMIN:
            return{
                ...state,
                loading: true,
                login_errors: []
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }

};

const persistConfig = {
    key: 'login',
    storage,
    whitelist: [
        'access_token',
        'client',
        'uid',
        'logged_in',
        'roles',
        'id',
        'full_name'
    ],
    stateReconciler: autoMergeLevel1
};

export default persistReducer(persistConfig, LoginReducer);

