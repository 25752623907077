import {
    LOGOUT_SUCCESS,
    GET_ADMIN_PROFILE,
    GET_ADMIN_PROFILE_COMPLETE,
    INITIALIZE_ADMIN_ACCOUNTS_PAGE,
    INITIALIZE_ADMIN_ACCOUNTS_PAGE_COMPLETE,
    SEARCH_ADMIN_ACCOUNTS,
    SEARCH_ADMIN_ACCOUNTS_COMPLETE,
    DESTROY_ADMIN_ACCOUNT
} from "../actions/types";

const INITIAL_STATE = {
    fetching_admin_profile: false,
    initializing_page: false,
    admin_accounts: [],
    admin_roles: [],
    searching: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DESTROY_ADMIN_ACCOUNT:
            return{
                ...state,
                admin_accounts: action.payload
            };
        case SEARCH_ADMIN_ACCOUNTS_COMPLETE:
            return{
                ...state,
                searching: false,
                admin_accounts: action.payload
            };
        case SEARCH_ADMIN_ACCOUNTS:
            return{
                ...state,
                searching: true
            };
        case INITIALIZE_ADMIN_ACCOUNTS_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false,
                admin_accounts: action.payload.admin_accounts,
                admin_roles: action.payload.admin_roles
            };
        case INITIALIZE_ADMIN_ACCOUNTS_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case GET_ADMIN_PROFILE_COMPLETE:
            return{
                ...state,
                fetching_admin_profile: false
            };
        case GET_ADMIN_PROFILE:
            return{
                ...state,
                fetching_admin_profile: true
            };
        case LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};