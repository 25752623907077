import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    logoutAdmin,
    adminChangeOwnEmail,
    clearHomeModalFormState,
    adminChangeOwnPassword
} from "../actions";
import Wrapper from "./Wrapper";
import { ChevronRight} from 'react-bootstrap-icons';
import TopHeader from "./TopHeader";

class Home extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const modal_visible = false;

        const modal_action = null;

        const email = "";

        const password = "";

        this.state = {
            history,
            params,
            location,
            width,
            height,
            modal_visible,
            modal_action,
            email,
            password
        };

    }

    componentDidMount(){

        const { logged_in } = this.props;

        const { history } = this.state;

        if(!logged_in){

            history.push("/");

        }


    }



    getPages(){

        const pages = [
            {title: 'Admin Accounts', route: '/admin-accounts', roles: ["root_admin"]},
            {title: 'Products & Categories', route: '/categories', roles: ["lister"]},
            {title: 'Customer Accounts', route: '/customer-accounts', roles: ["account_moderator"]},
            {title: 'Landing Page Images', route: '/landing-page-images', roles: []}
        ];

        const roles = this.props.roles;

        const history = this.state.history;


        return _.map(pages, (page, index) =>{

            const can_view_page = page.roles.length === 0 ||  roles.some(r=> page.roles.indexOf(r) >= 0) || roles.includes("root_admin");

            if(can_view_page){

                return(

                    <Card
                        key={index}
                        style={{
                            width: isMobile ? this.state.width - 60 :  this.state.width / 2,
                            marginBottom: '20px',
                            padding: isMobile ? '10px' : '10px'
                        }}
                    >

                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                marginBottom: '20px'
                            }}
                            onClick={() => {
                                history.push(page.route);
                            }}
                        >

                            <Card.Body style={{
                                fontWeight: isMobile ? 'none' : 'bold',
                                fontSize: isMobile ? '16px' : '20px'
                            }}>{page.title}</Card.Body>

                            <ChevronRight color="royalblue" size={30} style={{
                                marginRight: '6px'
                            }} />

                        </div>

                    </Card>

                );

            }


        });

    }

    exitModal(){

        this.props.clearHomeModalFormState();

        this.setState({
            modal_visible: false,
            modal_action: null,
            email: '',
            password: ''
        });


    }



    modalBody(){

        const {modal_action} = this.state;

        const {  modal_updating } = this.props;


        if(modal_updating){

            return(


                <div className="spinner-container">

                    <Spinner animation="border" variant="primary" />

                </div>



            );


        }else{

            if (modal_action === 0) {

                return (

                    <Form onSubmit={(e) => e.preventDefault()}>

                        <Form.Group
                            controlId="formBasicEmail"
                        >


                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={(e) => {
                                    this.setState({email: e.target.value});
                                }}
                            />

                        </Form.Group>




                    </Form>

                );

            } else if(modal_action === 1){

                return (

                    <Form onSubmit={(e) => e.preventDefault()}>

                        <Form.Group
                            controlId="formBasicEmail"
                        >


                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={(e) => {
                                    this.setState({password: e.target.value});
                                }}
                            />

                        </Form.Group>




                    </Form>

                );

            }

        }



    }


    modalTitle() {

        const {modal_action} = this.state;

        if (modal_action === 0) {

            return "Change Email";

        } else if(modal_action === 1){

            return "Change Password";
        }

    }


    modalErrorMessage(){

        const {modal_error_message } = this.props;

        if(modal_error_message.length > 0){

            return(

                <p style={{
                    color: '#ff0000',
                    textAlign: 'center'
                }}>
                    {modal_error_message}
                </p>

            );

        }

    }

    modalSuccessMessage(){

        const {modal_success_message } = this.props;

        if(modal_success_message.length > 0){

            return(

                <p style={{
                    color: '#228B22',
                    textAlign: 'center'
                }}>
                    {modal_success_message}
                </p>

            );

        }

    }

    renderModal(){

        const {
            adminChangeOwnEmail,
            access_token,
            client,
            uid,
            id,
            adminChangeOwnPassword
        } = this.props;


        const {
            modal_visible,
            modal_action,
            history,
            email,
            password
        } = this.state;


        if(modal_visible && modal_action !== null){


            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={modal_visible}
                    onHide={() => {
                        this.exitModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.modalTitle()}
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.modalBody()}

                    </Modal.Body>

                    {this.modalErrorMessage()}

                    {this.modalSuccessMessage()}

                    <Modal.Footer>

                        <Button
                            variant="success"
                            style={{
                                marginRight: '10px'
                            }}
                            onClick={() => {

                                if(modal_action === 0){

                                    adminChangeOwnEmail(access_token, client, uid, history, id, email);

                                }else if (modal_action === 1){

                                    adminChangeOwnPassword(access_token, client, uid, history, id, password)

                                }

                            }}>
                            Submit
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>


            );

        }


    }




    renderBody(){

        const {
            fetching_admin_profile,
            full_name
        } = this.props;


        const { history, params, location} = this.state;

        if(fetching_admin_profile){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />


                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>


                        <div style={ isMobile ? {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        } : {
                            width: this.state.width / 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>


                            <p style={ isMobile ? {
                                fontSize: '18px',
                                marginBottom: '20px'
                            } : {
                                fontSize: '35px',
                                marginBottom: '35px'
                            }}>
                                Welcome, {full_name}
                            </p>


                            {this.getPages()}

                            <Button
                                variant="outline-primary"
                                style={{
                                    width:  isMobile ? this.state.width - 60 : this.state.width / 2,
                                    fontSize: isMobile ? '16px' : '20px',
                                    marginBottom: '20px'
                                }}
                                onClick={() => {
                                    this.setState({modal_visible: true, modal_action: 0});
                                }}
                            >
                                Change Email
                            </Button>

                            <Button
                                variant="outline-primary"
                                style={{
                                    width:  isMobile ? this.state.width - 60 : this.state.width / 2,
                                    fontSize: isMobile ? '16px' : '20px'
                                }}
                                onClick={() => {
                                    this.setState({modal_visible: true, modal_action: 1});
                                }}
                            >
                                Change Password
                            </Button>

                        </div>

                    </div>


                    {this.renderModal()}


                </div>

            )

        }

    }

    render() {


        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        logged_in,
        full_name,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        modal_updating,
        modal_error_message,
        modal_success_message
    } = state.home;

    return {
        logged_in,
        full_name,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        modal_updating,
        modal_error_message,
        modal_success_message
    };

};

export default connect(mapStateToProps, {
    logoutAdmin,
    adminChangeOwnEmail,
    clearHomeModalFormState,
    adminChangeOwnPassword
})(Home);

