import {
    RESET_PASSWORD,
    RESET_PASSWORD_COMPLETE,
    CLEAR_RESET_PASSWORD_STATE,
    RESET_PASSWORD1_CHANGED,
    RESET_PASSWORD2_CHANGED,
    RESET_PASSWORD_FORM_ERROR_CHANGED,
} from "../actions/types";

const INITIAL_STATE = {
    password1: '',
    password2: '',
    loading: false,
    error: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case RESET_PASSWORD2_CHANGED:
            return{
                ...state,
                password2: action.payload
            };
        case RESET_PASSWORD1_CHANGED:
            return{
                ...state,
                password1: action.payload
            };
        case CLEAR_RESET_PASSWORD_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case RESET_PASSWORD_FORM_ERROR_CHANGED:
            return{
                ...state,
                error: action.payload
            };
        case RESET_PASSWORD_COMPLETE:
            return{
                ...state,
                loading: false
            };
        case RESET_PASSWORD:
            return{
                ...state,
                loading: true,
                error: ''
            };
        default:
            return state;
    }
};