import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import { logoutAdmin} from "../actions";

class TopHeader extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.params;

        const location = props.location;

        // console.log(history);
        // console.log(params);
        // console.log(location);

        const width = window.innerWidth;

        const height = window.innerHeight;

        this.state = {
            history,
            params,
            location,
            width,
            height
        };

    }

    render() {


        return (

            <Navbar
                style={{
                    backgroundColor: "#d20505",
                    marginBottom: '2.5rem',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>


                <Image
                    src={require("../images/logo.png")}
                    style={{
                        width: isMobile ? '100px' :  '150px',
                        height: isMobile ? '75px' : '100px',
                        marginLeft: '20px',
                        marginRight: '25px'
                    }}
                    className="logo"
                    onClick={() => {
                        this.state.history.push("/home");
                    }}
                />

                <Button
                    variant="success"
                    style={ isMobile ? {
                        fontSize: '17px',
                        marginRight: '20px'
                    } : {
                        marginRight: '20px',
                        fontSize: '20px'
                    }}
                    onClick={() => {
                        const { logoutAdmin, access_token, client, uid } = this.props;

                        const { history } = this.state;

                        logoutAdmin(access_token, client, uid, history);

                    }}
                >
                    Logout
                </Button>


            </Navbar>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        access_token,
        client,
        uid,
    } = state.login;


    return {
        access_token,
        client,
        uid
    };

};

export default connect(mapStateToProps, {
    logoutAdmin
})(TopHeader);


