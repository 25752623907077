import {
    LOGOUT_SUCCESS,
    CATEGORIES_INDEX_ROUTE,
    INITIALIZE_CATEGORIES_PAGE,
    INITIALIZE_CATEGORIES_PAGE_COMPLETE,
    CLEAR_CATEGORIES_PAGE_STATE,
    CREATE_CATEGORY_ROUTE,
    CREATE_CATEGORY,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE,
    CREATE_CATEGORY_COMPLETE,
    CLEAR_CATEGORY_MODAL_MESSAGES,
    SEARCH_CATEGORIES_ROUTE,
    SEARCH_CATEGORIES,
    SEARCH_CATEGORIES_COMPLETE,
    CREATE_SUBCATEGORY_ROUTE,
    CREATE_SUBCATEGORY,
    CREATE_SUBCATEGORY_COMPLETE,
    CREATE_SUBCATEGORY_SUCCESS,
    CREATE_SUBCATEGORY_FAILURE,
    CHANGE_CATEGORY_NAME_ROUTE,
    CHANGE_CATEGORY_NAME,
    CHANGE_CATEGORY_NAME_COMPLETE,
    CHANGE_CATEGORY_NAME_SUCCESS,
    CHANGE_CATEGORY_NAME_FAILURE,
    HIDE_CATEGORY_ROUTE,
    HIDE_CATEGORY,
    HIDE_CATEGORY_COMPLETE,
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const hideCategory = (access_token, client, uid, history, category_id, search_term) => {

    return(dispatch) => {

        dispatch({type: HIDE_CATEGORY});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: category_id
        });

        axios.post(HIDE_CATEGORY_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: HIDE_CATEGORY_COMPLETE});

                dispatch(searchCategories(access_token, client, uid, history, search_term));


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };

};




export const changeCategoryName = (access_token, client, uid, history, name, category_id, search_term) => {

    return(dispatch) => {

        dispatch({type: CHANGE_CATEGORY_NAME});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            name: name,
            category_id: category_id
        });

        axios.post(CHANGE_CATEGORY_NAME_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: CHANGE_CATEGORY_NAME_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    dispatch({type: CHANGE_CATEGORY_NAME_SUCCESS});

                    dispatch(searchCategories(access_token, client, uid, history, search_term));

                }else{

                    const message = data.message;

                    dispatch({type: CHANGE_CATEGORY_NAME_FAILURE, payload: message});

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};


export const createSubcategory = (access_token, client, uid, history, name, category_id, search_term) => {

    return(dispatch) => {

        dispatch({type: CREATE_SUBCATEGORY});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            name: name,
            category_id: category_id
        });

        axios.post(CREATE_SUBCATEGORY_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: CREATE_SUBCATEGORY_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    dispatch({type: CREATE_SUBCATEGORY_SUCCESS});

                    dispatch(searchCategories(access_token, client, uid, history, search_term));


                }else{

                    const message = data.message;

                    dispatch({type: CREATE_SUBCATEGORY_FAILURE, payload: message});

                }



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };

};

export const clearCategoryModalMessages = () => {

    return{
        type: CLEAR_CATEGORY_MODAL_MESSAGES
    };

};

export const searchCategories = (access_token, client, uid, history, search_term) => {

    return(dispatch) => {

        dispatch({type: SEARCH_CATEGORIES});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            name: search_term
        });

        axios.post(SEARCH_CATEGORIES_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data;

                const categories = data.categories;

                dispatch({type: SEARCH_CATEGORIES_COMPLETE, payload: categories});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};

export const createCategory = (access_token, client, uid, history, name, search_term) => {

    return(dispatch) => {

        dispatch({type: CREATE_CATEGORY});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            name: name
        });

        axios.post(CREATE_CATEGORY_ROUTE, bodyFormData, config)
            .then( response => {

                dispatch({type: CREATE_CATEGORY_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    dispatch({type: CREATE_CATEGORY_SUCCESS});

                    dispatch(searchCategories(access_token, client, uid, history, search_term));


                }else{

                    const message = data.message;

                    dispatch({type: CREATE_CATEGORY_FAILURE, payload: message});

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const clearCategoriesPageState = () => {

    return{
      type: CLEAR_CATEGORIES_PAGE_STATE
    };

};

export const initializeCategoriesPage = (access_token, client, uid, history) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_CATEGORIES_PAGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.get(CATEGORIES_INDEX_ROUTE, config)
            .then(response => {

                const data = response.data;

                const categories = data.categories;

                console.log(categories);

                dispatch({type: INITIALIZE_CATEGORIES_PAGE_COMPLETE, payload: categories});

            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};