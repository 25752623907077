import {
    LOGOUT_SUCCESS,
    CREATE_ADMIN_ACCOUNT_ROUTE,
    CREATE_ADMIN_ACCOUNT,
    CREATE_ADMIN_ACCOUNT_SUCCESS,
    CREATE_ADMIN_ACCOUNT_FAILURE,
    CLEAR_CREATE_ADMIN_STATE,
    CREATE_ADMIN_INDEX_ROUTE,
    INITIALIZE_CREATE_ADMIN_PAGE,
    INITIALIZE_CREATE_ADMIN_PAGE_COMPLETE
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";


export const initializeCreateAdminPage = (access_token, client, uid, history) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_CREATE_ADMIN_PAGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.get(CREATE_ADMIN_INDEX_ROUTE, config)
            .then(response => {

                const data = response.data;

                const admin_roles = data.admin_roles;

                console.log(admin_roles);

                dispatch({type: INITIALIZE_CREATE_ADMIN_PAGE_COMPLETE, payload: admin_roles});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};

export const clearCreateAdminState = () => {

    return{
      type: CLEAR_CREATE_ADMIN_STATE
    };

};

export const createAdmin = (access_token, client, uid, history, full_name, email, password, roles) => {

    return(dispatch) => {

        dispatch({type: CREATE_ADMIN_ACCOUNT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = new FormData();

        let data = {
            full_name: full_name,
            email: email,
            password: password
        };

        data.roles = JSON.stringify(roles);

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });



        axios.post(CREATE_ADMIN_ACCOUNT_ROUTE, bodyFormData, config)
            .then((response) => {

                const data = response.data;

                const success = data.success;

                if(success){

                    dispatch({type: CREATE_ADMIN_ACCOUNT_SUCCESS, payload: 'Successfully created account'});

                }else{

                    const message = data.message;

                    dispatch({type: CREATE_ADMIN_ACCOUNT_FAILURE, payload: message});

                }



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};