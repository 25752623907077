import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form, FormControl, Table, Accordion, ButtonGroup, Dropdown, Breadcrumb} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeCategoriesPage,
    clearCategoriesPageState,
    createCategory,
    clearCategoryModalMessages,
    searchCategories,
    createSubcategory,
    changeCategoryName,
    hideCategory
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";


class Categories extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const category_name = "";

        const category_modal_visible = false;

        const category_modal_action = null; // 0: create category, 1: create subcategory, 2: edit name

        const category_id = null;

        const hide_category_modal_visible = false;

        const search_term = "";

        const has_searched = false;

        this.state = {
            history,
            params,
            location,
            width,
            height,
            category_name,
            category_modal_visible,
            category_modal_action,
            category_id,
            search_term,
            has_searched,
            hide_category_modal_visible
        };

    }

    componentWillUnmount(){

        this.props.clearCategoriesPageState();

    }

    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeCategoriesPage
        } = this.props;

        const { history } = this.state;

        if(!logged_in){

            history.push("/");

        }else{

            if(!roles.includes("root_admin") && !roles.includes("lister")){

                history.push("/home");

            }else{

                initializeCategoriesPage(access_token, client, uid, history);

            }

        }

    }





    dropDownMenu( category){


        const category_id = category.id;

        const category_name = category.name;

        const { history } = this.state;


        return(

            <Dropdown.Menu>

                <Dropdown.Item
                    onClick={() => {
                        history.push(`/categories/category_id=${category_id}/create_product`);
                    }}
                >
                    Add Products
                </Dropdown.Item>

                <Dropdown.Item
                    onClick={() => {
                        this.createSubcategoryMode(category_id);
                    }}
                >
                    Add Subcategory
                </Dropdown.Item>

                <Dropdown.Item
                    onClick={() => {
                        this.editCategoryNameMode(category_name, category_id)
                    }}
                >
                    Edit Name
                </Dropdown.Item>

                <Dropdown.Item
                    onClick={() => {

                        this.setState({
                            category_id: category_id,
                            hide_category_modal_visible: true
                        });

                    }}
                >
                    Delete Category
                </Dropdown.Item>





            </Dropdown.Menu>

        );



    }

    renderSubcategories(subcategories){

        return _.map(subcategories, (subcategory, index) => {

            const child_subcategories = subcategory.subcategories;

            if(child_subcategories === undefined || _.isEmpty(child_subcategories) ){

                return(

                    <Card
                        key={subcategory.id}
                        style={{
                            marginBottom: '25px',
                            padding: '10px'
                        }}
                    >

                        <div className="category-items-container">

                            <Card.Body>

                        <span style={{fontSize: isMobile ? '16px' : '18px'}}>
                            {subcategory.name}
                        </span>

                            </Card.Body>

                            <Dropdown>

                                <Dropdown.Toggle variant="success" style={{
                                    marginRight: isMobile ? '0px' : '7px'
                                }} >
                                    Options
                                </Dropdown.Toggle>

                                {this.dropDownMenu(subcategory)}


                            </Dropdown>


                        </div>



                    </Card>

                );

            }else{

                return(

                    <Accordion key={subcategory.id}>

                        <Card
                            style={{
                                marginBottom: '20px'
                            }}
                        >

                            <Accordion.Item eventKey={subcategory.id}>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}
                                >

                                    <Accordion.Header
                                        style={{
                                            padding: isMobile ? '10px' : '15px'
                                        }}

                                    >
                                        <span style={{
                                            fontSize: isMobile ? '18px' : '20px',
                                            marginRight: isMobile ? '2px' :  '20px'
                                        }}>
                                            {subcategory.name}
                                        </span>

                                    </Accordion.Header>


                                    <Dropdown>

                                        <Dropdown.Toggle
                                            variant="success"
                                            style={{
                                                marginTop: '20px',
                                                marginRight: isMobile ? '12px' : '20px'
                                            }}
                                        >
                                            Options
                                        </Dropdown.Toggle>

                                        {this.dropDownMenu(subcategory)}

                                    </Dropdown>



                                </div>


                                <Accordion.Body>

                                    {this.renderSubcategories(child_subcategories)}

                                </Accordion.Body>

                            </Accordion.Item>





                        </Card>


                    </Accordion>


                );


            }





        });

    }





    renderCategories(){

        const { categories } = this.props;


        if(_.isEmpty(categories)){

            return(



                <p style={ isMobile ? {
                    marginLeft: '20px'
                } : {
                    fontSize: '20px',
                    textAlign: 'center'
                }}>
                    The category you were looking for was not found
                </p>




            );

        }else{

            return _.map(categories, (category, index)  => {

                const subcategories = category.subcategories;

                if(subcategories !== null && subcategories !== undefined && !_.isEmpty(subcategories)){

                    return(



                        <Card
                            key={index}
                            style={{
                                marginBottom: '20px',
                                width: isMobile ? this.state.width - 60 : this.state.width / 1.5
                            }}
                        >

                            <Accordion.Item eventKey={category.id}>

                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between'
                                    }}
                                    key={index}
                                >

                                    <Accordion.Header
                                        style={{
                                            padding: isMobile ? '10px' : '15px'
                                        }}

                                    >
                                        <span style={{
                                            fontSize: isMobile ? '18px' : '20px',
                                            marginRight: isMobile ? '2px' :  '20px'
                                        }}>
                                            {category.name}
                                        </span>

                                    </Accordion.Header>


                                    <Dropdown>

                                        <Dropdown.Toggle
                                            variant="success"
                                            style={{
                                                marginTop: '20px',
                                                marginRight: isMobile ? '12px' : '20px'
                                            }}
                                        >
                                            Options
                                        </Dropdown.Toggle>

                                        {this.dropDownMenu(category)}

                                    </Dropdown>



                                </div>


                                <Accordion.Body>

                                    {this.renderSubcategories(subcategories)}

                                </Accordion.Body>

                            </Accordion.Item>





                        </Card>








                    );

                }else{

                    return(



                        <Card
                            style={{
                                marginBottom: '20px',
                                width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                                padding: '15px'
                            }}
                            key={index}
                        >


                            <div className="category-items-container">

                                <Card.Body>

                                <span style={{
                                    fontSize: isMobile ? '18px' : '20px'
                                }}>
                                    {category.name}
                                </span>

                                </Card.Body>

                                <Dropdown>

                                    <Dropdown.Toggle variant="success" style={{
                                        marginRight:  isMobile ? '0px' : '7px'
                                    }} >
                                        Options
                                    </Dropdown.Toggle>

                                    {this.dropDownMenu(category)}

                                </Dropdown>



                            </div>


                        </Card>





                    );

                }

            });

        }




    }



    pageContent(){

        const {
            categories,
            searchCategories,
            access_token,
            client,
            uid
        } = this.props;

        const {
            history,
            search_term,
            has_searched
        } = this.state;

        if(_.isEmpty(categories) && !has_searched){

            return(

                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    height: this.state.height / 2
                }}>

                    <p style={ isMobile ? {
                        fontSize: '20px',
                        marginBottom: '30px',
                        textAlign: 'center'
                    } : {
                        fontSize: '20px',
                        marginBottom: '30px'
                    }}>
                        Add products by creating a category
                    </p>

                    <Button
                        variant="success"
                        size="lg"
                        onClick={() => {
                            this.createCategoryMode();
                        }}
                    >
                        Create Category
                    </Button>

                </div>

            );





        }else{

            return(

                <Fragment>

                    <Form style={{
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '3rem'
                    }}>

                        <FormControl
                            type="text"
                            placeholder="search by category name"
                            style={{
                                borderRadius: '20px',
                                width: isMobile ? this.state.width - 60 :  this.state.width / 2.5,
                                fontSize: '18px'
                            }}
                            onChange={(e) => {

                                e.preventDefault();

                                this.setState({search_term: e.target.value});

                                searchCategories(access_token, client, uid, history, e.target.value);

                                if(!has_searched){
                                    this.setState({has_searched: true});
                                }

                            }}
                            onKeyPress={(e) => {
                                if(e.charCode === 13){

                                    e.preventDefault();

                                    searchCategories(access_token, client, uid, history, search_term);

                                    if(!has_searched){
                                        this.setState({has_searched: true});
                                    }



                                }
                            }}
                        />

                    </Form>

                    <Accordion style={ isMobile ? {
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center'
                    } : {
                        marginBottom: '1.5rem'
                    }}>

                        {this.renderCategories()}

                    </Accordion>



                </Fragment>

            );

        }

    }


    modalBody(){

        const { category_modal_loading } = this.props;

        if(category_modal_loading){

            return(


                <div className="spinner-container">

                    <Spinner animation="border" variant="primary" />

                </div>



            );

        }else{

            return(

                <Form onSubmit={(e) => e.preventDefault()}>

                    <Form.Group>


                        <Form.Control
                            placeholder="Category Name"
                            value={this.state.category_name}
                            onChange={(e) => {
                                this.setState({category_name: e.target.value});
                            }}
                        />

                    </Form.Group>




                </Form>

            );

        }


    }


    modalErrorMessage(){

        const { category_modal_error_message } = this.props;

        if(category_modal_error_message){

            return(

                <p style={{
                    color: '#ff0000',
                    textAlign: 'center'
                }}>
                    {category_modal_error_message}
                </p>

            );

        }

    }

    modalSuccessMessage(){

        const { category_modal_success_message } = this.props;

        if(category_modal_success_message.length > 0){

            return(

                <p style={{
                    color: '#228B22',
                    textAlign: 'center'
                }}>
                    {category_modal_success_message}
                </p>

            );

        }

    }


    categoryModalTitle(){

        const { category_modal_action } = this.state;

        if(category_modal_action === 0){

            return "Create Category";

        }else if(category_modal_action === 1){

            return "Create Subcategory";

        }else if(category_modal_action === 2){

            return "Edit Name"

        }

    }

    exitCategoryModal(){

        this.props.clearCategoryModalMessages();

        this.setState({
            category_modal_visible: false,
            category_name: '',
            category_modal_action: null,
            category_id: null
        });

    }

    editCategoryNameMode(name, category_id){

        this.setState({
            category_modal_visible: true,
            category_name: name,
            category_modal_action: 2,
            category_id: category_id
        });

    }

    createCategoryMode(){

        this.setState({
            category_modal_visible: true,
            category_modal_action: 0
        })

    }

    createSubcategoryMode(category_id){

        this.setState({
            category_modal_visible: true,
            category_modal_action: 1,
            category_id: category_id
        })

    }


    exitDeleteCategoryModal(){

        this.setState({
            category_id: null,
            hide_category_modal_visible: false
        })

    }

    deleteCategoryModalBody(){

        const { hide_category_modal_loading } = this.props;

        if(hide_category_modal_loading){

            return(


                <div className="spinner-container">

                    <Spinner animation="border" variant="primary" />

                </div>



            );

        }else{

            return(

                <div>

                    <p style={{
                        fontSize: isMobile ? '16px' : '18px'
                    }}>
                        By deleting a category, it's subcategories and their products (if any) will be deleted
                        and the products of this category will be deleted (if any).
                    </p>

                </div>

            );

        }


    }

    deleteCategoryModal(){

        const {
            category_id,
            history,
            search_term,
            hide_category_modal_visible
        } = this.state;

        const {
            access_token,
            client,
            uid,
            hideCategory
        } = this.props;


        if(hide_category_modal_visible){

            return(
                <Modal
                    size={isMobile ? 'sm' : 'lg'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={hide_category_modal_visible}
                    onHide={() => {
                        this.exitDeleteCategoryModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete Category
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.deleteCategoryModalBody()}

                    </Modal.Body>


                    <Modal.Footer>

                        <Button
                            variant="danger"
                            style={{
                                marginRight: '10px'
                            }}
                            onClick={() => {
                                hideCategory(access_token, client, uid, history, category_id, search_term);
                                this.exitDeleteCategoryModal();
                            }}>
                            Delete
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitDeleteCategoryModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>


            );

        }


    }

    categoryModal(){

        const {
            category_modal_visible,
            history,
            category_name,
            search_term,
            category_modal_action,
            category_id
        } = this.state;


        const {
            createCategory,
            access_token,
            client,
            uid,
            createSubcategory,
            changeCategoryName
        } = this.props;


        if(category_modal_visible){

            return(

                <Modal
                    size={isMobile ? 'sm' : 'lg'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={category_modal_visible}
                    onHide={() => {
                        this.exitCategoryModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.categoryModalTitle()}
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.modalBody()}

                    </Modal.Body>

                    {this.modalErrorMessage()}

                    {this.modalSuccessMessage()}

                    <Modal.Footer>

                        <Button
                            variant="success"
                            style={{
                                marginRight: '10px'
                            }}
                            onClick={() => {

                                if(category_modal_action === 0){

                                    createCategory(access_token, client, uid, history, category_name, search_term);

                                }else if(category_modal_action === 1) {

                                    createSubcategory(access_token, client, uid, history, category_name, category_id, search_term);

                                }else if(category_modal_action === 2){

                                    changeCategoryName(access_token, client, uid, history, category_name, category_id, search_term);

                                }

                            }}>
                            Submit
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitCategoryModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>


            );

        }

    }

    topButtons(){

        const {
            categories
        } = this.props;

        const {
            has_searched,
            history
        } = this.state;


        if(!_.isEmpty(categories) || has_searched){

            return(

                <div style={ isMobile ? {
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '20px'
                } : {
                    marginBottom: '1rem',
                    display: 'flex',
                    justifyContent: 'end',
                    marginLeft: '20px',
                    marginRight: '20px'
                }}>


                    <Breadcrumb>

                        <Breadcrumb.Item
                            onClick={() => {
                                this.createCategoryMode();
                            }}
                        >
                            Create Category
                        </Breadcrumb.Item>

                        <Breadcrumb.Item
                            onClick={() => {
                                history.push("/products");
                            }}
                        >
                            Browse Products
                        </Breadcrumb.Item>

                    </Breadcrumb>
                    
                    
                </div>

            );

        }


    }

    renderBody(){

        const {
            fetching_admin_profile,
            initializing_page
        } = this.props;

        const {
            history,
            params,
            location,
        } = this.state;

        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />


                    {this.topButtons()}

                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <div style={ isMobile ? {
                            display: 'flex',
                            flexDirection: 'column'
                        } : {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>



                            <p style={{
                                fontSize: isMobile ? '30px' : '50px',
                                marginBottom: '20px',
                                textAlign: 'center'
                            }}>
                                Categories
                            </p>

                            {this.pageContent()}

                        </div>

                    </div>

                    {this.categoryModal()}

                    {this.deleteCategoryModal()}

                </div>
            )

        }

    }

    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        initializing_page,
        categories,
        category_modal_loading,
        category_modal_success_message,
        category_modal_error_message,
        hide_category_modal_loading
    } = state.categories;


    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        categories,
        category_modal_loading,
        category_modal_success_message,
        category_modal_error_message,
        hide_category_modal_loading
    };

};

export default connect(mapStateToProps, {
    initializeCategoriesPage,
    clearCategoriesPageState,
    createCategory,
    clearCategoryModalMessages,
    searchCategories,
    createSubcategory,
    changeCategoryName,
    hideCategory
})(Categories);

