import {
    LOGOUT_SUCCESS,
    CLEAR_HOME_MODAL_FORM_STATE,
    ADMIN_UPDATE_OWN_ACCOUNT,
    ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE,
    ADMIN_UPDATE_OWN_ACCOUNT_SUCCESS,
    ADMIN_UPDATE_OWN_ACCOUNT_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
    modal_updating: false,
    modal_error_message: '',
    modal_success_message: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ADMIN_UPDATE_OWN_ACCOUNT_FAILURE:
            return{
                ...state,
                modal_error_message: action.payload
            };
        case ADMIN_UPDATE_OWN_ACCOUNT_SUCCESS:
            return{
                ...state,
                modal_success_message: action.payload
            };
        case ADMIN_UPDATE_OWN_ACCOUNT_COMPLETE:
            return{
                ...state,
                modal_updating: false
            };
        case ADMIN_UPDATE_OWN_ACCOUNT:
            return{
                ...state,
                modal_updating: true,
                modal_error_message: '',
                modal_success_message: ''
            };
        case CLEAR_HOME_MODAL_FORM_STATE:
            return{
                ...state,
                modal_updating: false,
                modal_error_message: '',
                modal_success_message: ''
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};