import {
    LOGOUT_SUCCESS,
    EDIT_ADMIN_INDEX_ROUTE,
    UPDATE_ADMIN_ACCOUNT_FULL_NAME_ROUTE,
    UPDATE_ADMIN_ACCOUNT_EMAIL_ROUTE,
    UPDATE_ADMIN_ACCOUNT_PASSWORD_ROUTE,
    UPDATE_ADMIN_ACCOUNT_ROLES_ROUTE,
    INITIALIZE_EDIT_ADMIN_PAGE,
    INITIALIZE_EDIT_ADMIN_PAGE_COMPLETE,
    CLEAR_EDIT_ADMIN_STATE,
    CHANGE_ADMIN_ACCOUNT_FULL_NAME,
    CHANGE_ADMIN_ACCOUNT_EMAIL,
    CHANGE_ADMIN_ACCOUNT_PASSWORD,
    CHANGE_ADMIN_ACCOUNT_ROLES,
    UPDATE_ADMIN_ACCOUNT,
    UPDATE_ADMIN_ACCOUNT_COMPLETE,
    UPDATE_ADMIN_ADMIN_ACCOUNT_FULL_NAME_ERROR,
    UPDATE_ADMIN_ACCOUNT_EMAIL_ERROR,
    UPDATE_ADMIN_ACCOUNT_PASSWORD_ERROR,
    UPDATE_ADMIN_ACCOUNT_ROLES_ERROR,
    ADMIN_ACCOUNT_CHANGED,
    FULL_NAME_UPDATE_SUCCESS,
    EMAIL_UPDATE_SUCCESS,
    PASSWORD_UPDATE_SUCCESS,
    ROLES_UPDATE_SUCCESS
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

// clear edit admin state in component when component will Unmount

export const updateAdminAccount = (access_token, client, uid, history, id, full_name, email, password, admin_roles, account) => {

    return(dispatch) => {

        dispatch({type: UPDATE_ADMIN_ACCOUNT});

        full_name = full_name.trim();

        email = email.trim();

        password = password.trim();

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };



        if(!_.isEmpty(password)){

            console.log("change password");

            let passwordFormData = getFormData({
                id: id,
                password: password
            });

            axios.post(UPDATE_ADMIN_ACCOUNT_PASSWORD_ROUTE, passwordFormData, config)
                .then((response) => {

                    const data = response.data;

                    const success = data.success;

                    if(success){

                        const account = data.account;

                        dispatch({type: ADMIN_ACCOUNT_CHANGED, payload: account});

                        dispatch({type: CHANGE_ADMIN_ACCOUNT_PASSWORD, payload: ''});

                        dispatch({type: PASSWORD_UPDATE_SUCCESS});



                    }else{

                        const message = data.message;

                        dispatch({type: UPDATE_ADMIN_ACCOUNT_PASSWORD_ERROR, payload: message});


                    }


                }).catch(error => {

                dispatch({type: LOGOUT_SUCCESS});

                history.push("/");

            });



        }


        if(full_name !== account.full_name ){

            console.log("change full name");

            let nameFormData = getFormData({
                id: id,
                full_name: full_name
            });

            axios.post(UPDATE_ADMIN_ACCOUNT_FULL_NAME_ROUTE, nameFormData, config)
                .then((response) => {

                    const data = response.data;

                    const success = data.success;

                    if(success){

                        const account = data.account;

                        dispatch({type: ADMIN_ACCOUNT_CHANGED, payload: account});

                        dispatch({type: FULL_NAME_UPDATE_SUCCESS});


                    }else{

                        const message = data.message;

                        dispatch({type: UPDATE_ADMIN_ADMIN_ACCOUNT_FULL_NAME_ERROR, payload: message});


                    }


                }).catch(error => {


                dispatch({type: LOGOUT_SUCCESS});

                history.push("/");

            });


        }

        if(email !== account.email ){

            console.log("change email");

            let emailFormData = getFormData({
                id: id,
                email: email
            });

            axios.post(UPDATE_ADMIN_ACCOUNT_EMAIL_ROUTE, emailFormData, config)
                .then((response) => {

                    const data = response.data;

                    const success = data.success;

                    if(success){

                        const account = data.account;

                        dispatch({type: ADMIN_ACCOUNT_CHANGED, payload: account});

                        dispatch({type: EMAIL_UPDATE_SUCCESS});


                    }else{

                        const message = data.message;

                        dispatch({type: UPDATE_ADMIN_ACCOUNT_EMAIL_ERROR, payload: message});


                    }


                }).catch(error => {

                dispatch({type: LOGOUT_SUCCESS});

                history.push("/");

            });


        }



        if(admin_roles !== account.admin_roles ){



            console.log("change roles");

            let rolesFormData = getFormData({
                id: id,
                roles: JSON.stringify(admin_roles)
            });

            axios.post(UPDATE_ADMIN_ACCOUNT_ROLES_ROUTE, rolesFormData, config)
                .then((response) => {

                    const data = response.data;

                    const success = data.success;

                    if(success){

                        const account = data.account;

                        dispatch({type: ADMIN_ACCOUNT_CHANGED, payload: account});

                        dispatch({type: ROLES_UPDATE_SUCCESS});

                    }else{

                        const message = data.message;

                        dispatch({type: UPDATE_ADMIN_ACCOUNT_ROLES_ERROR, payload: message});


                    }


                }).catch(error => {


                dispatch({type: LOGOUT_SUCCESS});

                history.push("/");

            });


        }



        dispatch({type: UPDATE_ADMIN_ACCOUNT_COMPLETE});




    }



};

export const clearEditAdminState = () => {

    return{
        type: CLEAR_EDIT_ADMIN_STATE
    };

};


export const changeAdminAccountRoles = (admin_roles) => {

    return{
        type: CHANGE_ADMIN_ACCOUNT_ROLES,
        payload: admin_roles
    };

};


export const changeAdminAccountPassword = (password) => {

    return{
        type: CHANGE_ADMIN_ACCOUNT_PASSWORD,
        payload: password
    }

};

export const changeAdminAccountEmail = (email) => {

    return{
        type: CHANGE_ADMIN_ACCOUNT_EMAIL,
        payload: email
    }

};


export const changeAdminAccountFullName = (full_name) => {

    return{
        type: CHANGE_ADMIN_ACCOUNT_FULL_NAME,
        payload: full_name
    }

};


export const initializeEditAdminAccountPage = (access_token, client, uid, history, id) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_EDIT_ADMIN_PAGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: id
        });

        axios.post(EDIT_ADMIN_INDEX_ROUTE, bodyFormData, config)
            .then((response) => {

                const data = response.data;

                const success = data.success;

                if(success){

                    const account = data.account;

                    const available_roles = data.available_roles;

                    // console.log(account);
                    //
                    // console.log(available_roles);

                    dispatch({type: INITIALIZE_EDIT_ADMIN_PAGE_COMPLETE, payload: {
                        account: account,
                        available_roles: available_roles
                    }});

                    // save full name, email, roles to redux from account object


                }else{


                    history.push("/home");

                }


            }).catch(error => {


            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};