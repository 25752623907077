import {
    LOGOUT_SUCCESS,
    INITIALIZE_CATEGORIES_PAGE,
    INITIALIZE_CATEGORIES_PAGE_COMPLETE,
    CLEAR_CATEGORIES_PAGE_STATE,
    CREATE_CATEGORY,
    CREATE_CATEGORY_COMPLETE,
    CREATE_CATEGORY_SUCCESS,
    CREATE_CATEGORY_FAILURE,
    CLEAR_CATEGORY_MODAL_MESSAGES,
    SEARCH_CATEGORIES,
    SEARCH_CATEGORIES_COMPLETE,
    CREATE_SUBCATEGORY,
    CREATE_SUBCATEGORY_COMPLETE,
    CREATE_SUBCATEGORY_SUCCESS,
    CREATE_SUBCATEGORY_FAILURE,
    CHANGE_CATEGORY_NAME,
    CHANGE_CATEGORY_NAME_COMPLETE,
    CHANGE_CATEGORY_NAME_SUCCESS,
    CHANGE_CATEGORY_NAME_FAILURE
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    categories: [],
    category_modal_loading: false,
    category_modal_success_message: '',
    category_modal_error_message: '',
    searching_category: false,
    hide_category_modal_loading: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CHANGE_CATEGORY_NAME_FAILURE:
            return{
                ...state,
                category_modal_error_message: action.payload
            };
        case CHANGE_CATEGORY_NAME_SUCCESS:
            return{
                ...state,
                category_modal_success_message: 'Successfully Changed Name'
            };
        case CHANGE_CATEGORY_NAME_COMPLETE:
            return{
                ...state,
                category_modal_loading: false
            };
        case CHANGE_CATEGORY_NAME:
            return{
                ...state,
                category_modal_loading: true,
                category_modal_success_message: '',
                category_modal_error_message: ''
            };
        case CREATE_SUBCATEGORY_FAILURE:
            return{
                ...state,
                category_modal_error_message: action.payload
            };
        case CREATE_SUBCATEGORY_SUCCESS:
            return{
                ...state,
                category_modal_success_message: 'Successfully Created Subcategory'
            };
        case CREATE_SUBCATEGORY_COMPLETE:
            return{
                ...state,
                category_modal_loading: false
            };
        case CREATE_SUBCATEGORY:
            return{
                ...state,
                category_modal_loading: true,
                category_modal_success_message: '',
                category_modal_error_message: ''
            };
        case SEARCH_CATEGORIES_COMPLETE:
            return{
                ...state,
                searching_category: false,
                categories: action.payload
            };
        case SEARCH_CATEGORIES:
            return{
                ...state,
                searching_category: true
            };
        case CLEAR_CATEGORY_MODAL_MESSAGES:
            return{
                ...state,
                category_modal_success_message: '',
                category_modal_error_message: ''
            };
        case CREATE_CATEGORY_FAILURE:
            return{
                ...state,
                category_modal_error_message: action.payload
            };
        case CREATE_CATEGORY_SUCCESS:
            return{
                ...state,
                category_modal_success_message: 'Successfully Created Category'
            };
        case CREATE_CATEGORY_COMPLETE:
            return{
                ...state,
                category_modal_loading: false
            };
        case CREATE_CATEGORY:
            return{
                ...state,
                category_modal_loading: true,
                category_modal_success_message: '',
                category_modal_error_message: ''
            };
        case INITIALIZE_CATEGORIES_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false,
                categories: action.payload
            };
        case INITIALIZE_CATEGORIES_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_CATEGORIES_PAGE_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return {
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};