import {
    GET_ADMIN_PROFILE_ROUTE,
    ADMIN_ACCOUNT_FULL_NAME_CHANGED,
    ADMIN_ACCOUNT_UID_CHANGED,
    ADMIN_ACCOUNT_ROLES_CHANGED,
    GET_ADMIN_PROFILE,
    GET_ADMIN_PROFILE_COMPLETE,
    LOGOUT_SUCCESS,
    ADMIN_ACCOUNTS_INDEX_ROUTE,
    INITIALIZE_ADMIN_ACCOUNTS_PAGE,
    INITIALIZE_ADMIN_ACCOUNTS_PAGE_COMPLETE,
    SEARCH_ADMIN_ACCOUNTS_ROUTE,
    SEARCH_ADMIN_ACCOUNTS,
    SEARCH_ADMIN_ACCOUNTS_COMPLETE,
    DESTROY_ADMIN_ACCOUNT_ROUTE,
    DESTROY_ADMIN_ACCOUNT
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const destroyAdminAccount = (access_token, client, uid, history, id, admin_accounts) => {

    return(dispatch) => {



        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: id
        });

        axios.post(DESTROY_ADMIN_ACCOUNT_ROUTE, bodyFormData, config)
            .then(response => {

                const new_admin_accounts = admin_accounts.filter(object => {
                    return object.id !== id;
                });

                console.log(new_admin_accounts);

                dispatch({type: DESTROY_ADMIN_ACCOUNT, payload: new_admin_accounts});

            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };

};

export const searchAdminAccounts = (access_token, client, uid, history, search_term, role) => {

    return(dispatch) => {

        dispatch({type: SEARCH_ADMIN_ACCOUNTS});

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };


        let bodyFormData = new FormData();

        let form_data = {};

        form_data.search_term = search_term;

        if(role !== null){

            form_data.role = role;

        }

        _.each(form_data, (value, key) => {

            bodyFormData.append(key, value);

        });

        axios.post(SEARCH_ADMIN_ACCOUNTS_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data;

                const admin_accounts = data.admin_accounts;

                dispatch({type: SEARCH_ADMIN_ACCOUNTS_COMPLETE, payload: admin_accounts});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const initializeAdminAccountsPage = (access_token, client, uid, history) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_ADMIN_ACCOUNTS_PAGE});

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.get(ADMIN_ACCOUNTS_INDEX_ROUTE, config)
            .then(response => {

                const data = response.data;

                const admin_accounts = data.admin_accounts;

                const admin_roles = data.admin_roles;

                console.log("fetched admin accounts");

                console.log(admin_accounts);

                console.log(admin_roles);

                dispatch({type: INITIALIZE_ADMIN_ACCOUNTS_PAGE_COMPLETE, payload: {
                    admin_accounts: admin_accounts,
                    admin_roles: admin_roles
                }});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });




    };

};


export const getAdminProfile = (access_token, client, uid, history) => {

    return(dispatch) => {

        dispatch({type: GET_ADMIN_PROFILE});

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.get(GET_ADMIN_PROFILE_ROUTE, config)
            .then(response => {

                const data = response.data;

                // console.log("get admin profile");
                //
                // console.log(data);

                const full_name = data.full_name;

                const roles = data.roles;

                const uid = data.uid;

                dispatch({type: GET_ADMIN_PROFILE_COMPLETE});

                dispatch({type: ADMIN_ACCOUNT_FULL_NAME_CHANGED, payload: full_name});

                dispatch({type: ADMIN_ACCOUNT_ROLES_CHANGED, payload: roles});

                dispatch({type: ADMIN_ACCOUNT_UID_CHANGED, payload: uid});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });






    };

};