import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
import { LOGOUT_SUCCESS } from "../actions/types";

import LoginReducer from "./LoginReducer";
import AdminAccountsReducer from "./AdminAccountsReducer"
import ResendUnlockReducer from "./ResendUnlockReducer";
import ForgotPasswordReducer from "./ForgotPasswordReducer";
import ResetPasswordReducer from "./ResetPasswordReducer";
import HomeReducer from "./HomeReducer";
import CreateAdminReducer from "./CreateAdminReducer";
import EditAdminAccountReducer from "./EditAdminAccountReducer";
import CategoriesReducer from "./CategoriesReducer";
import ProductsReducer from "./ProductsReducer";
import CreateProductReducer from "./CreateProductReducer";
import EditProductReducer from "./EditProductReducer";
import CustomerAccountsReducer from "./CustomerAccountsReducer";
import EditCustomerReducer from "./EditCustomerReducer";
import ViewCustomerPageReducer from "./ViewCustomerPageReducer";
import LandingPageImagesReducer from "./LandingPageImagesReducer";

const appReducer = combineReducers({
    login: LoginReducer,
    admin_accounts: AdminAccountsReducer,
    resend_unlock: ResendUnlockReducer,
    forgot_password: ForgotPasswordReducer,
    reset_password: ResetPasswordReducer,
    home: HomeReducer,
    create_admin: CreateAdminReducer,
    edit_admin: EditAdminAccountReducer,
    categories: CategoriesReducer,
    products: ProductsReducer,
    create_product: CreateProductReducer,
    edit_product: EditProductReducer,
    customer_accounts: CustomerAccountsReducer,
    edit_customer: EditCustomerReducer,
    view_customer: ViewCustomerPageReducer,
    landing_page_images: LandingPageImagesReducer
});

const rootReducer = ( state, action ) => {

    if ( action.type === LOGOUT_SUCCESS ) {

        storage.removeItem('login:access_token').then(() => console.log("access_token removed"));

        storage.removeItem('login:client').then(() => console.log("client removed"));

        storage.removeItem('login:uid').then(() => console.log("uid removed"));

        storage.removeItem('login:logged_in').then(() => console.log("logged_in removed"));

        storage.removeItem('login:roles').then(() => console.log("roles removed"));

        storage.removeItem('login:id').then(() => console.log("id removed"));

        storage.removeItem('login:full_name').then(() => console.log("full_name removed"));

    }

    return appReducer(state, action);

};

export default rootReducer;

