import {
    LOGOUT_SUCCESS,
    CLEAR_CREATE_PRODUCT_STATE,
    CREATE_PRODUCT_ROUTE,
    CREATE_PRODUCT,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    HIDE_CREATE_PRODUCT_MODAL,
    CREATE_PRODUCT_NAME_CHANGED,
    CREATE_PRODUCT_DESCRIPTION_CHANGED,
    CREATE_PRODUCT_UPC_CHANGED,
    CREATE_PRODUCT_SKU_CHANGED,
    CREATE_PRODUCT_PICTURES_CHANGED,
    CREATE_PRODUCT_TOPIC_CHANGED,
    CREATE_PRODUCT_ATTRIBUTES_CHANGED,
    CLEAR_CREATE_PRODUCT_FORM,
    CREATE_PRODUCT_BRAND_NAME_CHANGED,
    CREATE_PRODUCT_PRICE_LIST_VISIBILITY_CHANGED,
    CREATE_PRODUCT_PACK_SIZE_CHANGED,
    CREATE_PRODUCT_PRICE_RANGES_CHANGED,
    CREATE_PRODUCT_QUANTITY_CHANGED
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const createProductQuantityChanged = (quantity) => {

    return{
      type: CREATE_PRODUCT_QUANTITY_CHANGED,
      payload: quantity
    };

};

export const createProductPriceRangesChanged = (price_ranges) => {

    return{
      type: CREATE_PRODUCT_PRICE_RANGES_CHANGED,
      payload: price_ranges
    };

};

export const createProductPackSizeChanged = (pack_size) => {

    return{
      type: CREATE_PRODUCT_PACK_SIZE_CHANGED,
      payload: pack_size
    };

};

export const createProductPriceListVisibilityChanged = (price_list_form_visible) => {

    return{
      type: CREATE_PRODUCT_PRICE_LIST_VISIBILITY_CHANGED,
      payload: price_list_form_visible
    };

};



export const createProductBrandNameChanged = (brand_name) => {

    return{
      type: CREATE_PRODUCT_BRAND_NAME_CHANGED,
      payload: brand_name
    };

};

export const createProductAttributesChanged = (product_attributes) => {

    let new_product_attributes = _.cloneDeep(product_attributes);

    return{
        type: CREATE_PRODUCT_ATTRIBUTES_CHANGED,
        payload: new_product_attributes
    };


};


export const createProductTopicChanged = (topic_name) => {

    return{
        type: CREATE_PRODUCT_TOPIC_CHANGED,
        payload: topic_name
    };

};

export const createProductPicturesChanged = (pictures) => {

    let new_pictures = _.cloneDeep(pictures);

    return{
        type: CREATE_PRODUCT_PICTURES_CHANGED,
        payload: new_pictures
    };

};

export const createProductSkuChanged = (sku) => {

    return{
        type: CREATE_PRODUCT_SKU_CHANGED,
        payload: sku
    };

};

export const createProductUpcChanged = (upc) => {

    return{
        type: CREATE_PRODUCT_UPC_CHANGED,
        payload: upc
    };

};

export const createProductDescriptionChanged = (description) => {

    return{
        type: CREATE_PRODUCT_DESCRIPTION_CHANGED,
        payload: description
    };

};

export const createProductNameChanged = (name) => {

    return{
        type: CREATE_PRODUCT_NAME_CHANGED,
        payload: name
    };

};


export const hideCreateProductModal = () => {

    return{
        type: HIDE_CREATE_PRODUCT_MODAL
    }

};


export const clearCreateProductState = () => {

    return{
        type: CLEAR_CREATE_PRODUCT_STATE
    }

};


export const createProduct = (
    access_token,
    client,
    uid,
    history,
    category_id,
    name,
    description,
    upc,
    sku,
    pictures,
    topic_name,
    product_attributes,
    brand_name,
    price_list,
    quantity
) => {

    return(dispatch) => {

        dispatch({type: CREATE_PRODUCT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json",
                'Content-Type': 'multipart/form-data'
            }
        };

        let bodyFormData = new FormData();

        let data = {
            category_id: category_id,
            name: name,
            description: description,
            upc: upc,
            sku: sku
        };



        if(pictures.length > 0){

            pictures[0].dataURL.includes("base64") ? data.isBase64 = true : data.isBase64 = false;

            for(let i = 0; i < pictures.length; i++) {

                if(data.isBase64){

                    let file = {};

                    file.uri = pictures[i].dataURL;

                    file.name = pictures[i].file.name;

                    file.type = pictures[i].file.type;

                    file = JSON.stringify(file);

                    bodyFormData.append('pictures[]', file);


                }else{

                    let file = pictures[i];

                    bodyFormData.append('pictures[]', file);

                }


            }



        }



        if(!_.isEmpty(topic_name)){

            data.topic_name = topic_name;

        }

        if (!_.isEmpty(product_attributes)) {

            data.product_attributes = JSON.stringify(product_attributes);

        }

        if(!_.isEmpty(brand_name)){

            data.brand_name = brand_name;

        }

        if(!_.isEmpty(price_list)){

            data.price_list = JSON.stringify(price_list);

        }

        if(!_.isEmpty(quantity)){

            data.quantity = quantity;

        }

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });


        axios.post(CREATE_PRODUCT_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data;

                const success = data.success;

                const message = data.message;

                if(success){

                    dispatch({type: CREATE_PRODUCT_SUCCESS, payload: message});

                    dispatch({type: CLEAR_CREATE_PRODUCT_FORM});

                }else{

                    dispatch({type: CREATE_PRODUCT_FAILURE, payload: message});

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};