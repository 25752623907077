import {
    LOGOUT_SUCCESS,
    INITIALIZE_EDIT_CUSTOMER_PAGE_ROUTE,
    INITIALIZE_EDIT_CUSTOMER_PAGE,
    INITIALIZE_EDIT_CUSTOMER_PAGE_COMPLETE,
    CLEAR_EDIT_CUSTOMER_PAGE_STATE,
    EDIT_CUSTOMER_EMAIL_CHANGED,
    EDIT_CUSTOMER_FULL_NAME_CHANGED,
    EDIT_CUSTOMER_COMPANY_NAME_CHANGED,
    EDIT_CUSTOMER_COMPANY_ADDRESS_CHANGED,
    EDIT_CUSTOMER_COMPANY_STATE_CHANGED,
    EDIT_CUSTOMER_COMPANY_CITY_CHANGED,
    EDIT_CUSTOMER_COMPANY_ZIP_CODE_CHANGED,
    EDIT_CUSTOMER_ACCOUNT_APPROVED_CHANGED,
    EDIT_CUSTOMER_ACCOUNT_PASSWORD_CHANGED,
    EDIT_CUSTOMER_ACCOUNT_CHANGED,
    EDIT_CUSTOMER_COMPANY_STATE_CODE_CHANGED,
    UPDATE_CUSTOMER_ACCOUNT_ROUTE,
    UPDATE_CUSTOMER_ACCOUNT,
    UPDATE_CUSTOMER_ACCOUNT_COMPLETE,
    OPEN_EDIT_CUSTOMER_ACCOUNT_MODAL,
    CLOSE_EDIT_CUSTOMER_ACCOUNT_MODAL,
    REPLACE_SELLER_PERMIT_ROUTE,
    REPLACE_SELLER_PERMIT,
    OPEN_RESELLER_PERMIT_MODAL,
    CLOSE_RESELLER_PERMIT_MODAL,
    REPLACE_SELLER_PERMIT_ERROR,
    REPLACE_SELLER_PERMIT_COMPLETE
} from "./types";

import axios from "axios";
import { getFormData, getUsaStateCode } from "../helpers";

export const replaceSellerPermit = (
    access_token,
    client,
    uid,
    history,
    customer_id,
    seller_permit
) => {

    return(dispatch) => {

        dispatch({type: REPLACE_SELLER_PERMIT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json",
                "Content-Type": "multipart/form-data"
            }
        };

        let bodyFormData = getFormData({
            seller_permit: seller_permit,
            customer_id: customer_id
        });

        axios.post(REPLACE_SELLER_PERMIT_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: REPLACE_SELLER_PERMIT_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const customer = data.customer;

                    console.log(customer);

                    const email = customer.email;

                    const full_name = customer.full_name;

                    const company_name = customer.company_name;

                    const company_address = customer.company_address;

                    const company_state = customer.company_state;

                    const company_city = customer.company_city;

                    const company_zip_code = customer.company_zip_code;

                    const account_approved = customer.account_approved;

                    const state_code = getUsaStateCode(company_state);

                    const seller_permit_url = customer.seller_permit_url;




                    dispatch({type: EDIT_CUSTOMER_ACCOUNT_CHANGED , payload: {
                        email: email,
                        full_name: full_name,
                        company_name: company_name,
                        company_address: company_address,
                        company_state: company_state,
                        company_city: company_city,
                        company_zip_code: company_zip_code,
                        account_approved: account_approved,
                        state_code: state_code,
                        seller_permit_url: seller_permit_url
                    }});

                    dispatch({type: CLOSE_RESELLER_PERMIT_MODAL});


                }else{

                    const message = data.message;

                    dispatch({type: REPLACE_SELLER_PERMIT_ERROR, payload: message});


                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });




    };


};

export const closeResellerPermitModal = () => {

    return{
        type: CLOSE_RESELLER_PERMIT_MODAL
    };

};


export const openResellerPermitModal = () => {

    return{
        type: OPEN_RESELLER_PERMIT_MODAL
    };

};



export const closeEditCustomerAccountModal = () => {

    return{
        type: CLOSE_EDIT_CUSTOMER_ACCOUNT_MODAL
    };

};

export const updateCustomerAccount = (
    access_token,
    client,
    uid,
    history,
    customer_id,
    email,
    full_name,
    company_name,
    company_address,
    company_state,
    company_city,
    company_zip_code,
    account_approved,
    password
) => {

    return(dispatch) => {

        dispatch({type: UPDATE_CUSTOMER_ACCOUNT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            customer_id: customer_id,
            email: email,
            full_name: full_name,
            company_name: company_name,
            company_address: company_address,
            company_state: company_state,
            company_city: company_city,
            company_zip_code: company_zip_code,
            account_approved: account_approved,
            password: password
        });

        axios.post(UPDATE_CUSTOMER_ACCOUNT_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: UPDATE_CUSTOMER_ACCOUNT_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const customer = data.customer;

                    console.log(customer);

                    const email = customer.email;

                    const full_name = customer.full_name;

                    const company_name = customer.company_name;

                    const company_address = customer.company_address;

                    const company_state = customer.company_state;

                    const company_city = customer.company_city;

                    const company_zip_code = customer.company_zip_code;


                    const account_approved = customer.account_approved;

                    const state_code = getUsaStateCode(company_state);

                    const seller_permit_url = customer.seller_permit_url;


                    dispatch({type: EDIT_CUSTOMER_ACCOUNT_CHANGED , payload: {
                        email: email,
                        full_name: full_name,
                        company_name: company_name,
                        company_address: company_address,
                        company_state: company_state,
                        company_city: company_city,
                        company_zip_code: company_zip_code,
                        account_approved: account_approved,
                        state_code: state_code,
                        seller_permit_url: seller_permit_url
                    }});



                }else{

                    const message = data.message;

                    dispatch({type: OPEN_EDIT_CUSTOMER_ACCOUNT_MODAL, payload: message});

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const editCustomerAccountPasswordChanged = (password) => {

    return{
        type: EDIT_CUSTOMER_ACCOUNT_PASSWORD_CHANGED,
        payload: password
    };
};

export const editCustomerAccountApprovedChanged = (account_approved) => {

    return{
        type: EDIT_CUSTOMER_ACCOUNT_APPROVED_CHANGED,
        payload: account_approved
    };

};



export const editCustomerCompanyZipCodeChanged = (company_zip_code) => {

    return{
        type: EDIT_CUSTOMER_COMPANY_ZIP_CODE_CHANGED,
        payload: company_zip_code
    };

};


export const editCustomerCompanyStateCodeChanged = (state_code) => {

    return{
        type: EDIT_CUSTOMER_COMPANY_STATE_CODE_CHANGED,
        payload: state_code
    };

};

export const editCustomerCompanyCityChanged = (company_city) => {

    return{
        type: EDIT_CUSTOMER_COMPANY_CITY_CHANGED,
        payload: company_city
    };

};

export const editCustomerCompanyStateChanged = (company_state) => {

    return{
        type: EDIT_CUSTOMER_COMPANY_STATE_CHANGED,
        payload: company_state
    };

};

export const editCustomerCompanyAddressChanged = (company_address) => {

    return{
        type: EDIT_CUSTOMER_COMPANY_ADDRESS_CHANGED,
        payload: company_address
    };

};

export const editCustomerCompanyNameChanged = (company_name) => {

    return{
        type: EDIT_CUSTOMER_COMPANY_NAME_CHANGED,
        payload: company_name
    };

};

export const editCustomerFullNameChanged = (full_name) => {

    return{
        type: EDIT_CUSTOMER_FULL_NAME_CHANGED,
        payload: full_name
    };

};


export const editCustomerEmailChanged = (email) => {

    return{
        type: EDIT_CUSTOMER_EMAIL_CHANGED,
        payload: email
    };

};

export const clearEditCustomerPageState = () => {

    return{
        type: CLEAR_EDIT_CUSTOMER_PAGE_STATE
    };

};

export const initializeEditCustomerPage = (access_token, client, uid, history, customer_id) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_EDIT_CUSTOMER_PAGE});


        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };


        let bodyFormData = getFormData({
            customer_id: customer_id
        });

        axios.post(INITIALIZE_EDIT_CUSTOMER_PAGE_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: INITIALIZE_EDIT_CUSTOMER_PAGE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const customer = data.customer;

                    console.log(customer);

                    const email = customer.email;

                    const full_name = customer.full_name;

                    const company_name = customer.company_name;

                    const company_address = customer.company_address;

                    const company_state = customer.company_state;

                    const company_city = customer.company_city;

                    const company_zip_code = customer.company_zip_code;

                    const account_approved = customer.account_approved;

                    const state_code = getUsaStateCode(company_state);

                    const seller_permit_url = customer.seller_permit_url;




                    dispatch({type: EDIT_CUSTOMER_ACCOUNT_CHANGED , payload: {
                        email: email,
                        full_name: full_name,
                        company_name: company_name,
                        company_address: company_address,
                        company_state: company_state,
                        company_city: company_city,
                        company_zip_code: company_zip_code,
                        account_approved: account_approved,
                        state_code: state_code,
                        seller_permit_url: seller_permit_url
                    }});


                }else{

                    history.push("/customer-accounts");

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });




    };

};