import {
    RESET_PASSWORD_ROUTE,
    RESET_PASSWORD,
    RESET_PASSWORD_COMPLETE,
    CLEAR_RESET_PASSWORD_STATE,
    RESET_PASSWORD1_CHANGED,
    RESET_PASSWORD2_CHANGED,
    RESET_PASSWORD_FORM_ERROR_CHANGED,
    LOGIN_ADMIN_SUCCESS
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const resetPassword2Changed = (password2) => {

    return{
        type: RESET_PASSWORD2_CHANGED,
        payload: password2
    };

};

export const resetPassword1Changed = (password1) => {

    return{
        type: RESET_PASSWORD1_CHANGED,
        payload: password1
    };

};


export const clearResetPasswordState = () => {

    return{
        type: CLEAR_RESET_PASSWORD_STATE
    };

};

export const resetPassword = (access_token, client, uid, reset_password_token, history, password, password_confirmation) => {

    return(dispatch) => {

        dispatch({type: RESET_PASSWORD});

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        password = password.trim();

        password_confirmation = password_confirmation.trim();

        if(_.isEmpty(password) || _.isEmpty(password_confirmation)){

            dispatch({type: RESET_PASSWORD_COMPLETE});

            dispatch({type: RESET_PASSWORD_FORM_ERROR_CHANGED, payload: 'Passwords cannot be blank'});

        }else if(password.length < 8 || password_confirmation.length < 8){

            dispatch({type: RESET_PASSWORD_COMPLETE});

            dispatch({type: RESET_PASSWORD_FORM_ERROR_CHANGED, payload: 'Passwords must be at least 8 characters long'});

        }else if(password !== password_confirmation){

            dispatch({type: RESET_PASSWORD_COMPLETE});

            dispatch({type: RESET_PASSWORD_FORM_ERROR_CHANGED, payload: 'Passwords do not match'});

        }else{

            // in then and catch clause of axios path request dispatch reset password complete

            let bodyFormData = getFormData({
                password: password,
                password_confirmation: password_confirmation,
                reset_password_token: reset_password_token
            });

            axios.patch(RESET_PASSWORD_ROUTE, bodyFormData, config)
                .then(response => {

                    dispatch({type: RESET_PASSWORD_COMPLETE});

                    const data = response.data.data;

                    console.log(data);

                    const roles = data.roles;

                    const id = data.id;

                    const full_name = data.full_name;

                    const headers = response.headers;

                    const access_token = headers["access-token"];

                    const client = headers["client"];

                    const uid = headers["uid"];

                    dispatch({type: LOGIN_ADMIN_SUCCESS, payload: {
                        access_token: access_token,
                        client: client,
                        uid: uid,
                        roles: roles,
                        id: id,
                        full_name: full_name
                    }});

                    history.push("/home");


                }).catch(error => {

                dispatch({type: RESET_PASSWORD_COMPLETE});

                history.push("/");

            });




        }






    };

};