import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeEditAdminAccountPage,
    changeAdminAccountFullName,
    changeAdminAccountEmail,
    changeAdminAccountPassword,
    changeAdminAccountRoles,
    clearEditAdminState,
    updateAdminAccount
} from "../actions";
import Wrapper from "./Wrapper";


class EditAdmin extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const admin_id = params.id;

        this.state = {
            history,
            params,
            location,
            width,
            height,
            admin_id
        };

    }

    componentWillUnmount(){


        this.props.clearEditAdminState();

    }


    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeEditAdminAccountPage
        } = this.props;

        const { history, admin_id } = this.state;

        if(!logged_in){

            history.push("/");

        }else{

            if(!roles.includes("root_admin")){

                history.push("/home");

            }else{

                initializeEditAdminAccountPage(access_token, client, uid, history, admin_id);

            }

        }

    }




    adminRoles = () => {

        const available_roles = this.props.available_roles;

        const changeAdminAccountRoles = this.props.changeAdminAccountRoles;

        let admin_roles = this.props.admin_roles;

        return _.map(available_roles, (role, index) => {



            return(

                <Form.Check
                    key={index}
                    checked={admin_roles.includes(role)}
                    onChange={(e) => {

                        const checked = e.target.checked;

                        if(checked){


                            let new_selected_roles = _.cloneDeep(admin_roles);

                            new_selected_roles.push(role);

                            changeAdminAccountRoles(new_selected_roles);



                        }else{


                            let new_selected_roles = _.cloneDeep(admin_roles);

                            _.remove(new_selected_roles, function(selected_role) {
                                return role === selected_role;
                            });


                            changeAdminAccountRoles(new_selected_roles);



                        }

                    }}
                    value={role}
                    type="checkbox"
                    label={_.startCase( role.split("_").join(" ") )}
                />

            );

        });


    };

    rolesError(){

        const { roles_error } = this.props;


        if( !_.isEmpty(roles_error)){

            return(

                <p
                    style={{
                        color: '#ff0000',
                        marginBottom: '20px'
                    }}
                >
                    {roles_error}
                </p>


            );

        }

    }

    rolesUpdatedSuccess(){

        const { roles_update_success } = this.props;

        if(roles_update_success){

            return(

                <p
                    style={{
                        color: '#228B22',
                        marginBottom: '20px'
                    }}
                >
                    Successfully Updated
                </p>

            );

        }

    }

    formContent(){


        const {
            account,
            full_name,
            email,
            password,
            admin_roles,
            full_name_error,
            email_error,
            password_error,
            changeAdminAccountFullName,
            changeAdminAccountEmail,
            changeAdminAccountPassword,
            updateAdminAccount,
            access_token,
            client,
            uid,
            full_name_update_success,
            email_update_success,
            password_update_success
        } = this.props;

        const {
            history,
            admin_id
        } = this.state;

        return(

            <Fragment>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 2.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Full Name
                    </Form.Label>


                    <Form.Control
                        value={full_name}
                        onChange={(e) => {
                            changeAdminAccountFullName(e.target.value);
                        }}
                        isInvalid={!_.isEmpty(full_name_error)}
                        isValid={full_name_update_success}
                    />

                    <Form.Control.Feedback type="invalid">
                        {full_name_error}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="valid">
                        Successfully updated
                    </Form.Control.Feedback>

                </Form.Group>


                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 2.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Email
                    </Form.Label>


                    <Form.Control
                        type="email"
                        value={email}
                        onChange={(e) => {
                            changeAdminAccountEmail(e.target.value);
                        }}
                        isInvalid={!_.isEmpty(email_error)}
                        isValid={email_update_success}
                    />

                    <Form.Control.Feedback type="invalid">
                        {email_error}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="valid">
                        Successfully updated
                    </Form.Control.Feedback>

                </Form.Group>


                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 2.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        New Password (at least 8 characters long)
                    </Form.Label>


                    <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => {
                            changeAdminAccountPassword(e.target.value);
                        }}
                        isInvalid={!_.isEmpty(password_error)}
                        isValid={password_update_success}
                    />

                    <Form.Control.Feedback type="invalid">
                        {password_error}
                    </Form.Control.Feedback>

                    <Form.Control.Feedback type="valid">
                        Successfully updated
                    </Form.Control.Feedback>


                </Form.Group>


                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 2.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Roles
                    </Form.Label>

                    {this.rolesError()}

                    {this.rolesUpdatedSuccess()}

                    {this.adminRoles()}


                </Form.Group>



                <Button
                    variant="success"
                    size="lg"
                    style={{
                        width: isMobile ? this.state.width - 60 :  this.state.width / 6,
                        borderRadius: '20px',
                        marginBottom: '10px'
                    }}
                    onClick={() => {
                        updateAdminAccount(
                            access_token,
                            client,
                            uid,
                            history,
                            admin_id,
                            full_name,
                            email,
                            password,
                            admin_roles,
                            account
                        )
                    }}
                >
                    Submit
                </Button>

            </Fragment>

        );


    }

    renderForm(){

        if(isMobile){

            return(

                <div>
                    {this.formContent()}
                </div>

            );

        }else{

            return(

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >


                    <Card style={ {
                        padding: '30px',
                        alignItems: 'center'
                    }}>

                        {this.formContent()}

                    </Card>

                </Form>

            );

        }

    }

    renderBody(){


        const {
            fetching_admin_profile,
            loading
        } = this.props;


        if(fetching_admin_profile || loading){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={ {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '3rem',
                    flexDirection: 'column'
                }}>

                    <p style={{
                        fontSize: isMobile ? '26px' : '35px',
                        marginBottom: '2rem'
                    }}>
                        Edit Admin account
                    </p>


                    {this.renderForm()}



                </div>

            );

        }



    }


    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        loading,
        account,
        full_name,
        email,
        password,
        admin_roles,
        full_name_error,
        email_error,
        password_error,
        roles_error,
        available_roles,
        full_name_update_success,
        email_update_success,
        password_update_success,
        roles_update_success
    } = state.edit_admin;

    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        loading,
        account,
        full_name,
        email,
        password,
        admin_roles,
        full_name_error,
        email_error,
        password_error,
        roles_error,
        available_roles,
        full_name_update_success,
        email_update_success,
        password_update_success,
        roles_update_success
    };

};

export default connect(mapStateToProps, {
    initializeEditAdminAccountPage,
    changeAdminAccountFullName,
    changeAdminAccountEmail,
    changeAdminAccountPassword,
    changeAdminAccountRoles,
    clearEditAdminState,
    updateAdminAccount
})(EditAdmin);
