import {
    LOGOUT_SUCCESS,
    CLEAR_CREATE_PRODUCT_STATE,
    CREATE_PRODUCT,
    CREATE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_FAILURE,
    HIDE_CREATE_PRODUCT_MODAL,
    CREATE_PRODUCT_NAME_CHANGED,
    CREATE_PRODUCT_DESCRIPTION_CHANGED,
    CREATE_PRODUCT_UPC_CHANGED,
    CREATE_PRODUCT_SKU_CHANGED,
    CREATE_PRODUCT_PICTURES_CHANGED,
    CREATE_PRODUCT_TOPIC_CHANGED,
    CREATE_PRODUCT_ATTRIBUTES_CHANGED,
    CLEAR_CREATE_PRODUCT_FORM,
    CREATE_PRODUCT_BRAND_NAME_CHANGED,
    CREATE_PRODUCT_PRICE_LIST_VISIBILITY_CHANGED,
    CREATE_PRODUCT_PACK_SIZE_CHANGED,
    CREATE_PRODUCT_PRICE_RANGES_CHANGED,
    CREATE_PRODUCT_QUANTITY_CHANGED
} from "../actions/types";

const INITIAL_STATE = {
    creating_product: false,
    name: '',
    description: '',
    upc: '',
    sku: '',
    pictures: [],
    topic_name: '',
    product_attributes: {},
    brand_name: '',
    create_product_modal_visible: false,
    success_message: '',
    error_message: '',
    price_list_form_visible: false,
    pack_size: '',
    price_ranges: [],
    quantity: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CREATE_PRODUCT_QUANTITY_CHANGED:
            return{
                ...state,
                quantity: action.payload
            };
        case CREATE_PRODUCT_PRICE_RANGES_CHANGED:
            return{
                ...state,
                price_ranges: action.payload
            };
        case CREATE_PRODUCT_PACK_SIZE_CHANGED:
            return{
                ...state,
                pack_size: action.payload
            };
        case CREATE_PRODUCT_PRICE_LIST_VISIBILITY_CHANGED:
            return{
                ...state,
                price_list_form_visible: action.payload
            };
        case CREATE_PRODUCT_BRAND_NAME_CHANGED:
            return{
                ...state,
                brand_name: action.payload
            };
        case HIDE_CREATE_PRODUCT_MODAL:
            return{
                ...state,
                create_product_modal_visible: false,
                success_message: '',
                error_message: ''
            };
        case CREATE_PRODUCT_FAILURE:
            return{
                ...state,
                creating_product: false,
                error_message: action.payload,
                create_product_modal_visible: true
            };
        case CREATE_PRODUCT_SUCCESS:
            return{
                ...state,
                creating_product: false,
                success_message: action.payload,
                create_product_modal_visible: true
            };
        case CREATE_PRODUCT:
            return{
                ...state,
                creating_product: true
            };
        case CREATE_PRODUCT_ATTRIBUTES_CHANGED:
            return{
                ...state,
                product_attributes: action.payload
            };
        case CREATE_PRODUCT_TOPIC_CHANGED:
            return{
                ...state,
                topic_name: action.payload
            };
        case CREATE_PRODUCT_PICTURES_CHANGED:
            return{
                ...state,
                pictures: action.payload
            };
        case CREATE_PRODUCT_SKU_CHANGED:
            return{
                ...state,
                sku: action.payload
            };
        case CREATE_PRODUCT_UPC_CHANGED:
            return{
                ...state,
                upc: action.payload
            };
        case CREATE_PRODUCT_DESCRIPTION_CHANGED:
            return{
                ...state,
                description: action.payload
            };
        case CREATE_PRODUCT_NAME_CHANGED:
            return{
                ...state,
                name: action.payload
            };
        case CLEAR_CREATE_PRODUCT_FORM:
            return{
                ...state,
                name: '',
                description: '',
                upc: '',
                sku: '',
                pictures: [],
                topic_name: '',
                product_attributes: {},
                brand_name: '',
                price_list_form_visible: false,
                pack_size: '',
                price_ranges: [],
                quantity: ''
            };
        case CLEAR_CREATE_PRODUCT_STATE:
            return{
                ...state,
                ...INITIAL_STATE,
                product_attributes: {}
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};