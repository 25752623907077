import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    createAdmin,
    clearCreateAdminState,
    initializeCreateAdminPage
} from "../actions";
import Wrapper from "./Wrapper";

class CreateAdmin extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const full_name = "";

        const email = "";

        const password = "";

        const selected_roles = [];

        this.state = {
            history,
            params,
            location,
            width,
            height,
            full_name,
            email,
            password,
            selected_roles
        };

    }

    componentWillUnmount(){

        this.setState({
            full_name: '',
            email: '',
            password: '',
            selected_roles: []
        });

        this.props.clearCreateAdminState();

    }

    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeCreateAdminPage
        } = this.props;

        const { history } = this.state;

        if(!logged_in){

            history.push("/");

        }else{

            if(!roles.includes("root_admin")){

                history.push("/home");

            }else{

                initializeCreateAdminPage(access_token, client, uid, history);

            }

        }

    }


    adminRoles(){

        const { admin_roles } = this.props;

        const { selected_roles  } = this.state;

        return _.map(admin_roles, (admin_role, index) => {

            return(

                <Form.Check
                    key={index}
                    checked={selected_roles.includes(admin_role)}
                    onChange={(e) => {

                        const checked = e.target.checked;

                        if(checked){

                            let new_selected_roles = selected_roles;

                            new_selected_roles.push(admin_role);

                            this.setState({selected_roles: new_selected_roles});


                        }else{

                            let new_selected_roles = selected_roles;

                            _.remove(new_selected_roles, function(selected_role) {
                                return admin_role === selected_role;
                            });


                            this.setState({selected_roles: new_selected_roles});



                        }

                    }}
                    value={admin_role}
                    type="checkbox"
                    label={_.startCase( admin_role.split("_").join(" ") )}
                />

            );

        });


    }

    errorMessage(){

        const { error_message } = this.props;

        if(error_message.length > 0){

            return(

                <p style={{
                    color: '#ff0000',
                    textAlign: 'center',
                    marginBottom: '1.5rem',
                    fontSize: '18px'
                }}>
                    {error_message}
                </p>

            );

        }

    }

    successMessage(){

        const {success_message } = this.props;

        if(success_message.length > 0){

            return(

                <p style={{
                    color: '#228B22',
                    textAlign: 'center',
                    marginBottom: '1.5rem',
                    fontSize: '18px'
                }}>
                    {success_message}
                </p>

            );

        }

    }


    formContent(){

        const {
            fetching_admin_profile,
            access_token,
            client,
            uid,
            loading,
            success_message,
            error_message,
            createAdmin
        } = this.props;

        const {
            history,
            full_name,
            email,
            password,
            selected_roles
        } = this.state;



            return(

                <Fragment>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 :  this.state.width / 2.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: '18px',
                            marginBottom: '15px'
                        }}>
                            Full Name
                        </Form.Label>


                        <Form.Control
                            value={full_name}
                            onChange={(e) => {
                                this.setState({full_name: e.target.value});
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 :  this.state.width / 2.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: '18px',
                            marginBottom: '15px'
                        }}>
                            Email
                        </Form.Label>


                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => {
                                this.setState({email: e.target.value});
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 :  this.state.width / 2.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: '18px',
                            marginBottom: '15px'
                        }}>
                            Password
                        </Form.Label>


                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => {
                                this.setState({password: e.target.value});
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 :  this.state.width / 2.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: '18px',
                            marginBottom: '15px'
                        }}>
                            Roles
                        </Form.Label>


                        {this.adminRoles()}



                    </Form.Group>


                    {this.errorMessage()}

                    {this.successMessage()}


                    <Button
                        variant="success"
                        size="lg"
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 6,
                            borderRadius: '20px',
                            marginBottom: '10px'
                        }}
                        onClick={() => {
                            createAdmin(access_token, client, uid, history, full_name, email, password, selected_roles);
                        }}
                    >
                        Submit
                    </Button>


                </Fragment>

            );


    }

    renderForm(){

        const {
            fetching_admin_profile,
            access_token,
            client,
            uid,
            loading,
            success_message,
            error_message,
            createAdmin
        } = this.props;

        const {
            history,
            full_name,
            email,
            password,
            selected_roles
        } = this.state;


        if(isMobile){

            return(

                <div>
                    {this.formContent()}
                </div>

            );

        }else{

            return(


                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >


                    <Card style={ {
                        padding: '30px',
                        alignItems: 'center'
                    }}>

                        {this.formContent()}


                    </Card>


                </Form>




            );

        }

    }

    renderBody(){


        const {
            fetching_admin_profile,
            access_token,
            client,
            uid,
            loading,
            success_message,
            error_message,
            createAdmin
        } = this.props;

        const {
            history,
            full_name,
            email,
            password,
            selected_roles
        } = this.state;


        if(fetching_admin_profile || loading){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div style={ {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '3rem',
                    flexDirection: 'column'
                }}>

                    <p style={{
                        fontSize:  isMobile ? '25px' : '35px',
                        marginBottom: '2rem'
                    }}>
                        Create Admin Account
                    </p>


                    {this.renderForm()}







                </div>

            );

        }



    }

    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        loading,
        success_message,
        error_message,
        admin_roles
    } = state.create_admin;

    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        loading,
        success_message,
        error_message,
        admin_roles
    };

};

export default connect(mapStateToProps, {
    createAdmin,
    clearCreateAdminState,
    initializeCreateAdminPage
})(CreateAdmin);



