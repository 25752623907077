import {
    RESEND_UNLOCK_LINK,
    RESEND_UNLOCK_LINK_SUCCESS,
    RESEND_UNLOCK_LINK_FAILURE,
    RESEND_UNLOCK_LINK_COMPLETE,
    CLEAR_RESEND_UNLOCK_STATE
} from "../actions/types";

const INITIAL_STATE = {
    loading: false,
    message: '',
    errors: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_RESEND_UNLOCK_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case RESEND_UNLOCK_LINK_FAILURE:
            return{
                ...state,
                errors: action.payload
            };
        case RESEND_UNLOCK_LINK_SUCCESS:
            return{
                ...state,
                message: action.payload
            };
        case RESEND_UNLOCK_LINK_COMPLETE:
            return{
                ...state,
                loading: false
            };
        case RESEND_UNLOCK_LINK:
            return{
                ...state,
                loading: true,
                message: '',
                errors: []
            };
        default:
            return state;
    }
};