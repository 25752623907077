import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeLandingPageImagesPage,
    clearLandingPageImagesState,
    addLandingImage,
    deleteLandingImage
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";
import ImageUploading from 'react-images-uploading';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

class LandingPageImages extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        this.state = {
            history,
            params,
            location,
            width,
            height
        };

    }

    componentWillUnmount(){
        this.props.clearLandingPageImagesState();
    }

    componentDidMount(){

        const {
            logged_in,
            initializeLandingPageImagesPage,
            access_token,
            client,
            uid
        } = this.props;

        const { history } = this.state;

        if(!logged_in){

            history.push("/");

        }else{

            initializeLandingPageImagesPage(access_token, client, uid, history);

        }

    }

    renderForm(){

        const {
            fetching_admin_profile,
            initializing_page,
            landing_page_images,
            modifying_images,
            landing_image_error_message,
            access_token,
            client,
            uid,
            addLandingImage,
            deleteLandingImage
        } = this.props;

        const {
            history,
            params,
            location
        } = this.state;

        if(modifying_images){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );


        }else{

            const responsive =  {
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                    slidesToSlide: 3
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                    slidesToSlide: 2
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1,
                    slidesToSlide: 1
                }
            };


            return(

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1.5rem'
                    }}
                >

                    <Form.Label
                    >
                        Upload Image
                    </Form.Label>

                    <ImageUploading
                        multiple={false}
                        value={landing_page_images}
                        onChange={(imageList, addUpdateIndex) => {

                            console.log(imageList);

                            addLandingImage(
                                access_token,
                                client,
                                uid,
                                history,
                                imageList[addUpdateIndex]
                            );



                        }}
                        acceptType={['jpg' , 'jpeg',  'gif', 'png', 'webp']}
                    >

                        {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps
                          }) => (
                            // write your building UI
                            <div >


                                <div style={ isMobile ? {
                                    display: 'flex' ,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    marginBottom: '2rem'
                                } : {
                                    marginBottom: '4rem'
                                }}>

                                    <Button
                                        style={ isMobile ? {
                                            color: isDragging ? 'red' : null,
                                            width: '45%'
                                        }:  {
                                            color: isDragging ? 'red' : null,
                                            marginRight: '20px'
                                        }}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                        variant="success"
                                    >
                                        { isMobile ? "Upload" : "Click or Drop here"  }
                                    </Button>




                                </div>


                                <Carousel
                                    responsive={responsive}
                                    removeArrowOnDeviceType={["tablet", "mobile"]}
                                >

                                    {imageList.map((image, index) => (


                                        <Card
                                            key={index}
                                            style={  {
                                                marginRight: isMobile ? 0 : '20px',
                                            }}
                                        >

                                            <Image
                                                src={image.url}
                                                style={ isMobile ? {
                                                    width: '300px',
                                                    height: '200px',
                                                    padding: '20px'
                                                } : {
                                                    width: '400px',
                                                    height: '300px',
                                                    padding: '20px'
                                                }}
                                                className="create-product-image"
                                            />



                                            <Card.Footer>


                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    flexDirection: 'column',
                                                    padding: '10px'
                                                }}>


                                                    <Button
                                                        onClick={() => {
                                                            deleteLandingImage(
                                                                access_token,
                                                                client,
                                                                uid,
                                                                history,
                                                                image.id
                                                            );
                                                        }}
                                                        variant="danger"
                                                        style={{
                                                            width: '90%',
                                                            marginBottom: '15px'
                                                        }}
                                                    >
                                                        Remove
                                                    </Button>


                                                </div>

                                            </Card.Footer>


                                        </Card>




                                    ))}

                                </Carousel>








                            </div>
                        )}


                    </ImageUploading>



                </Form.Group>

            );



        }

    }

    landingImageErrorMessage(){

        const { landing_image_error_message } = this.props;


        if(landing_image_error_message.length > 0){

            return(

                <p style={{
                    color: 'red',
                    fontSize: '18px',
                    textAlign: 'center'
                }}>
                    {landing_image_error_message}
                </p>

            );

        }

    }

    renderBody(){

        const {
            fetching_admin_profile,
            initializing_page,
            landing_page_images,
            modifying_images,
            landing_image_error_message
        } = this.props;

        const {
            history,
            params,
            location
        } = this.state;




        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />

                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>


                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>

                            <p style={{
                                fontSize: isMobile ? '30px' : '40px',
                                marginBottom: '20px',
                                textAlign: 'center'
                            }}>
                                Landing Page Images
                            </p>

                            {this.landingImageErrorMessage()}

                            <div style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '3rem',
                                flexDirection: 'column'
                            }}>


                                {this.renderForm()}



                            </div>


                        </div>




                    </div>


                </div>

            );

        }

    }

    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}
const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        initializing_page,
        landing_page_images,
        modifying_images,
        landing_image_error_message
    } = state.landing_page_images;


    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        landing_page_images,
        modifying_images,
        landing_image_error_message
    };

};

export default connect(mapStateToProps, {
    initializeLandingPageImagesPage,
    clearLandingPageImagesState,
    addLandingImage,
    deleteLandingImage
})(LandingPageImages);

