import {
    LOGOUT_SUCCESS,
    PRODUCTS_PAGE_INDEX_ROUTE,
    INITIALIZE_PRODUCTS_PAGE,
    INITIALIZE_PRODUCTS_PAGE_COMPLETE,
    CLEAR_PRODUCTS_PAGE_STATE,
    SEARCH_PRODUCTS_ROUTE,
    SEARCH_PRODUCTS,
    SEARCH_PRODUCTS_COMPLETE,
    HIDE_PRODUCT_ROUTE,
    CHANGE_PRODUCTS_CATEGORY_ROUTE,
    CHANGE_PRODUCTS_CATEGORY,
    PRODUCTS_PAGE_CATEGORIES_CHANGED,
    CHANGE_PRODUCTS_CATEGORY_FAILURE,
    CHANGE_PRODUCTS_CATEGORY_SUCCESS,
    CHANGE_PRODUCTS_CATEGORY_COMPLETE,
    PRODUCTS_BATCH_ACTION_EXECUTION_COMPLETE,
    CLEAR_CHANGE_PRODUCTS_CATEGORY_MODAL_MESSAGES,
    PRODUCTS_QUANTITY_CHANGED
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const productsQuantityChanged = (quantity) => {

    return{
        type: PRODUCTS_QUANTITY_CHANGED,
        payload: quantity
    };

};

export const clearChangeProductsCategoryModalMessages = () => {

    return{
        type: CLEAR_CHANGE_PRODUCTS_CATEGORY_MODAL_MESSAGES
    };

};

export const productsBatchActionExecutionComplete = () => {

    return{
        type: PRODUCTS_BATCH_ACTION_EXECUTION_COMPLETE
    }

};

export const changeProductsCategory = (
    access_token,
    client,
    uid,
    history,
    search_term,
    category_id,
    topic_id,
    new_category_id,
    product_ids,
    quantity
) => {

    return(dispatch) => {

        dispatch({type: CHANGE_PRODUCTS_CATEGORY});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = new FormData();

        let data = {
            category_id: new_category_id
        };

        data.product_ids = JSON.stringify(product_ids);

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });

        axios.post(CHANGE_PRODUCTS_CATEGORY_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: CHANGE_PRODUCTS_CATEGORY_COMPLETE});

                const data = response.data;

                const success = data.success;


                if(success){

                    dispatch({
                        type: CHANGE_PRODUCTS_CATEGORY_SUCCESS,
                        payload: 'Successfully updated products category'
                    });

                    dispatch(searchProducts(access_token, client, uid, history, search_term, category_id, topic_id, quantity));

                }else{

                    const message = data.message;

                    const categories = data.categories;


                    if(categories !== undefined && categories !== null){

                        dispatch({type: PRODUCTS_PAGE_CATEGORIES_CHANGED, payload: categories});

                    }

                    dispatch({type: CHANGE_PRODUCTS_CATEGORY_FAILURE, payload: message});


                }



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};





export const hideProduct = (access_token, client, uid, history, search_term, category_id, topic_id, product_id, quantity) => {

    return(dispatch) => {

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: product_id
        });

        axios.post(HIDE_PRODUCT_ROUTE, bodyFormData, config)
            .then( response => {

                dispatch(searchProducts(access_token, client, uid, history, search_term, category_id, topic_id, quantity));

            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};

export const searchProducts = (access_token, client, uid, history, search_term, category_id, topic_id, quantity) => {

    return(dispatch) => {

        dispatch({type: SEARCH_PRODUCTS});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            search_term: search_term,
            category_id: category_id,
            topic_id: topic_id,
            quantity: quantity
        });

        axios.post(SEARCH_PRODUCTS_ROUTE, bodyFormData, config)
            .then( response => {

                const data = response.data;

                const products = data.products;

                dispatch({type: SEARCH_PRODUCTS_COMPLETE, payload: products});


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const clearProductsPageState = () => {

    return{
        type: CLEAR_PRODUCTS_PAGE_STATE
    };

};

export const initializeProductsPage = (access_token, client, uid, history) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_PRODUCTS_PAGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.get(PRODUCTS_PAGE_INDEX_ROUTE, config)
            .then(response => {

                const data = response.data;

                const products = data.products;

                const categories = data.categories;

                const topics = data.topics;


                dispatch({type: INITIALIZE_PRODUCTS_PAGE_COMPLETE, payload: {
                    products: products,
                    categories: categories,
                    topics: topics
                }})

            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};
