import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeViewCustomerPage,
    clearViewCustomerPageState,
    openApproveAccountModal,
    closeApproveAccountModal,
    approveAccount
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";

class ViewCustomer extends Component{

    constructor(props){

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const customer_id = params.customer_id;

        this.state = {
            history,
            params,
            location,
            width,
            height,
            customer_id
        };

    }

    componentWillUnmount(){


        this.props.clearViewCustomerPageState();

    }

    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeViewCustomerPage
        } = this.props;

        const { history, customer_id } = this.state;

        if(!logged_in){

            history.push("/");

        }else{


            if(!roles.includes("root_admin") && !roles.includes("account_moderator")){

                history.push("/home");

            }else{

                initializeViewCustomerPage(
                    access_token,
                    client,
                    uid,
                    history,
                    customer_id
                );

            }

        }

    }


    approveAccountModalError(){

        const { approve_account_modal_error } = this.props;

        if(!_.isEmpty(approve_account_modal_error)){

            return(

                <p style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    color: 'red',
                    marginTop: '30px'
                }}>
                    {approve_account_modal_error}
                </p>

            );

        }


    }

    approveAccountModalBody(){

        const { approving_account } = this.props;

        if(approving_account){

            return(

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10px',
                    marginBottom: '10px'
                }}>

                    <Spinner animation="border" variant="primary"  />

                </div>

            );

        }else{

            return(

                <div>

                    <p style={{
                        fontSize: '18px',
                        textAlign: 'center'
                    }}>
                        The customer's account will be approved
                    </p>


                    {this.approveAccountModalError()}

                </div>
            );

        }

    }


    approveAccountModal(){

        const {
            approve_account_modal_visible,
            closeApproveAccountModal,
            approveAccount,
            access_token,
            client,
            uid
        } = this.props;

        const { history, customer_id } = this.state;

        if(approve_account_modal_visible){

            return(

                <Modal
                    size={isMobile ? 'sm' : 'lg'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={approve_account_modal_visible}
                    onHide={() => {
                        closeApproveAccountModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Are you sure?
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.approveAccountModalBody()}

                    </Modal.Body>


                    <Modal.Footer>

                        <Button
                            variant="success"
                            style={{
                            }}
                            onClick={() => {
                                approveAccount(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    customer_id
                                );
                            }}>
                            Approve Account
                        </Button>


                        <Button
                            variant="secondary"
                            onClick={() => {
                                closeApproveAccountModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }

    }


    approveAccountButton(){

        const {
            openApproveAccountModal,
            account_approved
        } = this.props;

        if(!account_approved){

            return(

                <Button
                    style={{
                        marginTop: '15px',
                        marginBottom: '20px',
                        width: '100%'
                    }}
                    onClick={ () => {
                        openApproveAccountModal();
                    } }
                    variant="outline-success"
                >
                    Approve Account
                </Button>

            );

        }



    }

    renderForm(){

        const {
            email,
            full_name,
            company_name,
            company_address,
            company_state,
            company_city,
            company_zip_code,
            account_approved,
            seller_permit_url,
            openApproveAccountModal,
            email_consent_given
        } = this.props;


        return(

            <div>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Email
                    </Form.Label>


                    <Form.Control
                        type="email"
                        value={email}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Full Name
                    </Form.Label>


                    <Form.Control
                        value={full_name}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Company Name
                    </Form.Label>


                    <Form.Control
                        value={company_name}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Company Address
                    </Form.Label>


                    <Form.Control
                        value={company_address}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        State
                    </Form.Label>


                    <Form.Control
                        value={company_state}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        City
                    </Form.Label>


                    <Form.Control
                        value={company_city}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Zip Code
                    </Form.Label>


                    <Form.Control
                        value={company_zip_code}
                        disabled
                    />
                </Form.Group>


                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Account Approved
                    </Form.Label>


                    <Form.Control
                        value={account_approved ? 'Yes' : 'No'}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >

                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        Email Consent Given
                    </Form.Label>


                    <Form.Control
                        value={email_consent_given ? 'Yes' : 'No'}
                        disabled
                    />
                </Form.Group>


                <Button
                    style={{
                        marginTop: '15px',
                        marginBottom: '20px',
                        width: '100%'
                    }}
                    onClick={ () => {
                        window.open(seller_permit_url)
                    } }
                    variant="outline-primary"
                >
                    View Seller Permit
                </Button>


                {this.approveAccountButton()}


                {this.approveAccountModal()}



            </div>

        );



    }

    renderBody(){


        const {
            fetching_admin_profile,
            initializing_page,
            company_name
        } = this.props;

        const {
            history,
            params,
            location
        } = this.state;

        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />


                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '3rem',
                            flexDirection: 'column'
                        }}>

                            <p style={{
                                fontSize: isMobile ? '25px' : '35px',
                                marginBottom: '3rem',
                                textAlign: 'center'
                            }}>
                                {company_name}
                            </p>

                            {this.renderForm()}


                        </div>


                    </div>



                </div>

            );

        }



    }


    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        initializing_page,
        email,
        full_name,
        company_name,
        company_address,
        company_state,
        company_city,
        company_zip_code,
        account_approved,
        state_code,
        seller_permit_url,
        approve_account_modal_visible,
        approve_account_modal_error,
        approving_account,
        email_consent_given
    } = state.view_customer;

    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        email,
        full_name,
        company_name,
        company_address,
        company_state,
        company_city,
        company_zip_code,
        account_approved,
        state_code,
        seller_permit_url,
        approve_account_modal_visible,
        approve_account_modal_error,
        approving_account,
        email_consent_given
    };

};

export default connect(mapStateToProps, {
    initializeViewCustomerPage,
    clearViewCustomerPageState,
    openApproveAccountModal,
    closeApproveAccountModal,
    approveAccount
})(ViewCustomer);
