import {
    LOGIN_ROUTE,
    LOGIN_ADMIN,
    LOGIN_ADMIN_SUCCESS,
    LOGIN_ADMIN_FAILURE,
    LOGOUT_ROUTE, LOGOUT_SUCCESS
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";

export const logoutAdmin = (access_token, client, uid, history) => {

    return(dispatch) => {

        const config = {
            headers: {
                "access-token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        axios.delete(LOGOUT_ROUTE, config)
            .then(() => {

                dispatch({type: LOGOUT_SUCCESS});

                history.push("/");

            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const loginAdmin = (email, password, history) => {

    return(dispatch) => {

        dispatch({type: LOGIN_ADMIN});

        const config = {
            headers: {
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            email: email,
            password: password
        });


        axios.post(LOGIN_ROUTE, bodyFormData, config)
            .then(response => {

                const data = response.data.data;

                console.log(data);

                const roles = data.roles;

                const id = data.id;

                const full_name = data.full_name;

                const headers = response.headers;

                const access_token = headers["access-token"];

                const client = headers["client"];

                const uid = headers["uid"];

                console.log(headers);

                dispatch({type: LOGIN_ADMIN_SUCCESS, payload: {
                    access_token: access_token,
                    client: client,
                    uid: uid,
                    roles: roles,
                    id: id,
                    full_name: full_name
                }});

                history.push("/home");


            }).catch(error => {

            const errors = error.response.data.errors;

            console.log(errors);

            dispatch({ type: LOGIN_ADMIN_FAILURE, payload: errors });

        });

    };

};