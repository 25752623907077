import {
    LOGOUT_SUCCESS,
    EDIT_PRODUCT_INDEX_ROUTE,
    INITIALIZE_EDIT_PRODUCT_PAGE,
    INITIALIZE_EDIT_PRODUCT_PAGE_COMPLETE,
    INITIALIZE_EDIT_PRODUCT_PAGE_SUCCESS,
    CLEAR_EDIT_PRODUCT_STATE,
    EDIT_PRODUCT_NAME_CHANGED,
    EDIT_PRODUCT_DESCRIPTION_CHANGED,
    EDIT_PRODUCT_UPC_CHANGED,
    EDIT_PRODUCT_SKU_CHANGED,
    EDIT_PRODUCT_TOPIC_CHANGED,
    EDIT_PRODUCT_ATTRIBUTES_CHANGED,
    EDIT_PRODUCT_CATEGORY_CHANGED,
    REMOVE_PRODUCT_IMAGE_ROUTE,
    REMOVE_PRODUCT_IMAGE_SUCCESS,
    REMOVE_PRODUCT_IMAGE_FAILURE,
    HIDE_EDIT_PRODUCT_MODAL,
    ADD_PRODUCT_IMAGES_ROUTE,
    ADD_PRODUCT_IMAGES_FAILURE,
    ADD_PRODUCT_IMAGES_SUCCESS,
    REMOVE_PRODUCT_IMAGE,
    REMOVE_PRODUCT_IMAGE_COMPLETE,
    ADD_PRODUCT_IMAGES,
    ADD_PRODUCT_IMAGES_COMPLETE,
    UPDATE_PRODUCT_ROUTE,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_COMPLETE,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    REPLACE_PRODUCT_PICTURE_ROUTE,
    REPLACE_PRODUCT_PICTURE,
    REPLACE_PRODUCT_PICTURE_COMPLETE,
    REPLACE_PRODUCT_PICTURE_FAILURE,
    EDIT_PRODUCT_PICTURES_CHANGED,
    EDIT_PRODUCT_BRAND_NAME_CHANGED,
    EDIT_PACK_SIZE_MODAL_VISIBLE_CHANGED,
    NEW_PACK_SIZE_CHANGED,
    EDIT_PACK_SIZE_ROUTE,
    EDIT_PACK_SIZE,
    EDIT_PACK_SIZE_COMPLETE,
    EDIT_PACK_SIZE_SUCCESS,
    EDIT_PACK_SIZE_FAILURE,
    CLEAR_EDIT_PRICE_LIST_STATE,
    CLEAR_EDIT_PACK_SIZE_MODAL_STATE,
    EDIT_PACK_SIZE_MODAL_ERROR_CHANGED,
    EDIT_PRICE_MODAL_VISIBLE_CHANGED,
    NEW_PRICE_CHANGED,
    EDIT_PRICE_ROUTE,
    EDIT_PRICE,
    EDIT_PRICE_COMPLETE,
    EDIT_PRICE_SUCCESS,
    EDIT_PRICE_FAILURE,
    CLEAR_EDIT_PRICE_MODAL_STATE,
    EDIT_PRICE_MODAL_ERROR_CHANGED,
    EDIT_PRICE_RANGES_CHANGED,
    EDIT_SELECTED_PRICE_RANGE_ID_CHANGED,
    OPEN_EDIT_PRICE_MODAL,
    REMOVE_PRICE_RANGE_ROUTE,
    REMOVE_PRICE_RANGE,
    REMOVE_PRICE_RANGE_COMPLETE,
    REMOVE_PRICE_RANGE_FAILURE,
    NEW_END_QUANTITY_CHANGED,
    EDIT_QUANTITY_ROUTE,
    EDIT_QUANTITY,
    EDIT_QUANTITY_COMPLETE,
    EDIT_QUANTITY_FAILURE,
    CLEAR_EDIT_QUANTITY_MODAL_STATE,
    EDIT_QUANTITY_MODAL_ERROR_CHANGED,
    OPEN_EDIT_QUANTITY_MODAL,
    CLEAR_ADD_PRICE_MODAL_STATE,
    END_QUANTITY_ERROR_CHANGED,
    PRICE_ERROR_CHANGED,
    OPEN_ADD_PRICE_MODAL,
    ADD_PRICE_ROUTE,
    ADD_PRICE,
    ADD_PRICE_COMPLETE,
    ADD_PRICE_FAILURE,
    ADD_PRICE_MODAL_ERROR_CHANGED,
    DELETE_PRICE_LIST_ROUTE,
    DELETE_PRICE_LIST,
    DELETE_PRICE_LIST_COMPLETE,
    CLEAR_CREATE_PRICE_LIST_MODAL_STATE,
    PACK_SIZE_ERROR_CHANGED,
    CREATE_PRICE_LIST_MODAL_ERROR_CHANGED,
    OPEN_CREATE_PRICE_LIST_MODAL,
    CREATE_PRICE_LIST,
    CREATE_PRICE_LIST_ROUTE,
    CREATE_PRICE_LIST_COMPLETE,
    CREATE_PRICE_LIST_FAILURE,
    CREATE_PRICE_LIST_SUCCESS,
    EDIT_PRODUCT_QUANTITY_CHANGED
} from "./types";

import axios from "axios";
import { getFormData } from "../helpers";
import _ from "lodash";

export const editProductQuantityChanged = (quantity) => {

    return{
      type: EDIT_PRODUCT_QUANTITY_CHANGED,
      payload: quantity
    };


};

export const createPriceList = (
    access_token,
    client,
    uid,
    history,
    price_list,
    product_id
) => {

    return(dispatch) => {

        dispatch({type: CREATE_PRICE_LIST});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = new FormData();

        let data = {
            product_id: product_id
        };

        data.price_list = JSON.stringify(price_list);

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });


        axios.post(CREATE_PRICE_LIST_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: CREATE_PRICE_LIST_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const price_list_id = data.price_list_id;

                    const pack_size = data.pack_size;

                    const price_ranges = data.price_ranges;


                    dispatch({type: CREATE_PRICE_LIST_SUCCESS, payload: {
                        price_list_id: price_list_id,
                        pack_size: pack_size,
                        price_ranges: price_ranges
                    }});


                    dispatch({type: CLEAR_CREATE_PRICE_LIST_MODAL_STATE});

                }else{

                    const price_list_exists = data.price_list_exists;

                    if(price_list_exists){

                        const price_list_id = data.price_list_id;

                        const pack_size = data.pack_size;

                        const price_ranges = data.price_ranges;


                        dispatch({type: CREATE_PRICE_LIST_SUCCESS, payload: {
                            price_list_id: price_list_id,
                            pack_size: pack_size,
                            price_ranges: price_ranges
                        }});


                        dispatch({type: CLEAR_CREATE_PRICE_LIST_MODAL_STATE});


                    }else{

                        const message = data.message;

                        dispatch({type: CREATE_PRICE_LIST_FAILURE, payload: message});

                    }


                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });




    };

};


export const clearCreatePriceListModalState = () => {

    return{
        type: CLEAR_CREATE_PRICE_LIST_MODAL_STATE
    };

};

export const openCreatePriceListModal = () => {

    return{
        type: OPEN_CREATE_PRICE_LIST_MODAL
    };

};

export const createPriceListModalErrorChanged = (price_list_modal_error) => {

    return{
      type: CREATE_PRICE_LIST_MODAL_ERROR_CHANGED,
      payload: price_list_modal_error
    };

};



export const packSizeErrorChanged = (pack_size_error) => {

    return{
      type: PACK_SIZE_ERROR_CHANGED,
      payload: pack_size_error
    };


};



export const deletePriceList = (
    access_token,
    client,
    uid,
    history,
    price_list_id
) => {

    return(dispatch) => {

        dispatch({type: DELETE_PRICE_LIST});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: price_list_id
        });

        axios.post(DELETE_PRICE_LIST_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: DELETE_PRICE_LIST_COMPLETE});

                const data = response.data;

                dispatch({type: CLEAR_EDIT_PRICE_LIST_STATE});



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const addPrice = (
    access_token,
    client,
    uid,
    history,
    price_list_id,
    price_range
) => {

    return(dispatch) => {

        dispatch({type: ADD_PRICE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = new FormData();

        let data = {
           price_list_id: price_list_id
        };

        data.price_range = JSON.stringify(price_range);

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });

        axios.post(ADD_PRICE_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: ADD_PRICE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const price_ranges = data.price_ranges;

                    dispatch({type: EDIT_PRICE_RANGES_CHANGED, payload: price_ranges});

                    dispatch({type: CLEAR_ADD_PRICE_MODAL_STATE});

                }else{

                    const record_not_found = data.record_not_found;

                    if(record_not_found){

                        dispatch({type: CLEAR_ADD_PRICE_MODAL_STATE});

                        dispatch({type: CLEAR_EDIT_PRICE_LIST_STATE});

                    }else{

                        const message = data.message;

                        dispatch({type: ADD_PRICE_FAILURE, payload: message});

                    }

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};


export const clearAddPriceModalState = () => {

    return{
        type: CLEAR_ADD_PRICE_MODAL_STATE
    };

};

export const openAddPriceModal = (new_start_quantity) => {

    return{
        type: OPEN_ADD_PRICE_MODAL,
        payload: new_start_quantity
    }


};

export const addPriceModalErrorChanged = (add_price_modal_error  ) => {
  return{
    type: ADD_PRICE_MODAL_ERROR_CHANGED,
    payload: add_price_modal_error
  };
};

export const priceErrorChanged = (price_error) => {

    return{
        type: PRICE_ERROR_CHANGED,
        payload: price_error
    };

};

export const endQuantityErrorChanged = (end_quantity_error) => {

    return{
        type: END_QUANTITY_ERROR_CHANGED,
        payload: end_quantity_error
    };

};

export const editQuantity = (
    access_token,
    client,
    uid,
    history,
    price_range_id,
    new_end_quantity,
    price_ranges
) => {

    return(dispatch) => {

        dispatch({type: EDIT_QUANTITY});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: price_range_id,
            end_quantity: new_end_quantity
        });

        axios.post(EDIT_QUANTITY_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: EDIT_QUANTITY_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const price_ranges = data.price_ranges;

                    dispatch({type: EDIT_PRICE_RANGES_CHANGED, payload: price_ranges});

                    dispatch({type: CLEAR_EDIT_QUANTITY_MODAL_STATE});


                }else{

                    const record_not_found = data.record_not_found;

                    if(record_not_found){

                        _.remove(price_ranges, function(pr) {
                            return pr.id === price_range_id
                        });

                        const new_price_ranges = _.cloneDeep(price_ranges);

                        if(_.isEmpty(new_price_ranges)){

                            dispatch({type: CLEAR_EDIT_QUANTITY_MODAL_STATE});

                            dispatch({type: CLEAR_EDIT_PRICE_LIST_STATE});

                        }else{

                            dispatch({type: CLEAR_EDIT_QUANTITY_MODAL_STATE});

                            dispatch({type: EDIT_PRICE_RANGES_CHANGED, payload: new_price_ranges});


                        }



                    }else{

                        const message = data.message;

                        dispatch({type: EDIT_QUANTITY_FAILURE, payload: message});

                    }

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};

export const editQuantityModalErrorChanged = (edit_quantity_modal_error) => {

    return{
        type: EDIT_QUANTITY_MODAL_ERROR_CHANGED,
        payload: edit_quantity_modal_error
    };

};


export const newEndQuantityChanged = (new_end_quantity) => {

    return{
        type: NEW_END_QUANTITY_CHANGED,
        payload: new_end_quantity
    };

};

export const clearEditQuantityModalState = () => {

    return{
        type: CLEAR_EDIT_QUANTITY_MODAL_STATE
    };

};

export const openEditQuantityModal = (new_start_quantity, new_end_quantity, selected_price_range_id) => {

    return(dispatch) => {

        dispatch({type: OPEN_EDIT_QUANTITY_MODAL, payload: {
            new_start_quantity: new_start_quantity,
            new_end_quantity: new_end_quantity,
            selected_price_range_id: selected_price_range_id
        }})

    };

};


export const removePriceRange = (access_token, client, uid, history, price_range_id, price_ranges, index) => {

    return(dispatch) => {

        dispatch({type: REMOVE_PRICE_RANGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: price_range_id
        });


        axios.post(REMOVE_PRICE_RANGE_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: REMOVE_PRICE_RANGE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const price_ranges = data.price_ranges;

                    dispatch({type: EDIT_PRICE_RANGES_CHANGED, payload: price_ranges});

                }else{

                    const record_not_found = data.record_not_found;

                    if(record_not_found){

                        price_ranges.splice(index, 1);

                        const new_price_ranges = _.cloneDeep(price_ranges);

                        dispatch({type: EDIT_PRICE_RANGES_CHANGED, payload: new_price_ranges});

                    }else{

                        const message = data.message;

                        dispatch({type: REMOVE_PRICE_RANGE_FAILURE, payload: message});

                    }

                }



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });



    };


};

export const openEditPriceModal = (price, selected_price_range_id) => {

    return(dispatch) => {



        dispatch({type: OPEN_EDIT_PRICE_MODAL, payload: {
            new_price: price,
            selected_price_range_id: selected_price_range_id
        }})

    };

};

export const clearEditPackSizeModalState = () => {

    return{
        type: CLEAR_EDIT_PACK_SIZE_MODAL_STATE
    };

};

export const clearEditPriceModalState = () => {
    return{
        type: CLEAR_EDIT_PRICE_MODAL_STATE
    };
};

export const editSelectedPriceRangeIdChanged = (selected_price_range_id) => {

    return{
        type: EDIT_SELECTED_PRICE_RANGE_ID_CHANGED,
        payload: selected_price_range_id
    };

};

export const editPriceModalErrorChanged = (edit_price_modal_error) => {

    return{
        type: EDIT_PRICE_MODAL_ERROR_CHANGED,
        payload: edit_price_modal_error
    };

};


export const editPrice = (
    access_token,
    client,
    uid,
    history,
    price_range_id,
    price,
    price_ranges
) => {

    return(dispatch) => {

        dispatch({type: EDIT_PRICE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: price_range_id,
            price: price
        });

        axios.post(EDIT_PRICE_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: EDIT_PRICE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const price_ranges = data.price_ranges;

                    dispatch({type: EDIT_PRICE_SUCCESS, payload: price_ranges});

                    dispatch({type: CLEAR_EDIT_PRICE_MODAL_STATE});


                }else{

                    const record_not_found = data.record_not_found;

                    if(record_not_found){

                        _.remove(price_ranges, function(pr) {
                            return pr.id === price_range_id
                        });

                        const new_price_ranges = _.cloneDeep(price_ranges);

                        if(_.isEmpty(new_price_ranges)){

                            dispatch({type: CLEAR_EDIT_PRICE_MODAL_STATE});

                            dispatch({type: CLEAR_EDIT_PRICE_LIST_STATE});

                        }else{

                            dispatch({type: CLEAR_EDIT_PRICE_MODAL_STATE});

                            dispatch({type: EDIT_PRICE_RANGES_CHANGED, payload: new_price_ranges});


                        }



                    }else{

                        const message = data.message;

                        dispatch({type: EDIT_PRICE_FAILURE, payload: message});

                    }

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });





    };

};

export const newPriceChanged = (new_price) => {

    return{
        type: NEW_PRICE_CHANGED,
        payload: new_price
    };

};

export const editPriceModalVisibleChanged = (edit_price_modal_visible) => {

    return{
        type: EDIT_PRICE_MODAL_VISIBLE_CHANGED,
        payload: edit_price_modal_visible
    };

};

export const editPackSizeModalErrorChanged = (edit_pack_size_modal_error) => {

    return{
        type: EDIT_PACK_SIZE_MODAL_ERROR_CHANGED,
        payload: edit_pack_size_modal_error
    };

};

export const editPackSize = (
    access_token,
    client,
    uid,
    history,
    price_list_id,
    pack_size
) => {

    return(dispatch) => {

        dispatch({type: EDIT_PACK_SIZE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: price_list_id,
            pack_size: pack_size
        });


        axios.post(EDIT_PACK_SIZE_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: EDIT_PACK_SIZE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const new_pack_size = data.pack_size;

                    dispatch({type: EDIT_PACK_SIZE_SUCCESS, payload: new_pack_size});

                    dispatch({type: CLEAR_EDIT_PACK_SIZE_MODAL_STATE});

                }else{

                    const record_not_found = data.record_not_found;

                    if(record_not_found){

                        dispatch({type: CLEAR_EDIT_PACK_SIZE_MODAL_STATE});

                        dispatch({type: CLEAR_EDIT_PRICE_LIST_STATE});

                    }else{

                        const message = data.message;

                        dispatch({type: EDIT_PACK_SIZE_FAILURE, payload: message});

                    }

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };


};

export const newPackSizeChanged = (new_pack_size) => {

    return{
        type: NEW_PACK_SIZE_CHANGED,
        payload: new_pack_size
    };

};

export const editPackSizeModalVisibleChanged = (edit_pack_size_modal_visible) => {

    return{
        type: EDIT_PACK_SIZE_MODAL_VISIBLE_CHANGED,
        payload: edit_pack_size_modal_visible
    };

};

export const editProductBrandNameChanged = (brand_name) => {

    return{
        type: EDIT_PRODUCT_BRAND_NAME_CHANGED,
        payload: brand_name
    };

};

export const replaceProductPicture = (
    access_token,
    client,
    uid,
    history,
    product_id,
    picture,
    replace_index,
    picture_name
) => {

    return(dispatch) => {

        dispatch({type: REPLACE_PRODUCT_PICTURE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json",
                'Content-Type': 'multipart/form-data'
            }
        };

        let bodyFormData = new FormData();

        let data = {
            product_id: product_id,
            replace_index: replace_index,
            picture_name: picture_name
        };

        picture.dataURL.includes("base64") ? data.isBase64 = true : data.isBase64 = false;


        if(data.isBase64){

            let file = {};

            file.uri = picture.dataURL;

            file.name = picture.file.name;

            file.type = picture.file.type;

            file = JSON.stringify(file);

            bodyFormData.append('picture', file);


        }else{

            bodyFormData.append('picture', picture);

        }


        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });

        axios.post(REPLACE_PRODUCT_PICTURE_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: REPLACE_PRODUCT_PICTURE_COMPLETE});

                const data = response.data;

                const success = data.success;

                const pictures = data.pictures;

                if(pictures !== undefined && pictures !== null){

                    dispatch({type: EDIT_PRODUCT_PICTURES_CHANGED, payload: pictures});

                }

                if(!success){

                    dispatch({
                        type: REPLACE_PRODUCT_PICTURE_FAILURE,
                        payload: 'There was an error replacing the picture'
                    });

                }



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });






    };

};

export const updateProduct = (
    access_token,
    client,
    uid,
    history,
    product_id,
    name,
    description,
    upc,
    sku,
    product_attributes,
    category_id,
    topic_name,
    brand_name,
    quantity
) => {

    return(dispatch) => {

        dispatch({type: UPDATE_PRODUCT});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json",
                'Content-Type': 'multipart/form-data'
            }
        };

        let bodyFormData = new FormData();

        let data = {
            product_id: product_id,
            name: name,
            description: description,
            upc: upc,
            sku: sku,
            category_id: category_id,
            topic_name: topic_name,
            brand_name:  brand_name,
            quantity: quantity
        };

        data.product_attributes = JSON.stringify(product_attributes);

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });


        axios.post(UPDATE_PRODUCT_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: UPDATE_PRODUCT_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const message = data.message;

                    const name = data.name;

                    const description = data.description;

                    const upc = data.upc;

                    const sku = data.sku;

                    const pictures = data.pictures;

                    const topic_name = data.topic_name;

                    const product_attributes = data.product_attributes;

                    const category_id = data.category_id;

                    const categories = data.categories;

                    const price_list_id = data.price_list_id;

                    const pack_size = data.pack_size;

                    const price_ranges = data.price_ranges;

                    let quantity = data.quantity;

                    if(quantity === "" || quantity === null){

                        quantity = '';

                    }


                    dispatch({type: UPDATE_PRODUCT_SUCCESS, payload: {
                        message: message,
                        name: name,
                        description: description,
                        upc: upc,
                        sku: sku,
                        pictures: pictures,
                        topic_name: topic_name,
                        product_attributes: product_attributes,
                        category_id: category_id,
                        categories: categories,
                        price_list_id: price_list_id,
                        pack_size: pack_size,
                        price_ranges: price_ranges,
                        price_list_form_visible: price_list_id !== undefined,
                        quantity: quantity
                    }});


                }else{

                    const message = data.message;

                    dispatch({type: UPDATE_PRODUCT_FAILURE, payload: message});

                }


            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });




    };


};

export const addProductImages = (access_token, client, uid, history, product_id, pictures) => {

    return(dispatch) => {

        dispatch({type: ADD_PRODUCT_IMAGES});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json",
                'Content-Type': 'multipart/form-data'
            }
        };

        let bodyFormData = new FormData();

        let data = {
            product_id: product_id
        };


        pictures[0].dataURL.includes("base64") ? data.isBase64 = true : data.isBase64 = false;

        for(let i = 0; i < pictures.length; i++) {

            if(data.isBase64){

                let file = {};

                file.uri = pictures[i].dataURL;

                file.name = pictures[i].file.name;

                file.type = pictures[i].file.type;

                file = JSON.stringify(file);

                bodyFormData.append('pictures[]', file);


            }else{

                let file = pictures[i];

                bodyFormData.append('pictures[]', file);

            }


        }

        _.each(data, (value, key) => {

            bodyFormData.append(key, value);

        });

        axios.post(ADD_PRODUCT_IMAGES_ROUTE, bodyFormData, config)
            .then(response => {

                dispatch({type: ADD_PRODUCT_IMAGES_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const pictures = data.pictures;

                    dispatch({type: ADD_PRODUCT_IMAGES_SUCCESS, payload: pictures});


                }else{

                    const message = data.message;

                    dispatch({type: ADD_PRODUCT_IMAGES_FAILURE, payload: message});


                }



            }).catch(error => {

            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };


};

export const hideEditProductModal = () => {

    return{
        type: HIDE_EDIT_PRODUCT_MODAL
    };

};

export const removeProductImage = (access_token, client, uid, history, product_id, image_name) => {

    return(dispatch) => {

        dispatch({type: REMOVE_PRODUCT_IMAGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            product_id: product_id,
            image_name: image_name
        });

        axios.post(REMOVE_PRODUCT_IMAGE_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: REMOVE_PRODUCT_IMAGE_COMPLETE});

                const data = response.data;

                const success = data.success;

                if(success){

                    const pictures = data.pictures;

                    dispatch({type: REMOVE_PRODUCT_IMAGE_SUCCESS, payload: pictures})


                }else{

                    const message = data.message;

                    dispatch({type: REMOVE_PRODUCT_IMAGE_FAILURE, payload: message})


                }


            }).catch(error => {


            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });


    };

};

export const editProductCategoryChanged = (category_id) => {

    return{
        type: EDIT_PRODUCT_CATEGORY_CHANGED,
        payload: category_id
    };

};


export const editProductAttributesChanged = (product_attributes) => {

    let new_product_attributes = _.cloneDeep(product_attributes);

    return{
        type: EDIT_PRODUCT_ATTRIBUTES_CHANGED,
        payload: new_product_attributes
    };


};



export const editProductTopicChanged = (topic_name) => {

    return{
        type: EDIT_PRODUCT_TOPIC_CHANGED,
        payload: topic_name
    };

};

export const editProductSkuChanged = (sku) => {

    return{
        type: EDIT_PRODUCT_SKU_CHANGED,
        payload: sku
    };

};

export const editProductUpcChanged = (upc) => {

    return{
        type: EDIT_PRODUCT_UPC_CHANGED,
        payload: upc
    }

};


export const editProductDescriptionChanged = (description) => {

    return{
        type: EDIT_PRODUCT_DESCRIPTION_CHANGED,
        payload: description
    };

};


export const editProductNameChanged = (name) => {

    return{
        type: EDIT_PRODUCT_NAME_CHANGED,
        payload: name
    };

};

export const clearEditProductState = () => {

    return{
        type: CLEAR_EDIT_PRODUCT_STATE
    };

};

export const initializeEditProductPage = (access_token, client, uid, history, product_id) => {

    return(dispatch) => {

        dispatch({type: INITIALIZE_EDIT_PRODUCT_PAGE});

        const config = {
            headers: {
                "access_token": access_token,
                "client": client,
                "uid": uid,
                "Accept": "application/json"
            }
        };

        let bodyFormData = getFormData({
            id: product_id
        });

        axios.post(EDIT_PRODUCT_INDEX_ROUTE, bodyFormData, config)
            .then((response) => {

                dispatch({type: INITIALIZE_EDIT_PRODUCT_PAGE_COMPLETE});

                const data = response.data;

                console.log(data);

                const success = data.success;

                if(success){

                    const name = data.name;

                    const description = data.description;

                    const upc = data.upc;

                    const sku = data.sku;

                    const pictures = data.pictures;

                    const topic_name = data.topic_name;

                    const product_attributes = data.product_attributes;

                    const category_id = data.category_id;

                    const categories = data.categories;

                    const brand_name = data.brand_name;

                    const price_list_id = data.price_list_id;

                    const pack_size = data.pack_size;

                    const price_ranges = data.price_ranges;

                    let quantity = data.quantity;

                    if(quantity === "" || quantity === null){

                        quantity = '';

                    }


                    dispatch({type: INITIALIZE_EDIT_PRODUCT_PAGE_SUCCESS, payload: {
                        name: name,
                        description: description,
                        upc: upc,
                        sku: sku,
                        pictures: pictures,
                        topic_name: topic_name,
                        product_attributes: product_attributes,
                        category_id: category_id,
                        categories: categories,
                        brand_name: brand_name,
                        price_list_id: price_list_id,
                        pack_size: pack_size,
                        price_ranges: price_ranges,
                        price_list_form_visible: price_list_id !== undefined,
                        quantity: quantity
                    }});


                }else{

                    history.push("/products");


                }



            }).catch(error => {


            dispatch({type: LOGOUT_SUCCESS});

            history.push("/");

        });

    };

};