import {State, City} from 'country-state-city';
import _ from "lodash";

export const getUsaStateCode = (state_name) => {

    let state_code = "";

    const states = State.getStatesOfCountry('US');

    for(let i = 0; i < states.length; i++){

        const state_item = states[i];

        if(state_item.name === state_name){

            state_code = state_item.isoCode;

            break;

        }

    }

    return state_code;


};