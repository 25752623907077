import {
    LOGOUT_SUCCESS,
    CLEAR_EDIT_PRODUCT_STATE,
    INITIALIZE_EDIT_PRODUCT_PAGE,
    INITIALIZE_EDIT_PRODUCT_PAGE_COMPLETE,
    INITIALIZE_EDIT_PRODUCT_PAGE_SUCCESS,
    EDIT_PRODUCT_NAME_CHANGED,
    EDIT_PRODUCT_DESCRIPTION_CHANGED,
    EDIT_PRODUCT_UPC_CHANGED,
    EDIT_PRODUCT_SKU_CHANGED,
    EDIT_PRODUCT_TOPIC_CHANGED,
    EDIT_PRODUCT_ATTRIBUTES_CHANGED,
    EDIT_PRODUCT_CATEGORY_CHANGED,
    REMOVE_PRODUCT_IMAGE_SUCCESS,
    REMOVE_PRODUCT_IMAGE_FAILURE,
    HIDE_EDIT_PRODUCT_MODAL,
    ADD_PRODUCT_IMAGES_FAILURE,
    ADD_PRODUCT_IMAGES_SUCCESS,
    REMOVE_PRODUCT_IMAGE,
    REMOVE_PRODUCT_IMAGE_COMPLETE,
    ADD_PRODUCT_IMAGES,
    ADD_PRODUCT_IMAGES_COMPLETE,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_COMPLETE,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAILURE,
    REPLACE_PRODUCT_PICTURE,
    REPLACE_PRODUCT_PICTURE_COMPLETE,
    REPLACE_PRODUCT_PICTURE_FAILURE,
    EDIT_PRODUCT_PICTURES_CHANGED,
    EDIT_PRODUCT_BRAND_NAME_CHANGED,
    EDIT_PACK_SIZE_MODAL_VISIBLE_CHANGED,
    NEW_PACK_SIZE_CHANGED,
    CLEAR_EDIT_PRICE_LIST_STATE,
    CLEAR_EDIT_PACK_SIZE_MODAL_STATE,
    EDIT_PACK_SIZE, EDIT_PACK_SIZE_COMPLETE,
    EDIT_PACK_SIZE_SUCCESS,
    EDIT_PACK_SIZE_FAILURE,
    EDIT_PACK_SIZE_MODAL_ERROR_CHANGED,
    EDIT_PRICE_MODAL_VISIBLE_CHANGED,
    NEW_PRICE_CHANGED,
    EDIT_PRICE,
    EDIT_PRICE_COMPLETE,
    EDIT_PRICE_SUCCESS,
    CLEAR_EDIT_PRICE_MODAL_STATE,
    EDIT_PRICE_RANGES_CHANGED,
    EDIT_PRICE_FAILURE,
    EDIT_PRICE_MODAL_ERROR_CHANGED,
    EDIT_SELECTED_PRICE_RANGE_ID_CHANGED,
    OPEN_EDIT_PRICE_MODAL,
    REMOVE_PRICE_RANGE,
    REMOVE_PRICE_RANGE_COMPLETE,
    REMOVE_PRICE_RANGE_FAILURE,
    OPEN_EDIT_QUANTITY_MODAL,
    CLEAR_EDIT_QUANTITY_MODAL_STATE,
    NEW_END_QUANTITY_CHANGED,
    EDIT_QUANTITY_MODAL_ERROR_CHANGED,
    EDIT_QUANTITY,
    EDIT_QUANTITY_COMPLETE,
    EDIT_QUANTITY_FAILURE,
    END_QUANTITY_ERROR_CHANGED,
    PRICE_ERROR_CHANGED,
    ADD_PRICE_MODAL_ERROR_CHANGED,
    CLEAR_ADD_PRICE_MODAL_STATE,
    OPEN_ADD_PRICE_MODAL,
    ADD_PRICE,
    ADD_PRICE_COMPLETE,
    ADD_PRICE_FAILURE,
    DELETE_PRICE_LIST,
    DELETE_PRICE_LIST_COMPLETE,
    PACK_SIZE_ERROR_CHANGED,
    CREATE_PRICE_LIST_MODAL_ERROR_CHANGED,
    OPEN_CREATE_PRICE_LIST_MODAL,
    CLEAR_CREATE_PRICE_LIST_MODAL_STATE,
    CREATE_PRICE_LIST,
    CREATE_PRICE_LIST_COMPLETE,
    CREATE_PRICE_LIST_SUCCESS,
    CREATE_PRICE_LIST_FAILURE,
    EDIT_PRODUCT_QUANTITY_CHANGED
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    name: '',
    description: '',
    upc: '',
    sku: '',
    pictures: [],
    topic_name: '',
    product_attributes: {},
    category_id: '',
    categories: [],
    brand_name: '',
    editing_product: false,
    edit_product_modal_visible: false,
    success_message: '',
    error_message: '',
    editing_images: false,
    price_list_id: '',
    pack_size: '',
    price_ranges: [],
    price_list_form_visible: false,
    edit_pack_size_modal_visible: false,
    new_pack_size: '',
    edit_pack_size_modal_error: '',
    editing_pack_size: false,
    edit_price_modal_visible: false,
    new_price: '',
    editing_price: false,
    edit_price_modal_error: '',
    selected_price_range_id: '',
    removing_price_range: false,
    edit_quantity_modal_visible: false,
    new_start_quantity: '',
    new_end_quantity: '',
    edit_quantity_modal_error: '',
    editing_quantity: false,
    end_quantity_error: '',
    price_error: '',
    add_price_modal_error: '',
    add_price_modal_visible: false,
    adding_price: false,
    deleting_price_list: false,
    pack_size_error: '',
    create_price_list_modal_error: '',
    create_price_list_modal_visible: false,
    creating_price_list: false,
    quantity: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EDIT_PRODUCT_QUANTITY_CHANGED:
            return{
                ...state,
                quantity: action.payload
            };
        case CREATE_PRICE_LIST_FAILURE:
            return{
                ...state,
                create_price_list_modal_error: action.payload
            };
        case CREATE_PRICE_LIST_SUCCESS:
            return{
                ...state,
                price_list_id: action.payload.price_list_id,
                pack_size: action.payload.pack_size,
                price_ranges: action.payload.price_ranges,
                price_list_form_visible: true
            };
        case CREATE_PRICE_LIST_COMPLETE:
            return{
                ...state,
                creating_price_list: false
            };
        case CREATE_PRICE_LIST:
            return{
                ...state,
                creating_price_list: true,
                create_price_list_modal_error: ''
            };
        case CLEAR_CREATE_PRICE_LIST_MODAL_STATE:
            return{
                ...state,
                new_pack_size: '',
                pack_size_error: '',
                new_start_quantity: '',
                new_end_quantity: '',
                end_quantity_error: '',
                new_price: '',
                price_error: '',
                create_price_list_modal_error: '',
                create_price_list_modal_visible: false,
                creating_price_list: false
            };
        case OPEN_CREATE_PRICE_LIST_MODAL:
            return{
                ...state,
                new_start_quantity: 1,
                create_price_list_modal_visible: true
            };
        case CREATE_PRICE_LIST_MODAL_ERROR_CHANGED:
            return{
                ...state,
                create_price_list_modal_error: action.payload
            };
        case PACK_SIZE_ERROR_CHANGED:
            return{
                ...state,
                pack_size_error: action.payload
            };
        case DELETE_PRICE_LIST_COMPLETE:
            return{
                ...state,
                deleting_price_list: false
            };
        case DELETE_PRICE_LIST:
            return{
                ...state,
                deleting_price_list: true
            };
        case ADD_PRICE_FAILURE:
            return{
                ...state,
                add_price_modal_error: action.payload
            };
        case ADD_PRICE_COMPLETE:
            return{
                ...state,
                adding_price: false
            };
        case ADD_PRICE:
            return{
                ...state,
                adding_price: true,
                end_quantity_error: '',
                price_error: '',
                add_price_modal_error: ''
            };
        case OPEN_ADD_PRICE_MODAL:
            return{
                ...state,
                new_start_quantity: action.payload,
                add_price_modal_visible: true
            };
        case CLEAR_ADD_PRICE_MODAL_STATE:
            return{
                ...state,
                new_start_quantity: '',
                new_end_quantity: '',
                new_price: '',
                end_quantity_error: '',
                price_error: '',
                add_price_modal_error: '',
                add_price_modal_visible: false,
                adding_price: false
            };
        case ADD_PRICE_MODAL_ERROR_CHANGED:
            return{
                ...state,
                add_price_modal_error: action.payload
            };
        case PRICE_ERROR_CHANGED:
            return{
                ...state,
                price_error: action.payload
            };
        case END_QUANTITY_ERROR_CHANGED:
            return{
                ...state,
                end_quantity_error: action.payload
            };
        case EDIT_QUANTITY_FAILURE:
            return {
                ...state,
                edit_quantity_modal_error: action.payload
            };
        case EDIT_QUANTITY_COMPLETE:
            return{
                ...state,
                editing_quantity: false
            };
        case EDIT_QUANTITY:
            return{
                ...state,
                edit_quantity_modal_error: '',
                editing_quantity: true
            };
        case EDIT_QUANTITY_MODAL_ERROR_CHANGED:
            return{
                ...state,
                edit_quantity_modal_error: action.payload
            };
        case NEW_END_QUANTITY_CHANGED:
            return{
                ...state,
                new_end_quantity: action.payload
            };
        case CLEAR_EDIT_QUANTITY_MODAL_STATE:
            return{
                ...state,
                edit_quantity_modal_visible: false,
                new_start_quantity: '',
                new_end_quantity: '',
                edit_quantity_modal_error: '',
                editing_quantity: false
            };
        case OPEN_EDIT_QUANTITY_MODAL:
            return{
                ...state,
                edit_quantity_modal_visible: true,
                new_start_quantity: action.payload.new_start_quantity,
                new_end_quantity: action.payload.new_end_quantity,
                selected_price_range_id: action.payload.selected_price_range_id
            };
        case REMOVE_PRICE_RANGE_FAILURE:
            return{
                ...state,
                edit_product_modal_visible: true,
                error_message: action.payload
            };
        case REMOVE_PRICE_RANGE_COMPLETE:
            return{
                ...state,
                removing_price_range: false
            };
        case REMOVE_PRICE_RANGE:
            return{
                ...state,
                removing_price_range: true
            };
        case OPEN_EDIT_PRICE_MODAL:
            return{
                ...state,
                edit_price_modal_visible: true,
                new_price: action.payload.new_price,
                selected_price_range_id: action.payload.selected_price_range_id
            };
        case EDIT_SELECTED_PRICE_RANGE_ID_CHANGED:
            return{
                ...state,
                selected_price_range_id: action.payload
            };
        case EDIT_PRICE_MODAL_ERROR_CHANGED:
            return{
                ...state,
                edit_price_modal_error: action.payload
            };
        case EDIT_PRICE_FAILURE:
            return{
                ...state,
                edit_price_modal_error: action.payload
            };
        case EDIT_PRICE_RANGES_CHANGED:
            return{
                ...state,
                price_ranges: action.payload
            };
        case CLEAR_EDIT_PRICE_MODAL_STATE:
            return{
                ...state,
                edit_price_modal_visible: false,
                new_price: '',
                selected_price_range_id: '',
                edit_price_modal_error: ''
            };
        case EDIT_PRICE_SUCCESS:
            return{
                ...state,
                price_ranges: action.payload
            };
        case EDIT_PRICE_COMPLETE:
            return{
                ...state,
                editing_price: false
            };
        case EDIT_PRICE:
            return{
                ...state,
                editing_price: true,
                edit_price_modal_error: ''
            };
        case NEW_PRICE_CHANGED:
            return{
                ...state,
                new_price: action.payload
            };
        case EDIT_PRICE_MODAL_VISIBLE_CHANGED:
            return{
                ...state,
                edit_price_modal_visible: action.payload
            };
        case EDIT_PACK_SIZE_MODAL_ERROR_CHANGED:
            return{
                ...state,
                edit_pack_size_modal_error: action.payload
            };
        case CLEAR_EDIT_PACK_SIZE_MODAL_STATE:
            return{
                ...state,
                edit_pack_size_modal_visible: false,
                new_pack_size: '',
                edit_pack_size_modal_error: ''
            };
        case CLEAR_EDIT_PRICE_LIST_STATE:
            return{
                ...state,
                price_list_id: '',
                pack_size: '',
                price_ranges: [],
                price_list_form_visible: false
            };
        case EDIT_PACK_SIZE_FAILURE:
            return{
                ...state,
                edit_pack_size_modal_error: action.payload
            };
        case EDIT_PACK_SIZE_SUCCESS:
            return{
                ...state,
                pack_size: action.payload
            };
        case EDIT_PACK_SIZE_COMPLETE:
            return{
                ...state,
                editing_pack_size: false
            };
        case EDIT_PACK_SIZE:
            return{
                ...state,
                edit_pack_size_modal_error: '',
                editing_pack_size: true
            };
        case NEW_PACK_SIZE_CHANGED:
            return{
                ...state,
                new_pack_size: action.payload
            };
        case EDIT_PACK_SIZE_MODAL_VISIBLE_CHANGED:
            return{
                ...state,
                edit_pack_size_modal_visible: action.payload
            };
        case EDIT_PRODUCT_BRAND_NAME_CHANGED:
            return{
                ...state,
                brand_name: action.payload
            };
        case EDIT_PRODUCT_PICTURES_CHANGED:
            return{
                ...state,
                pictures: action.payload
            };
        case REPLACE_PRODUCT_PICTURE_FAILURE:
            return{
                ...state,
                edit_product_modal_visible: true,
                error_message: action.payload
            };
        case REPLACE_PRODUCT_PICTURE_COMPLETE:
            return{
                ...state,
                editing_images: false
            };
        case REPLACE_PRODUCT_PICTURE:
            return{
                ...state,
                editing_images: true
            };
        case UPDATE_PRODUCT_SUCCESS:
            return{
                ...state,
                edit_product_modal_visible: true,
                success_message: action.payload.message,
                name: action.payload.name,
                description: action.payload.description,
                upc: action.payload.upc,
                sku: action.payload.sku,
                pictures: action.payload.pictures,
                topic_name: action.payload.topic_name,
                product_attributes: action.payload.product_attributes,
                category_id: action.payload.category_id,
                categories: action.payload.categories,
                price_list_id: action.payload.price_list_id === undefined ? '' : action.payload.price_list_id,
                pack_size: action.payload.pack_size === undefined ? '' : action.payload.pack_size,
                price_ranges: action.payload.price_ranges === undefined ? [] : action.payload.price_ranges,
                price_list_form_visible: action.payload.price_list_form_visible,
                quantity: action.payload.quantity
            };
        case UPDATE_PRODUCT_FAILURE:
            return{
                ...state,
                edit_product_modal_visible: true,
                error_message: action.payload
            };
        case UPDATE_PRODUCT_COMPLETE:
            return{
                ...state,
                editing_product: false
            };
        case UPDATE_PRODUCT:
            return{
                ...state,
                editing_product: true
            };
        case ADD_PRODUCT_IMAGES_FAILURE:
            return{
                ...state,
                edit_product_modal_visible: true,
                error_message: action.payload
            };
        case ADD_PRODUCT_IMAGES_SUCCESS:
            return{
                ...state,
                pictures: action.payload
            };
        case ADD_PRODUCT_IMAGES_COMPLETE:
            return{
                ...state,
                editing_images: false
            };
        case ADD_PRODUCT_IMAGES:
            return{
                ...state,
                editing_images: true
            };
        case HIDE_EDIT_PRODUCT_MODAL:
            return{
                ...state,
                edit_product_modal_visible: false,
                success_message: '',
                error_message: ''
            };
        case REMOVE_PRODUCT_IMAGE_FAILURE:
            return{
                ...state,
                edit_product_modal_visible: true,
                error_message: action.payload
            };
        case REMOVE_PRODUCT_IMAGE_SUCCESS:
            return{
                ...state,
                pictures: action.payload
            };
        case REMOVE_PRODUCT_IMAGE_COMPLETE:
            return{
                ...state,
                editing_images: false
            };
        case REMOVE_PRODUCT_IMAGE:
            return{
                ...state,
                editing_images: true
            };
        case EDIT_PRODUCT_CATEGORY_CHANGED:
            return{
                ...state,
                category_id: action.payload
            };
        case EDIT_PRODUCT_ATTRIBUTES_CHANGED:
            return{
                ...state,
                product_attributes: action.payload
            };
        case EDIT_PRODUCT_TOPIC_CHANGED:
            return{
                ...state,
                topic_name: action.payload
            };
        case EDIT_PRODUCT_SKU_CHANGED:
            return{
                ...state,
                sku: action.payload
            };
        case EDIT_PRODUCT_UPC_CHANGED:
            return{
                ...state,
                upc: action.payload
            };
        case EDIT_PRODUCT_DESCRIPTION_CHANGED:
            return{
                ...state,
                description: action.payload
            };
        case EDIT_PRODUCT_NAME_CHANGED:
            return{
                ...state,
                name: action.payload
            };
        case INITIALIZE_EDIT_PRODUCT_PAGE_SUCCESS:
            return{
                ...state,
                name: action.payload.name,
                description: action.payload.description,
                upc: action.payload.upc,
                sku: action.payload.sku,
                pictures: action.payload.pictures,
                topic_name: action.payload.topic_name,
                product_attributes: action.payload.product_attributes,
                category_id: action.payload.category_id,
                categories: action.payload.categories,
                brand_name: action.payload.brand_name,
                price_list_id: action.payload.price_list_id === undefined ? '' : action.payload.price_list_id,
                pack_size: action.payload.pack_size === undefined ? '' : action.payload.pack_size,
                price_ranges: action.payload.price_ranges === undefined ? [] : action.payload.price_ranges,
                price_list_form_visible: action.payload.price_list_form_visible,
                quantity: action.payload.quantity
            };
        case INITIALIZE_EDIT_PRODUCT_PAGE_COMPLETE:
            return{
                ...state,
                initializing_page: false
            };
        case INITIALIZE_EDIT_PRODUCT_PAGE:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_EDIT_PRODUCT_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};