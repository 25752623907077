import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form, FormControl, Table, Accordion, ButtonGroup, Dropdown} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeProductsPage,
    clearProductsPageState,
    searchProducts,
    hideProduct,
    productsBatchActionExecutionComplete,
    clearChangeProductsCategoryModalMessages,
    changeProductsCategory,
    productsQuantityChanged
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";
import { Typeahead } from 'react-bootstrap-typeahead';

class Products extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const search_term = "";

        const selected_category_id = "";

        const selected_topic_id = "";

        const product_id = "";

        const delete_product_modal_visible = false;

        const selected_products_ids = [];

        const change_products_category_modal_visible = false;

        const updated_category_id = "";

        this.state = {
            history,
            params,
            location,
            width,
            height,
            search_term,
            selected_category_id,
            selected_topic_id,
            delete_product_modal_visible,
            product_id,
            selected_products_ids,
            change_products_category_modal_visible,
            updated_category_id
        };

    }

    componentWillUnmount(){

        this.props.clearProductsPageState();

    }



    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeProductsPage
        } = this.props;

        const { history } = this.state;

        if(!logged_in){

            history.push("/");

        }else{

            if(!roles.includes("root_admin") && !roles.includes("lister")){

                history.push("/home");

            }else{

                initializeProductsPage(access_token, client, uid, history);

            }

        }

    }



    categoriesFilter(){

        const {
            searchProducts,
            access_token,
            client,
            uid,
            categories,
            productsQuantityChanged
        } = this.props;

        const {
            history,
            selected_topic_id,
            search_term
        } = this.state;



        if(!_.isEmpty(categories)){

            return(

                <Form.Group style={{
                    marginRight: isMobile ? '25px' : '60px',
                    width: isMobile ? this.state.width / 2.5 : this.state.width / 6
                }}>

                    <Form.Label style={{
                        fontSize: '18px'
                    }}>
                        Category
                    </Form.Label>

                    <Typeahead
                        id="category-selector"
                        labelKey={category => `${category.name}`}
                        onChange={(selected_categories) => {

                            console.log(selected_categories);

                            if(!_.isEmpty(selected_categories)){

                                const new_selected_category = selected_categories[0];

                                const new_category_id = new_selected_category.id;

                                this.setState({
                                    selected_category_id: new_category_id,
                                    selected_products_ids: []
                                });

                                searchProducts(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    search_term,
                                    new_category_id,
                                    selected_topic_id,
                                    ''
                                );


                            }else{

                                this.setState({
                                    selected_category_id: '',
                                    selected_products_ids: []
                                });

                                productsQuantityChanged(20);

                                searchProducts(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    search_term,
                                    "",
                                    selected_topic_id,
                                    20
                                );

                            }


                        }}
                        options={categories}
                        placeholder="Select Category"
                    />
                </Form.Group>


            );


        }

    }

    topicsFilter(){

        const {
            searchProducts,
            access_token,
            client,
            uid,
            topics,
            productsQuantityChanged
        } = this.props;

        const {
            history,
            selected_category_id,
            search_term
        } = this.state;




        if(!_.isEmpty(topics)){

            return(

                <Form.Group style={{
                    width: isMobile ? this.state.width / 2.5 : this.state.width / 6
                }}>

                    <Form.Label style={{
                        fontSize: '18px'
                    }}>
                        Topic
                    </Form.Label>

                    <Typeahead
                        id="topic-selector"
                        labelKey={topic => `${topic.name}`}
                        onChange={(selected_topics) => {

                            console.log(selected_topics);

                            if(!_.isEmpty(selected_topics)){

                                const new_selected_topic = selected_topics[0];

                                const new_selected_topic_id = new_selected_topic.id;

                                this.setState({
                                    selected_topic_id: new_selected_topic_id,
                                    selected_products_ids: []
                                });

                                searchProducts(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    search_term,
                                    selected_category_id,
                                    new_selected_topic_id,
                                    ''
                                );


                            }else{

                                this.setState({
                                    selected_topic_id: '',
                                    selected_products_ids: []
                                });

                                productsQuantityChanged(20);

                                searchProducts(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    search_term,
                                    selected_category_id,
                                    "",
                                    20
                                );

                            }


                        }}
                        options={topics}
                        placeholder="Select Topic"
                    />
                </Form.Group>


            );

        }

    }

    productTopic(product){

        const topic_id = product.topic_id;

        const topic_name = product.topic_name;


        if(topic_id !== undefined  && topic_name !== undefined ){

            if(isMobile){

                return(

                    <Form.Control
                        readOnly
                        type="text"
                        value={topic_name}
                        disabled
                    />

                );

            }else{

                return(

                    <p style={{
                        textAlign: 'center'
                    }}>
                        {topic_name}
                    </p>


                );


            }




        }else{

            if(isMobile){

                return(

                    <Form.Control
                        readOnly
                        type="text"
                        value={"N/A"}
                        disabled
                    />

                );

            }else{

                return(

                    <p style={{
                        textAlign: 'center'
                    }}>
                        N/A
                    </p>


                );

            }



        }


    }

    renderOptionsButton(product){

        return(

            <Dropdown style={ isMobile ? {} : {
                paddingLeft: '10px',
                paddingRight: '10px'
            }}>

                <Dropdown.Toggle
                    style={ isMobile ? {
                        width: this.state.width - 100
                    }: {}}
                    size={isMobile ? "md" : "sm"}
                    variant="success"
                >
                    Options
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={() => {
                            this.state.history.push(`/products/edit/product_id=${product.id}`);
                        }}
                    >
                        Edit Product
                    </Dropdown.Item>


                    <Dropdown.Item
                        onClick={() => {
                            this.setState({delete_product_modal_visible: true, product_id: product.id})
                        }}
                    >
                        Delete Product
                    </Dropdown.Item>


                </Dropdown.Menu>


            </Dropdown>


        );

    }


    productCheckBox(product){

        const { selected_products_ids } = this.state;

        return(

            <Form.Check
                checked={selected_products_ids.includes(product.id)}
                onChange={(e) => {

                    const checked = e.target.checked;

                    if(checked){

                        let new_selected_product_ids = selected_products_ids;

                        new_selected_product_ids.push(product.id);

                        this.setState({selected_products_ids: new_selected_product_ids});

                    }else{


                        let new_selected_product_ids = selected_products_ids;

                        _.remove(new_selected_product_ids, function(selected_product_id) {
                            return product.id === selected_product_id;
                        });

                        this.setState({selected_products_ids: new_selected_product_ids});


                    }

                }}
                type="checkbox"
            />

        );

    }

    productsList(){

        const { products } = this.props;

        const { history } = this.state;

        return _.map(products, (product, index) => {

            if(isMobile){

                return(

                    <div
                        key={index}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >

                        <div style={{
                            marginBottom: '20px',
                            marginRight: '25px'
                        }}>

                            {this.productCheckBox(product)}

                        </div>



                        <Card
                            style={{
                                marginBottom: '30px',
                                width: this.state.width - 50
                            }}
                        >
                            <Accordion.Item eventKey={index}>

                                <Accordion.Header>

                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flex: 1
                                    }}>

                                        <p style={{
                                            textAlign: 'center',
                                            marginBottom: '20px'
                                        }}>
                                            {product.name}
                                        </p>

                                        <Image
                                            src={product.main_picture_url}
                                            style={{
                                                width: '120px',
                                                height: '120px',
                                                objectFit: 'contain',
                                                marginBottom: '20px'
                                            }}
                                        />


                                    </div>

                                </Accordion.Header>

                                <Accordion.Body>

                                    <Form.Group className="product-list-item-attribute-container" >

                                        <Form.Label  >
                                            Product Name
                                        </Form.Label>


                                        <Form.Control
                                            readOnly
                                            disabled
                                            as="textarea"
                                            value={product.name}
                                            style={{ height: '150px' }}
                                        />

                                    </Form.Group>

                                    <Form.Group className="product-list-item-attribute-container" >

                                        <Form.Label  >
                                            UPC
                                        </Form.Label>


                                        <Form.Control
                                            readOnly
                                            type="text"
                                            value={product.upc}
                                            disabled
                                        />

                                    </Form.Group>


                                    <Form.Group className="product-list-item-attribute-container" >

                                        <Form.Label  >
                                            SKU
                                        </Form.Label>


                                        <Form.Control
                                            readOnly
                                            type="text"
                                            value={product.sku}
                                            disabled
                                        />

                                    </Form.Group>

                                    <Form.Group className="product-list-item-attribute-container" >

                                        <Form.Label  >
                                            Category
                                        </Form.Label>


                                        <Form.Control
                                            readOnly
                                            type="text"
                                            value={product.category_name}
                                            disabled
                                        />

                                    </Form.Group>


                                    <Form.Group className="product-list-item-attribute-container" >

                                        <Form.Label  >
                                            Topic
                                        </Form.Label>


                                        {this.productTopic(product)}

                                    </Form.Group>


                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginBottom: '10px'
                                    }}>

                                        {this.renderOptionsButton(product)}


                                    </div>




                                </Accordion.Body>



                            </Accordion.Item>


                        </Card>

                    </div>

                );

            }else{

                return(

                    <tr key={index}>

                        <td>


                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '50px'
                            }}>

                                {this.productCheckBox(product)}

                            </div>



                        </td>

                        <td style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Image
                                src={product.main_picture_url}
                                style={{
                                    width: '120px',
                                    height: '120px',
                                    objectFit: 'contain'
                                }}
                            />
                        </td>

                        <td>

                            <p style={{
                                textAlign: 'center'
                            }}>
                                {product.name}
                            </p>

                        </td>

                        <td>

                            <p style={{
                                textAlign: 'center'
                            }}>
                                {product.upc}
                            </p>

                        </td>

                        <td>

                            <p style={{
                                textAlign: 'center'
                            }}>
                                {product.sku}
                            </p>

                        </td>

                        <td>

                            <p style={{
                                textAlign: 'center'
                            }}>
                                {product.category_name}
                            </p>

                        </td>

                        <td>

                            {this.productTopic(product)}

                        </td>

                        <td>

                            {this.renderOptionsButton(product)}

                        </td>

                    </tr>

                );


            }



        });


    }


    selectAllCheckbox(){

        const { products } = this.props;

        const { selected_products_ids } = this.state;

        return(

            <Form.Check
                checked={products.length === selected_products_ids.length}
                style={{
                    marginBottom: '2rem'
                }}
                onChange={(e) => {

                    const checked = e.target.checked;

                    if(checked){


                        let new_selected_ids = [];

                        _.map(products, (product, index) => {

                            new_selected_ids.push(product.id);

                        });

                        console.log(`new select ids length is ${new_selected_ids.length}`);

                        console.log(`products length is ${products.length}`);

                        console.log(new_selected_ids);


                        this.setState({selected_products_ids: new_selected_ids});




                    }else{

                        console.log("clear selected product ids");

                        this.setState({selected_products_ids: []});



                    }

                }}
                label="Select All"
                type="checkbox"
            />

        );

    }

    batchActionsButton(){

        const {selected_products_ids} = this.state;


        if(!_.isEmpty(selected_products_ids)){

            return(

                <Dropdown
                    style={{
                        marginBottom: '2rem'
                    }}
                >

                    <Dropdown.Toggle
                        size={"md"}
                        variant="success"
                    >
                        Batch Actions
                    </Dropdown.Toggle>

                    <Dropdown.Menu>

                        <Dropdown.Item
                            onClick={() => {
                                this.setState({change_products_category_modal_visible: true});
                            }}
                        >
                            Change products category
                        </Dropdown.Item>



                    </Dropdown.Menu>


                </Dropdown>


            );

        }


    }

    loadMoreButton(){

        const {
            selected_topic_id,
            selected_category_id
        } = this.state;

        const { searching_products } = this.props;


        if(selected_category_id === "" && selected_topic_id === ""){

            if(searching_products){

                return(

                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>

                        <Spinner animation="border" variant="primary"  />

                    </div>

                );

            }else{
                return(

                    <Button
                        variant="success"
                        style={{
                            width: isMobile ? '85%' : '10%',
                            alignSelf: 'center',
                            borderRadius: '20px',
                            marginTop: '1.5rem',
                        }}
                        onClick={() => {

                            const {
                                searchProducts,
                                access_token,
                                client,
                                uid,
                                productsQuantityChanged
                            } = this.props;

                            let quantity = this.props.quantity;


                            const {
                                history,
                                selected_topic_id,
                                selected_category_id,
                                search_term
                            } = this.state;

                            quantity = quantity + 20;

                            productsQuantityChanged(quantity);



                            searchProducts(
                                access_token,
                                client,
                                uid,
                                history,
                                search_term,
                                selected_category_id,
                                selected_topic_id,
                                quantity
                            );



                            window.scrollBy(0, -0.001);

                        }}
                    >
                        Load More
                    </Button>

                );

            }



        }




    }

    renderProducts(){

        const { products } = this.props;

        if(!_.isEmpty(products)){


            if(isMobile){

                return(

                    <Fragment>

                        <Accordion style={{
                            marginTop: '2rem'
                        }}>

                            {this.selectAllCheckbox()}

                            {this.batchActionsButton()}

                            {this.productsList()}



                        </Accordion>


                        {this.loadMoreButton()}

                    </Fragment>


                );


            }else{

                return(

                    <div style={{
                        flexDirection: 'column',
                        display: 'flex',
                        marginTop: '2rem'
                    }}>

                        {this.selectAllCheckbox()}

                        {this.batchActionsButton()}

                        <Table style={ {
                            width:  this.state.width / 1.1,
                        }}   striped bordered hover>

                            <thead>

                            <tr>
                                <th></th>
                                <th></th>
                                <th className="products-table-header">Name</th>
                                <th className="products-table-header">UPC</th>
                                <th className="products-table-header">SKU</th>
                                <th className="products-table-header">Category</th>
                                <th className="products-table-header">Topic</th>
                                <th></th>
                            </tr>

                            </thead>

                            <tbody>

                            {this.productsList()}

                            </tbody>

                        </Table>

                        {this.loadMoreButton()}

                    </div>


                );

            }




        }else{

            return(

                <p style={{
                    marginTop: '4rem',
                    fontSize: '20px'
                }}>
                    No Products Found
                </p>

            );

        }

    }

    componentDidUpdate(){

        const {  batch_action_executed } = this.props;

        const { selected_products_ids  } = this.state;


        if(batch_action_executed && selected_products_ids.length > 0){

            this.setState({selected_products_ids: []})

        }


    }


    exitChangeProductsCategoryModal(){

        const { clearChangeProductsCategoryModalMessages,  productsBatchActionExecutionComplete, batch_action_executed } = this.props;

        clearChangeProductsCategoryModalMessages();

        if(batch_action_executed){

            productsBatchActionExecutionComplete();

        }

        this.setState({
            change_products_category_modal_visible: false,
            updated_category_id: ''
        });

    }

    changeProductsCategoryModalMessages(){

        const {
            change_products_category_error_message
        } = this.props;


        if(!_.isEmpty(change_products_category_error_message)){

            return(

                <p style={{
                    fontSize: '18px',
                    color: '#FF0000',
                    textAlign: 'center',
                    marginTop: '20px'
                }}>
                    {change_products_category_error_message}
                </p>

            );


        }


    }


    changeProductsCategoryModalBody(){

        const {
            categories,
            changing_products_category,
            batch_action_executed,
            change_products_category_success_message
        } = this.props;

        if(changing_products_category){

            return(

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary"  />

                </div>

            );

        }else{

            if(batch_action_executed){

                return(

                    <p style={{
                        fontSize: '18px',
                        color: '#008000',
                        textAlign: 'center',
                        marginTop: '20px',
                    }}>
                        {change_products_category_success_message}
                    </p>

                );

            }else{

                return(

                    <Fragment>

                        <Form.Group
                        >

                            <Typeahead
                                id="updated-category-selector"
                                labelKey={category => `${category.name}`}
                                onChange={(selected_categories) => {

                                    console.log(selected_categories);

                                    if(!_.isEmpty(selected_categories)){

                                        const new_selected_category = selected_categories[0];

                                        const new_category_id = new_selected_category.id;

                                        console.log("updated category id " + new_category_id);


                                        this.setState({updated_category_id: new_category_id})

                                    }else{

                                        console.log("clear category id");

                                        this.setState({updated_category_id: ''})

                                    }


                                }}
                                options={categories}
                                placeholder="Select Category"
                            />
                        </Form.Group>

                        {this.changeProductsCategoryModalMessages()}

                    </Fragment>

                );

            }



        }

    }

    changeProductsCategoryModal(){


        const {
            access_token,
            client,
            uid,
            changeProductsCategory,
        } = this.props;

        const {
            history,
            search_term,
            selected_category_id,
            selected_topic_id,
            selected_products_ids,
            change_products_category_modal_visible,
            updated_category_id
        } = this.state;



        if(change_products_category_modal_visible){

            return(

                <Modal
                    size={isMobile ? 'sm' : 'lg'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={change_products_category_modal_visible}
                    onHide={() => {
                        this.exitChangeProductsCategoryModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Change Products Category
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.changeProductsCategoryModalBody()}

                    </Modal.Body>


                    <Modal.Footer>

                        <Button
                            variant="success"
                            style={{
                            }}
                            onClick={() => {

                                console.log(selected_products_ids);

                                changeProductsCategory(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    search_term,
                                    selected_category_id,
                                    selected_topic_id,
                                    updated_category_id,
                                    selected_products_ids,
                                    this.getQuantity()
                                );

                            }}>
                            Update
                        </Button>


                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitChangeProductsCategoryModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>


            );

        }



    }



    exitDeleteProductModal(){

        this.setState({delete_product_modal_visible: false, product_id: ''});

    }

    getQuantity(){

        const {
            selected_topic_id,
            selected_category_id
        } = this.state;

        let quantity;

        console.log(selected_category_id);

        if(selected_category_id === "" && selected_topic_id === ""){

            quantity = this.props.quantity;

        }else{

            quantity = "";

        }

        return quantity;

    }

    deleteProductModal(){

        const {
            hideProduct,
            access_token,
            client,
            uid
        } = this.props;

        const {
            history,
            selected_topic_id,
            selected_category_id,
            search_term,
            delete_product_modal_visible,
            product_id
        } = this.state;


        if(delete_product_modal_visible){

            return(
                <Modal
                    size={isMobile ? 'sm' : 'lg'}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={delete_product_modal_visible}
                    onHide={() => {
                        this.exitDeleteProductModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Delete Product
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        The product will be deleted permanently.

                    </Modal.Body>


                    <Modal.Footer>

                        <Button
                            variant="danger"
                            style={{
                                marginRight: '10px'
                            }}
                            onClick={() => {

                                hideProduct(
                                    access_token,
                                    client,
                                    uid,
                                    history,
                                    search_term,
                                    selected_category_id,
                                    selected_topic_id,
                                    product_id,
                                    this.getQuantity()
                                );

                                this.exitDeleteProductModal();
                            }}>
                            Delete
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                this.exitDeleteProductModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>


            );

        }

    }

    renderBody(){

        const {
            fetching_admin_profile,
            initializing_page,
            searchProducts,
            access_token,
            client,
            uid
        } = this.props;

        const {
            history,
            params,
            location,
            selected_topic_id,
            selected_category_id,
            search_term
        } = this.state;

        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />

                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>



                            <p style={{
                                fontSize: isMobile ? '30px' : '40px',
                                marginBottom: '20px',
                                textAlign: 'center'
                            }}>
                                Products
                            </p>

                            <div style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '3rem',
                                flexDirection: 'column'
                            }}>


                                <FormControl
                                    type="text"
                                    placeholder="search by name, upc, sku"
                                    style={{
                                        borderRadius: '20px',
                                        width: isMobile ? this.state.width - 60  : this.state.width / 2.5,
                                        fontSize: '18px',
                                        marginBottom: '20px'
                                    }}
                                    onChange={(e) => {

                                        e.preventDefault();

                                        this.setState({
                                            search_term: e.target.value,
                                            selected_products_ids: []
                                        });



                                    }}
                                    onKeyPress={(e) => {

                                        if(e.charCode === 13){

                                            e.preventDefault();



                                            searchProducts(
                                                access_token,
                                                client,
                                                uid,
                                                history,
                                                search_term,
                                                selected_category_id,
                                                selected_topic_id,
                                                this.getQuantity()
                                            );


                                        }
                                    }}
                                    value={search_term}
                                />


                                <div style={ {
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>

                                    {this.categoriesFilter()}

                                    {this.topicsFilter()}

                                </div>

                                {this.renderProducts()}

                            </div>




                        </div>

                    </div>

                    {this.deleteProductModal()}

                    {this.changeProductsCategoryModal()}

                </div>

            );

        }

    }

    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        initializing_page,
        products,
        categories,
        topics,
        batch_action_executed,
        changing_products_category,
        change_products_category_error_message,
        change_products_category_success_message,
        searching_products,
        quantity
    } = state.products;


    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        products,
        categories,
        topics,
        batch_action_executed,
        changing_products_category,
        change_products_category_error_message,
        change_products_category_success_message,
        searching_products,
        quantity
    };

};

export default connect(mapStateToProps, {
    initializeProductsPage,
    clearProductsPageState,
    searchProducts,
    hideProduct,
    productsBatchActionExecutionComplete,
    clearChangeProductsCategoryModalMessages,
    changeProductsCategory,
    productsQuantityChanged
})(Products);

