import React, {Component} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Login from "./components/Login";
import Home from "./components/Home";
import ResendUnlock from "./components/ResendUnlock";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AdminAccounts from "./components/AdminAccounts";
import CreateAdmin from "./components/CreateAdmin";
import EditAdmin from "./components/EditAdmin";
import Categories from "./components/Categories";
import Products from "./components/Products";
import CreateProduct from "./components/CreateProduct";
import EditProduct from "./components/EditProduct";
import CustomerAccounts from "./components/CustomerAccounts";
import EditCustomer from "./components/EditCustomer";
import ViewCustomer from "./components/ViewCustomer";
import LandingPageImages from "./components/LandingPageImages";

export default function Routes(){

    return(

        <Router>

            <Switch>

                <Route exact path="/" component={Login} />

                <Route exact path="/home" component={Home} />

                <Route exact path="/resend-unlock-link" component={ResendUnlock} />

                <Route exact path="/forgot-password" component={ForgotPassword} />

                <Route exact path="/reset-password/access_token=:access_token&client=:client&uid=:uid&reset_password_token=:reset_password_token" component={ResetPassword} />

                <Route exact path="/admin-accounts" component={AdminAccounts} />

                <Route exact path="/admin-accounts/new" component={CreateAdmin} />

                <Route exact path="/admin-accounts/edit/id=:id" component={EditAdmin} />

                <Route exact path="/categories" component={Categories} />

                <Route exact path="/products" component={Products} />

                <Route exact path="/categories/category_id=:category_id/create_product" component={CreateProduct} />

                <Route exact path="/products/edit/product_id=:product_id" component={EditProduct} />

                <Route exact path="/customer-accounts" component={CustomerAccounts} />

                <Route exact path="/customer-accounts/search_term=:search_term" component={CustomerAccounts} />

                <Route exact path="/edit-customer/customer_id=:customer_id" component={EditCustomer} />

                <Route exact path="/view-customer/customer_id=:customer_id" component={ViewCustomer} />

                <Route exact path="/landing-page-images" component={LandingPageImages} />



            </Switch>

        </Router>

    );


}

