import {
    LOGOUT_SUCCESS,
    GET_CUSTOMER_ACCOUNTS,
    GET_CUSTOMER_ACCOUNTS_COMPLETE,
    CUSTOMER_ACCOUNTS_CHANGED,
    CLEAR_CUSTOMER_ACCOUNTS_STATE,
    SEARCH_CUSTOMER_ACCOUNTS,
    SEARCH_CUSTOMER_ACCOUNTS_COMPLETE
} from "../actions/types";

const INITIAL_STATE = {
    initializing_page: false,
    customers: [],
    searching_accounts: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_CUSTOMER_ACCOUNTS_COMPLETE:
            return{
                ...state,
                searching_accounts: false
            };
        case SEARCH_CUSTOMER_ACCOUNTS:
            return{
                ...state,
                searching_accounts: true
            };
        case CUSTOMER_ACCOUNTS_CHANGED:
            return{
                ...state,
                customers: action.payload
            };
        case GET_CUSTOMER_ACCOUNTS_COMPLETE:
            return{
                ...state,
                initializing_page: false
            };
        case GET_CUSTOMER_ACCOUNTS:
            return{
                ...state,
                initializing_page: true
            };
        case CLEAR_CUSTOMER_ACCOUNTS_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};