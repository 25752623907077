import {
    LOGOUT_SUCCESS,
    INITIALIZE_EDIT_ADMIN_PAGE,
    INITIALIZE_EDIT_ADMIN_PAGE_COMPLETE,
    CLEAR_EDIT_ADMIN_STATE,
    CHANGE_ADMIN_ACCOUNT_FULL_NAME,
    CHANGE_ADMIN_ACCOUNT_EMAIL,
    CHANGE_ADMIN_ACCOUNT_PASSWORD,
    CHANGE_ADMIN_ACCOUNT_ROLES,
    UPDATE_ADMIN_ACCOUNT,
    UPDATE_ADMIN_ACCOUNT_COMPLETE,
    UPDATE_ADMIN_ADMIN_ACCOUNT_FULL_NAME_ERROR,
    UPDATE_ADMIN_ACCOUNT_EMAIL_ERROR,
    UPDATE_ADMIN_ACCOUNT_PASSWORD_ERROR,
    UPDATE_ADMIN_ACCOUNT_ROLES_ERROR,
    ADMIN_ACCOUNT_CHANGED,
    FULL_NAME_UPDATE_SUCCESS,
    EMAIL_UPDATE_SUCCESS,
    PASSWORD_UPDATE_SUCCESS,
    ROLES_UPDATE_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
    loading: false,
    account: {},
    full_name: '',
    email: '',
    password: '',
    admin_roles: [],
    full_name_error: '',
    email_error: '',
    password_error: '',
    roles_error: '',
    available_roles: [],
    full_name_update_success: false,
    email_update_success: false,
    password_update_success: false,
    roles_update_success: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_ADMIN_ACCOUNT:
            return{
                ...state,
                loading: true,
                full_name_error: '',
                email_error: '',
                password_error: '',
                roles_error: '',
                full_name_update_success: false,
                email_update_success: false,
                password_update_success: false,
                roles_update_success: false
            };
        case ROLES_UPDATE_SUCCESS:
            return{
                ...state,
                roles_update_success: true
            };
        case PASSWORD_UPDATE_SUCCESS:
            return{
                ...state,
                password_update_success: true
            };
        case EMAIL_UPDATE_SUCCESS:
            return{
                ...state,
                email_update_success: true
            };
        case  FULL_NAME_UPDATE_SUCCESS:
            return{
                ...state,
                full_name_update_success: true
            };
        case UPDATE_ADMIN_ACCOUNT_ROLES_ERROR:
            return{
                ...state,
                roles_error: action.payload
            };
        case UPDATE_ADMIN_ACCOUNT_PASSWORD_ERROR:
            return{
                ...state,
                password_error: action.payload
            };
        case UPDATE_ADMIN_ACCOUNT_EMAIL_ERROR:
            return{
                ...state,
                email_error: action.payload
            };
        case UPDATE_ADMIN_ADMIN_ACCOUNT_FULL_NAME_ERROR:
            return{
                ...state,
                full_name_error: action.payload
            };
        case ADMIN_ACCOUNT_CHANGED:
            return{
                ...state,
                account: action.payload,
                full_name: action.payload.full_name,
                email: action.payload.email,
                admin_roles: action.payload.admin_roles
            };
        case UPDATE_ADMIN_ACCOUNT_COMPLETE:
            return{
                ...state,
                loading: false
            };
        case CHANGE_ADMIN_ACCOUNT_ROLES:
            return{
                ...state,
                admin_roles: action.payload
            };
        case CHANGE_ADMIN_ACCOUNT_PASSWORD:
            return{
                ...state,
                password: action.payload
            };
        case CHANGE_ADMIN_ACCOUNT_EMAIL:
            return{
                ...state,
                email: action.payload
            };
        case CHANGE_ADMIN_ACCOUNT_FULL_NAME:
            return{
                ...state,
                full_name: action.payload
            };
        case INITIALIZE_EDIT_ADMIN_PAGE_COMPLETE:
            return{
                ...state,
                loading: false,
                account: action.payload.account,
                full_name: action.payload.account.full_name,
                email: action.payload.account.email,
                admin_roles: action.payload.account.admin_roles,
                available_roles: action.payload.available_roles
            };
        case INITIALIZE_EDIT_ADMIN_PAGE:
            return{
                ...state,
                loading: true
            };
        case CLEAR_EDIT_ADMIN_STATE:
            return{
                ...state,
                ...INITIAL_STATE
            };
        case LOGOUT_SUCCESS:
            return{
                ...INITIAL_STATE
            };
        default:
            return state;
    }
};