import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeEditCustomerPage,
    clearEditCustomerPageState,
    editCustomerEmailChanged,
    editCustomerFullNameChanged,
    editCustomerCompanyNameChanged,
    editCustomerCompanyAddressChanged,
    editCustomerCompanyStateChanged,
    editCustomerCompanyCityChanged,
    editCustomerCompanyStateCodeChanged,
    editCustomerCompanyZipCodeChanged,
    editCustomerAccountApprovedChanged,
    editCustomerAccountPasswordChanged,
    updateCustomerAccount,
    closeEditCustomerAccountModal,
    openResellerPermitModal,
    closeResellerPermitModal,
    replaceSellerPermit
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";
import { Typeahead } from 'react-bootstrap-typeahead';
import {State, City} from 'country-state-city';


class EditCustomer extends Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const customer_id = params.customer_id;

        this.state = {
            history,
            params,
            location,
            width,
            height,
            customer_id
        };

    }


    componentWillUnmount(){


        this.props.clearEditCustomerPageState();

    }

    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeEditCustomerPage
        } = this.props;

        const { history, customer_id } = this.state;

        if(!logged_in){

            history.push("/");

        }else{


            if(!roles.includes("root_admin") && !roles.includes("account_moderator")){

                history.push("/home");

            }else{

                initializeEditCustomerPage(
                    access_token,
                    client,
                    uid,
                    history,
                    customer_id
                );

            }

        }

    }

    getCountryStates(){

        let states = [];

        _.map(State.getStatesOfCountry('US'), (state_item, index) => {

            const cities = City.getCitiesOfState('US', state_item.isoCode);

            if(!_.isEmpty(cities)){

                states.push(state_item);

            }

        });

        return states;

    }



    renderCitySelector(){

        const { state_code, editCustomerCompanyCityChanged, company_city } = this.props;


        if(!_.isEmpty(state_code)){

            return(

                <Form.Group
                    style={{
                        width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        marginBottom: '1rem'
                    }}
                >



                    <Form.Label style={{
                        fontSize: isMobile ? '16px' : '18px',
                        marginBottom: '15px'
                    }}>
                        City
                    </Form.Label>


                    <Typeahead
                        id="city-selector"
                        labelKey={city => `${city.name === undefined ? city : city.name}`}
                        onChange={(selected_cities) => {

                            if(!_.isEmpty(selected_cities)){

                                const new_selected_city = selected_cities[0];


                                const new_selected_city_name = new_selected_city.name;


                                editCustomerCompanyCityChanged(new_selected_city_name);


                            }else{


                                editCustomerCompanyCityChanged('');


                            }


                        }}
                        options={City.getCitiesOfState('US', state_code)}
                        placeholder="Select City"
                        defaultSelected={[company_city]}
                    />



                </Form.Group>


            );

        }

    }

    getAccountApprovedLabel(account_approved){

        if(account_approved){

            return 'Approved';

        }else{

            return 'Unapproved';

        }

    }


    editCustomerAccountModal(){

        const {
            edit_customer_account_modal_visible,
            edit_customer_account_modal_error,
            closeEditCustomerAccountModal
        } = this.props;

        if(edit_customer_account_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={edit_customer_account_modal_visible}
                    onHide={() => {
                        closeEditCustomerAccountModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Result
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <p style={{
                            fontSize: '18px',
                            color: '#FF0000'
                        }}>
                            {edit_customer_account_modal_error}
                        </p>

                    </Modal.Body>



                    <Modal.Footer>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                closeEditCustomerAccountModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }

    }

    sellerPermitError(){

        const { replace_seller_permit_modal_error } = this.props;

        if(replace_seller_permit_modal_error.length > 0) {

            return (

                <p style={{
                    textAlign: 'center',
                    color: 'red'
                }}>
                    {replace_seller_permit_modal_error}
                </p>

            )

        }

    }

    replaceSellerPermitModalBody(){

        const  {
            replacing_seller_permit ,
            replaceSellerPermit,
            access_token,
            client,
            uid
        } = this.props;

        const { history, customer_id } = this.state;

        if(replacing_seller_permit){

            return(

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    <Spinner animation="border" variant="primary" />

                </div>

            );

        }else{

            return(

                <Fragment>

                    <p>Seller Permit (*.pdf, *.doc, *.docx)</p>



                    <input
                        type="file"
                        accept=".pdf,.doc,.docx,.odt"
                        onChange={e => {
                            replaceSellerPermit(
                                access_token,
                                client,
                                uid,
                                history,
                                customer_id,
                                e.target.files[0]
                            );
                        }}
                    />

                    {this.sellerPermitError()}

                </Fragment>


            );

        }




    }

    replaceSellerPermitModal(){

        const { replace_seller_permit_modal_visible, closeResellerPermitModal } = this.props;

        if(replace_seller_permit_modal_visible){

            return(

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={replace_seller_permit_modal_visible}
                    onHide={() => {
                        closeResellerPermitModal();
                    }}
                >
                    <Modal.Header closeButton>

                        <Modal.Title id="contained-modal-title-vcenter">
                            Replace Seller Permit
                        </Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        {this.replaceSellerPermitModalBody()}


                    </Modal.Body>



                    <Modal.Footer>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                closeResellerPermitModal();
                            }}>
                            Close
                        </Button>

                    </Modal.Footer>

                </Modal>

            );

        }

    }


    renderForm(){

        const {
            email,
            editCustomerEmailChanged,
            editCustomerFullNameChanged,
            full_name,
            company_name,
            editCustomerCompanyNameChanged,
            company_address,
            editCustomerCompanyAddressChanged,
            company_state,
            editCustomerCompanyStateChanged,
            editCustomerCompanyCityChanged,
            editCustomerCompanyStateCodeChanged,
            editCustomerCompanyZipCodeChanged,
            company_zip_code,
            editCustomerAccountApprovedChanged,
            account_approved,
            editCustomerAccountPasswordChanged,
            password,
            updating_account,
            updateCustomerAccount,
            access_token,
            client,
            uid,
            company_city,
            openResellerPermitModal,
            seller_permit_url
        } = this.props;

        const {  history, customer_id } = this.state;

        if(updating_account){

            return(



                <div style={{
                    marginTop: this.state.height / 4
                }}>

                    <Spinner animation="border" variant="primary"  />


                </div>


            );

        }else{

            return(

                <div>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            Email
                        </Form.Label>


                        <Form.Control
                            type="email"
                            value={email}
                            onChange={(e) => {
                                editCustomerEmailChanged(e.target.value);
                            }}
                        />
                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            Full Name
                        </Form.Label>


                        <Form.Control
                            value={full_name}
                            onChange={(e) => {
                                editCustomerFullNameChanged(e.target.value);
                            }}
                        />
                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            Company Name
                        </Form.Label>


                        <Form.Control
                            value={company_name}
                            onChange={(e) => {
                                editCustomerCompanyNameChanged(e.target.value);
                            }}
                        />
                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            Address
                        </Form.Label>


                        <Form.Control
                            value={company_address}
                            onChange={(e) => {
                                editCustomerCompanyAddressChanged(e.target.value);
                            }}
                        />

                    </Form.Group>

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            State
                        </Form.Label>


                        <Typeahead
                            id="state-selector"
                            labelKey={state => `${state.name === undefined ? state : state.name}`}
                            onChange={(selected_states) => {

                                console.log(selected_states);


                                if(!_.isEmpty(selected_states)){

                                    const new_selected_state = selected_states[0];

                                    editCustomerCompanyStateCodeChanged(new_selected_state.isoCode);

                                    const new_selected_state_name = new_selected_state.name;

                                    editCustomerCompanyStateChanged(new_selected_state_name);

                                }else{


                                    editCustomerCompanyStateCodeChanged('');

                                    editCustomerCompanyStateChanged('');

                                    editCustomerCompanyCityChanged('');

                                }


                            }}
                            options={this.getCountryStates()}
                            placeholder="Select State"
                            defaultSelected={[company_state]}
                        />

                    </Form.Group>

                    {this.renderCitySelector()}

                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            Zip Code
                        </Form.Label>


                        <Form.Control
                            value={company_zip_code}
                            onChange={(e) => {
                                editCustomerCompanyZipCodeChanged(e.target.value);
                            }}
                        />

                    </Form.Group>




                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '1rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            Account Status
                        </Form.Label>

                        <Typeahead
                            id="account-status-selector"
                            labelKey={account_approved_item => `${account_approved_item.label}`}
                            onChange={(selected_account_approved_items) => {

                                console.log(selected_account_approved_items);

                                if(!_.isEmpty(selected_account_approved_items)){

                                    const new_account_approved_item = selected_account_approved_items[0];

                                    editCustomerAccountApprovedChanged(new_account_approved_item.approved);


                                }else{

                                    editCustomerAccountApprovedChanged('');


                                }


                            }}
                            options={[
                                {label: this.getAccountApprovedLabel(true), approved: true},
                                {label: this.getAccountApprovedLabel(false), approved: false}
                            ]}
                            placeholder="Select Status"
                            defaultSelected={[{label: this.getAccountApprovedLabel(account_approved), approved: account_approved}]}
                        />


                    </Form.Group>



                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '2rem'
                        }}
                    >

                        <Form.Label style={{
                            fontSize: isMobile ? '16px' : '18px',
                            marginBottom: '15px'
                        }}>
                            New Password
                        </Form.Label>

                        <p
                            style={{
                                color: 'blue'
                            }}
                        >
                            At least 8 characters long
                        </p>

                        <Form.Control
                            type="password"
                            value={password}
                            onChange={(e) => {
                                editCustomerAccountPasswordChanged(e.target.value);
                            }}
                        />

                    </Form.Group>


                    <Form.Group
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                            marginBottom: '3rem',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >

                        <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>

                            <Form.Label
                                style={{
                                    marginBottom: '20px'
                                }}
                            >
                                Seller Permit (*.pdf, *.doc, *.docx)
                            </Form.Label>

                            <span
                                onClick={() => {
                                    openResellerPermitModal();
                                }}
                                style={{
                                    textDecoration: 'underline',
                                    color: '#0000EE',
                                    textAlign: 'center'
                                }}
                            >
                                Edit
                            </span>

                        </div>



                           <Button
                               onClick={() => {
                                   console.log("Opening");
                                   console.log(seller_permit_url);
                                   window.open(seller_permit_url);
                               }}
                               variant="outline-primary"
                           >
                               View Permit
                           </Button>



                    </Form.Group>



                    <Button
                        variant="success"
                        style={{
                            width: isMobile ? this.state.width - 60 : this.state.width / 1.5,
                        }}
                        onClick={() => {
                            updateCustomerAccount(
                                access_token,
                                client,
                                uid,
                                history,
                                customer_id,
                                email,
                                full_name,
                                company_name,
                                company_address,
                                company_state,
                                company_city,
                                company_zip_code,
                                account_approved,
                                password
                            );
                        }}
                    >
                        Update Account
                    </Button>

                    {this.editCustomerAccountModal()}

                    {this.replaceSellerPermitModal()}


                </div>

            );

        }




    }



    renderBody(){


        const {
            fetching_admin_profile,
            initializing_page
        } = this.props;

        const {
            history,
            params,
            location
        } = this.state;

        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />


                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '3rem',
                            flexDirection: 'column'
                        }}>

                            <p style={{
                                fontSize: isMobile ? '25px' : '35px',
                                marginBottom: '3rem',
                                textAlign: 'center'
                            }}>
                                Edit Customer Account
                            </p>


                            {this.renderForm()}


                        </div>


                    </div>



                </div>

            );

        }



    }


    render() {

        return (

            <Wrapper history={this.state.history}>
                {this.renderBody()}
            </Wrapper>

        );

    }

}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        initializing_page,
        email,
        full_name,
        company_name,
        company_address,
        company_state,
        company_city,
        company_zip_code,
        account_approved,
        password,
        state_code,
        updating_account,
        edit_customer_account_modal_visible,
        edit_customer_account_modal_error,
        seller_permit_url,
        replace_seller_permit_modal_visible,
        replace_seller_permit_modal_error,
        replacing_seller_permit
    } = state.edit_customer;

    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        email,
        full_name,
        company_name,
        company_address,
        company_state,
        company_city,
        company_zip_code,
        account_approved,
        password,
        state_code,
        updating_account,
        edit_customer_account_modal_visible,
        edit_customer_account_modal_error,
        seller_permit_url,
        replace_seller_permit_modal_visible,
        replace_seller_permit_modal_error,
        replacing_seller_permit
    };

};

export default connect(mapStateToProps, {
    initializeEditCustomerPage,
    clearEditCustomerPageState,
    editCustomerEmailChanged,
    editCustomerFullNameChanged,
    editCustomerCompanyNameChanged,
    editCustomerCompanyAddressChanged,
    editCustomerCompanyStateChanged,
    editCustomerCompanyCityChanged,
    editCustomerCompanyStateCodeChanged,
    editCustomerCompanyZipCodeChanged,
    editCustomerAccountApprovedChanged,
    editCustomerAccountPasswordChanged,
    updateCustomerAccount,
    closeEditCustomerAccountModal,
    openResellerPermitModal,
    closeResellerPermitModal,
    replaceSellerPermit
})(EditCustomer);
