import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Card, Spinner, Button, ListGroup, Navbar, Image,  Modal, Form, FormControl, Table, Accordion, ButtonGroup, Dropdown} from "react-bootstrap";
import _ from "lodash";
import {isMobile} from 'react-device-detect';
import {
    initializeCustomerAccountsPage,
    clearCustomerAccountsState,
    searchCustomerAccounts
} from "../actions";
import Wrapper from "./Wrapper";
import TopHeader from "./TopHeader";
import { Typeahead } from 'react-bootstrap-typeahead';


class CustomerAccounts extends  Component{

    constructor(props) {

        super(props);

        const history = props.history;

        const params = props.match.params;

        const location = props.location;

        const width = window.innerWidth;

        const height = window.innerHeight;

        const search_term =  params.search_term ===  undefined ? '' : params.search_term ;

        const account_approved = '';

        const sort_direction = '';

        const email_consent_given = '';


        this.state = {
            history,
            params,
            location,
            width,
            height,
            search_term,
            account_approved,
            sort_direction,
            email_consent_given
        };

    }

    componentWillUnmount(){

        this.props.clearCustomerAccountsState();

    }

    componentDidMount(){

        const {
            logged_in,
            roles,
            access_token,
            client,
            uid,
            initializeCustomerAccountsPage,
            searchCustomerAccounts
        } = this.props;

        const {
            history,
            search_term,
            account_approved,
            sort_direction,
            email_consent_given
        } = this.state;

        if(!logged_in){

            history.push("/");

        }else{


            if(!roles.includes("root_admin") && !roles.includes("lister")){

                history.push("/home");

            }else{

                if(search_term === ''){

                    initializeCustomerAccountsPage(
                        access_token,
                        client,
                        uid,
                        history
                    );

                }else{

                    searchCustomerAccounts(
                        access_token,
                        client,
                        uid,
                        history,
                        search_term,
                        account_approved,
                        sort_direction,
                        email_consent_given
                    );


                }



            }

        }

    }

    accountApprovedFilter(){


        const {
            customers,
            searchCustomerAccounts,
            access_token,
            client,
            uid
        } = this.props;

        const {
            history,
            search_term,
            sort_direction,
            email_consent_given
        } = this.state;




        return(

            <Form.Group style={ isMobile ? {
                width:  this.state.width - 45,
                marginBottom: '20px'
            } : {
                marginRight: '60px',
                width: this.state.width / 6
            }}>

                <Form.Label style={{
                    fontSize: '18px'
                }}>
                    Status
                </Form.Label>

                <Typeahead
                    id="account-status-selector"
                    labelKey={account_approved_item => `${account_approved_item.label}`}
                    onChange={(selected_account_approved_items) => {

                        console.log(selected_account_approved_items);

                        if(!_.isEmpty(selected_account_approved_items)){

                            const new_account_approved_item = selected_account_approved_items[0];

                            this.setState({account_approved: new_account_approved_item.approved});


                            searchCustomerAccounts(
                                access_token,
                                client,
                                uid,
                                history,
                                search_term,
                                new_account_approved_item.approved,
                                sort_direction,
                                email_consent_given
                            );





                        }else{

                            this.setState({account_approved: ''});

                            searchCustomerAccounts(
                                access_token,
                                client,
                                uid,
                                history,
                                search_term,
                                "",
                                sort_direction,
                                email_consent_given
                            );


                        }


                    }}
                    options={[
                        {label: 'Approved', approved: true},
                        {label: 'Unapproved', approved: false}
                    ]}
                    placeholder="Select Status"
                />
            </Form.Group>

        );




    }


    emailConsentGivenFilter(){


        const {
            customers,
            searchCustomerAccounts,
            access_token,
            client,
            uid,
        } = this.props;

        const {
            history,
            search_term,
            account_approved,
            sort_direction
        } = this.state;



        return(

            <Form.Group style={ isMobile ? {
                width:  this.state.width - 45,
                marginBottom: '20px'
            } : {
                width: this.state.width / 6
            }}>

                <Form.Label style={{
                    fontSize: '18px'
                }}>
                    Email Consent Given
                </Form.Label>

                <Typeahead
                    id="email-consent-selector"
                    labelKey={option => `${option.label}`}
                    onChange={(selected_options) => {

                        console.log(selected_options);

                        if(!_.isEmpty(selected_options)){

                            const new_option_item = selected_options[0];

                            this.setState({email_consent_given: new_option_item.value});

                            searchCustomerAccounts(
                                access_token,
                                client,
                                uid,
                                history,
                                search_term,
                                account_approved,
                                sort_direction,
                                new_option_item.value
                            );



                        }else{


                            this.setState({email_consent_given: ''});

                            searchCustomerAccounts(
                                access_token,
                                client,
                                uid,
                                history,
                                search_term,
                                account_approved,
                                sort_direction,
                                ''
                            );

                        }


                    }}
                    options={[
                        {label: 'Yes', value: true},
                        {label: 'No', value: false}
                    ]}
                    placeholder="Select criteria"
                />
            </Form.Group>

        );


    }

    sortFilter(){

        const {
            customers,
            searchCustomerAccounts,
            access_token,
            client,
            uid,
        } = this.props;

        const {
            history,
            search_term,
            account_approved,
            email_consent_given
        } = this.state;



        return(

            <Form.Group style={ isMobile ? {
                width:  this.state.width - 45,
                marginBottom: '20px'
            } : {
                marginRight: '60px',
                width: this.state.width / 6
            }}>

                <Form.Label style={{
                    fontSize: '18px'
                }}>
                    Sort
                </Form.Label>

                <Typeahead
                    id="sort-selector"
                    labelKey={sort_item => `${sort_item.label}`}
                    onChange={(selected_sort_items) => {

                        console.log(selected_sort_items);

                        if(!_.isEmpty(selected_sort_items)){

                            const new_selected_sort_item = selected_sort_items[0];

                            this.setState({sort_direction: new_selected_sort_item.sort_direction});

                            searchCustomerAccounts(
                                access_token,
                                client,
                                uid,
                                history,
                                search_term,
                                account_approved,
                                new_selected_sort_item.sort_direction,
                                email_consent_given
                            );



                        }else{


                            this.setState({sort_direction: ''});

                            searchCustomerAccounts(
                                access_token,
                                client,
                                uid,
                                history,
                                search_term,
                                account_approved,
                                '',
                                email_consent_given
                            );

                        }


                    }}
                    options={[
                        {label: 'Oldest to Newest', sort_direction: 'asc'},
                        {label: 'Newest to Oldest', sort_direction: 'desc'}
                    ]}
                    placeholder="Select criteria"
                />
            </Form.Group>

        );



    }


    renderOptionsButton(customer){

        const { history  } = this.state;

        return(

            <Dropdown style={{
                paddingLeft: '10px',
                paddingRight: '10px'
            }}>

                <Dropdown.Toggle
                    size={"sm"}
                    variant="success"
                >
                    Options
                </Dropdown.Toggle>

                <Dropdown.Menu>

                    <Dropdown.Item
                        onClick={() => {
                            history.push(`/view-customer/customer_id=${customer.id}`)
                        }}
                    >
                        View Account
                    </Dropdown.Item>


                    <Dropdown.Item
                        onClick={() => {
                            history.push(`/edit-customer/customer_id=${customer.id}`);
                        }}
                    >
                        Edit Account
                    </Dropdown.Item>


                </Dropdown.Menu>


            </Dropdown>


        );

    }




    accountsList(){

        const { customers } = this.props;

        const { history  } = this.state;

        if(isMobile){

            return _.map(customers, (customer, index) => {

                return(

                    <Card key={index}  style={{
                        width: this.state.width - 60,
                        marginBottom: '1.5rem',
                        paddingLeft:  '5px',
                        paddingRight:'5px' ,
                        paddingTop: '5px'
                    }}>

                        <Card.Body>




                            <Form.Group id="customer-account-wrapper" >

                                <Form.Label  >
                                    Company Name
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={ customer.company_name}
                                    disabled
                                />

                            </Form.Group>



                            <Form.Group id="customer-account-wrapper" >

                                <Form.Label  >
                                    Full Name
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={customer.full_name}
                                    disabled
                                />

                            </Form.Group>



                            <Form.Group id="customer-account-wrapper" >

                                <Form.Label  >
                                    Email
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={ customer.email}
                                    disabled
                                />


                            </Form.Group>

                            <Form.Group id="customer-account-wrapper" >

                                <Form.Label  >
                                    Account Status
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={ customer.account_approved ? 'Approved' : 'Unapproved' }
                                    disabled
                                />

                            </Form.Group>

                            <Form.Group id="customer-account-wrapper" >

                                <Form.Label  >
                                    Email Consent Given
                                </Form.Label>


                                <Form.Control
                                    readOnly
                                    type="text"
                                    value={ customer.email_consent_given ? 'Yes' : 'No'}
                                    disabled
                                />

                            </Form.Group>


                            <Button
                                variant="outline-primary"
                                style={{
                                    marginBottom: '20px',
                                    width: '100%',
                                    marginTop: '10px'
                                }}
                                onClick={() => {
                                    history.push(`/view-customer/customer_id=${customer.id}`)
                                }}
                            >
                                View Account
                            </Button>


                            <Button
                                variant="outline-primary"
                                style={{
                                    marginBottom: '20px',
                                    width: '100%'
                                }}
                                onClick={() => {
                                    history.push(`/edit-customer/customer_id=${customer.id}`);
                                }}
                            >
                                Edit Account
                            </Button>


                        </Card.Body>

                    </Card>

                );

            });


        }else{

            return(

                <Table  striped bordered hover
                        style={ {
                            width:  this.state.width / 1.5,
                        }}

                >

                    <thead>

                    <tr>
                        <th>Company Name</th>
                        <th>Full Name</th>
                        <th>Email</th>
                        <th>Account Status</th>
                        <th>Email Consent Given</th>
                        <th></th>
                    </tr>

                    </thead>
                    <tbody>

                    {_.map(customers, (customer, index) => {

                        return(

                            <tr key={index}>
                                <td>{customer.company_name}</td>
                                <td>{customer.full_name}</td>
                                <td>{customer.email}</td>
                                <td>{customer.account_approved ? 'Approved' : 'Unapproved'}</td>
                                <td>{customer.email_consent_given ? 'Yes' : 'No'}</td>
                                <td style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    {this.renderOptionsButton(customer)}
                                </td>
                            </tr>

                        );

                    })}


                    </tbody>
                </Table>


            );

        }






    }

    renderCustomerAccounts(){

        const { customers, searching_accounts } = this.props;

        if(!_.isEmpty(customers)){

            if(searching_accounts){

                return(


                    <div style={{
                        marginTop: isMobile ? this.state.height / 6 : this.state.height / 7
                    }}>

                        <Spinner animation="border" variant="primary" />

                    </div>


                );

            }else{

                return(

                    <div style={{
                        marginTop: '3rem'
                    }}>

                        {this.copyCustomerEmailsButton()}

                        {this.accountsList()}

                    </div>

                );

            }

        }else{

            return(

                <p style={{
                    marginTop: '4rem',
                    fontSize: '20px'
                }}>
                    No Accounts Found
                </p>

            );

        }


    }



    copyCustomerEmailsButton(){

        const { customers } = this.props;

        if(customers.length > 0){

            return(

               <div style={ isMobile ? {
                   display: 'flex',
                   alignItems: 'center',
                   justifyContent: 'center',
                   marginBottom: '35px'
               } : { marginBottom: '35px' } }>

                   <Button
                       variant="outline-primary"
                       onClick={async () => {

                           const customer_emails = _.map(customers, 'email').join('\r\n');

                           await navigator.clipboard.writeText(customer_emails).then(
                               () => {
                                   alert("Emails were copied to clipboard");
                               },
                               () => {
                                   alert("Unable to copy emails to clipboard");
                               }
                           );


                       }}
                   >
                       Copy Emails to Clipboard
                   </Button>

               </div>


            );

        }


    }


    renderBody(){

        const {
            fetching_admin_profile,
            initializing_page,
            searchCustomerAccounts,
            access_token,
            client,
            uid,
        } = this.props;

        const {
            history,
            params,
            location,
            search_term,
            account_approved,
            sort_direction,
            email_consent_given
        } = this.state;

        if(fetching_admin_profile || initializing_page){

            return(

                <div>

                    <Spinner animation="border" variant="primary" className="spinner" />

                </div>

            );

        }else{

            return(

                <div>

                    <TopHeader
                        history={history}
                        params={params}
                        location={location}
                    />

                    <div style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}>



                            <p style={{
                                fontSize: isMobile ? '25px' : '35px',
                                marginBottom: '20px',
                                textAlign: 'center'
                            }}>
                                Customer Accounts
                            </p>


                            <div style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: '3rem',
                                flexDirection: 'column'
                            }}>


                                <FormControl
                                    ref={searchBar => this.searchBar = searchBar}
                                    type="text"
                                    placeholder="search using any keyword"
                                    style={{
                                        borderRadius: '20px',
                                        width: isMobile ? this.state.width - 45  : this.state.width / 2.5,
                                        fontSize: '18px',
                                        marginBottom: '20px'
                                    }}
                                    onChange={(e) => {

                                        e.preventDefault();


                                        this.setState({search_term: e.target.value});

                                        if(e.target.value === ''){

                                            searchCustomerAccounts(
                                                access_token,
                                                client,
                                                uid,
                                                history,
                                                e.target.value,
                                                account_approved,
                                                sort_direction,
                                                email_consent_given
                                            );

                                        }

                                    }}
                                    onKeyPress={(e) => {

                                        console.log("I was clicked");

                                        if(e.charCode === 13){

                                            e.preventDefault();

                                            searchCustomerAccounts(
                                                access_token,
                                                client,
                                                uid,
                                                history,
                                                search_term,
                                                account_approved,
                                                sort_direction,
                                                email_consent_given
                                            );


                                        }
                                    }}
                                    value={search_term}
                                />


                                <div style={ isMobile ? {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flex: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                } :  {
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>

                                    {this.accountApprovedFilter()}

                                    {this.sortFilter()}

                                    {this.emailConsentGivenFilter()}


                                </div>


                                {this.renderCustomerAccounts()}

                            </div>



                        </div>

                    </div>



                </div>

            );

        }

    }

    render() {

        return (

            <Wrapper history={this.state.history}>

                {this.renderBody()}

            </Wrapper>

        );

    }


}

const mapStateToProps = (state) => {

    const {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id
    } = state.login;

    const {
        fetching_admin_profile
    } = state.admin_accounts;

    const {
        initializing_page,
        customers,
        searching_accounts
    } = state.customer_accounts;


    return {
        logged_in,
        access_token,
        client,
        uid,
        roles,
        id,
        fetching_admin_profile,
        initializing_page,
        customers,
        searching_accounts
    };

};


export default connect(mapStateToProps, {
    initializeCustomerAccountsPage,
    clearCustomerAccountsState,
    searchCustomerAccounts
})(CustomerAccounts);

